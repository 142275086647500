import React, { useCallback } from 'react'
import clsx from 'clsx'
import { styled, css } from '@mui/system'
import { Modal as ModalStyle } from '@mui/base/Modal'
import { Button, CircularProgress, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

interface IProps {
  children: React.ReactNode
  open: boolean
  setOpen: (open: boolean) => void
  title: string
  handleUpdate: (e: any) => Promise<void>
  labelButton?: string
  size?: string
  disabled?: boolean
  onHandleClose?: () => void
}

export default function BaseModal({
  children,
  open,
  setOpen,
  title,
  handleUpdate,
  labelButton = 'update',
  size = 'xs',
  disabled = false,
  onHandleClose
}: IProps) {
  const { t } = useTranslation()
  const handleClose = () => {
    setOpen(false)
    if (onHandleClose) {
      onHandleClose()
    }
  }
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const onSubmit = useCallback(
    async (e: any) => {
      setIsLoading(true)
      await handleUpdate(e)
      setIsLoading(false)
    },
    [handleUpdate]
  )

  return (
    <div>
      <Modal open={open} onClose={handleClose} slots={{ backdrop: StyledBackdrop }}>
        <Form autoComplete="off" onSubmit={onSubmit} size={size}>
          <ModalHeader>
            <Typography variant="h3">{t(title)}</Typography>
            <Close fontSize={'small'} onClick={handleClose} style={{ cursor: 'pointer' }} />
          </ModalHeader>
          <ModalContent>{children}</ModalContent>
          <ModalFooter>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              {t('cancel')}
            </Button>
            <Button disabled={disabled} type="submit" variant="contained" color="primary">
              {t(labelButton)}
              {isLoading && (
                <CircularProgress style={{ marginLeft: '0.5rem' }} size={20} color="info" />
              )}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  )
}

const Backdrop = React.forwardRef<HTMLDivElement, { open?: boolean; className: string }>(
  (props, ref) => {
    const { open, className, ...other } = props
    return <div className={clsx({ 'MuiBackdrop-open': open }, className)} ref={ref} {...other} />
  }
)

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025'
}

const Modal = styled(ModalStyle)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`

const Form = styled('form', { shouldForwardProp: (prop) => prop !== 'size' })<{
  size: string
}>(({ theme, size }) => ({
  maxWidth: `${size === 'xs' ? '400px' : '1000px'}`,
  width: '90%'
}))

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};
  `
)

const ModalHeader = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(8px - 1px);
    border-top-right-radius: calc(8px - 1px);
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    flex-direction: row;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};
  `
)

const ModalFooter = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 24px;
    border-top: 1px solid #dee2e6;
    border-bottom-left-radius: calc(8px - 1px);
    border-bottom-right-radius: calc(8px - 1px);
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    flex-direction: row;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};
  `
)
