import React, { useState, useMemo, ChangeEvent } from 'react'
import {
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Box,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Tooltip
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Appointment } from 'api/models'
import { useTranslation } from 'react-i18next'
import { useFeedback } from 'app/providers/feedback.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import BaseModal from 'app/components/modals/base.modal'
import dayjs from 'dayjs'
import { CircleRounded } from '@mui/icons-material'
import { z } from 'zod'

interface AppointmentActionMenuProps {
  item: Appointment
  refreshList: () => void
}

export function AppointmentActionMenu({ item, refreshList }: AppointmentActionMenuProps) {
  const { cancelAppointment, getLastSentReminder, sendReminderAppointment, confirmPresence } =
    useFetcher()
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false)
  const [cancelReason, setCancelReason] = useState<string>('')
  const [openConfirmGuestsModal, setOpenConfirmGuestsModal] = useState<boolean>(false)
  const [guestStatuses, setGuestStatuses] = useState<
    { id: number; name: string; status: number; statusLabel: string; checked: boolean }[]
  >([])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    refreshList()
  }

  const handleCancelClose = () => {
    setOpenCancelModal(false)
    setCancelReason('')
  }

  const handleCancelAppointment = async (event: React.FormEvent) => {
    event.preventDefault()
    await handleMutation({
      mutation: cancelAppointment,
      data: { id: item.id, cancellationReason: cancelReason },
      toastSuccess: t('appointment_cancelled_successfully'),
      toastError: t('appointment_cancel_failed'),
      onEnd: () => {
        handleCancelClose()
        handleClose()
      }
    })
  }

  const handleConfirmGuestsClose = () => {
    setOpenConfirmGuestsModal(false)
    setGuestStatuses([])
  }

  const handleConfirmGuests = async (event: React.FormEvent) => {
    event.preventDefault()
    const confirmedGuests = guestStatuses.map((guest) => ({
      id: guest.id,
      presence: guest.checked
    }))
    await handleMutation({
      mutation: confirmPresence,
      data: { id: item.id, guests: confirmedGuests },
      toastSuccess: t('guests_confirmed_successfully'),
      toastError: t('guests_confirm_failed'),
      onEnd: () => {
        handleConfirmGuestsClose()
        handleClose()
      }
    })
  }

  const areAllGuestsPending = (
    guestsSum: { id: number; name: string; status: number; statusLabel: string }[]
  ): boolean => {
    return guestsSum.some((guest: { status: number }) => guest.status === 1)
  }

  const formatGuestInvitationStatus = (value: number, text: string) => {
    let color:
      | 'inherit'
      | 'action'
      | 'disabled'
      | 'primary'
      | 'secondary'
      | 'error'
      | 'info'
      | 'success'
      | 'warning' = 'inherit'

    switch (Number(value)) {
      case 1:
        color = 'warning'
        break
      case 2:
        color = 'secondary'
        break
      case 3:
        color = 'error'
        break
      case 4:
        color = 'success'
        break
      case 5:
        color = 'disabled'
        break
    }

    return (
      <Box component="span" display="flex" alignItems="center" justifyContent="center">
        <Tooltip title={text} placement="top">
          <CircleRounded
            color={color}
            style={{ width: '1rem', height: '1rem', opacity: '0.75', marginRight: '12px' }}
          />
        </Tooltip>
      </Box>
    )
  }

  const menuItems = useMemo(() => {
    const _menuItems = []

    if (item.status !== 2 && areAllGuestsPending(JSON.parse(`[${item.guestsSum}]`))) {
      _menuItems.push({
        label: t('send_reminder_visitors'),
        key: 'send_reminder_visitors',
        action: async () => {
          await handleMutation({
            mutation: getLastSentReminder,
            data: { id: item.id },
            onSuccess: async (response: { date: string }) => {
              await handleMutation({
                mutation: sendReminderAppointment,
                data: { id: item.id },
                confirm: {
                  content: t('confirm_send_reminder', {
                    date: dayjs(response?.date).format('MM/DD/YYYY, HH:mm')
                  })
                },
                toastSuccess: t('reminder_sent_successfully'),
                toastError: t('reminder_send_failed'),
                onEnd: () => {
                  handleClose()
                }
              })
            }
          })
        }
      })
    }

    if (item.status !== 2) {
      _menuItems.push({
        label: t('confirm_appointment'),
        key: 'confirm_appointment',
        action: async () => {
          const guests = JSON.parse(`[${item.guestsSum}]`)
          const guestList = guests.map(
            (guest: { id: number; name: string; status: number; statusLabel: string }) => ({
              ...guest,
              checked: guest.status === 4
            })
          )
          setGuestStatuses(guestList)
          setOpenConfirmGuestsModal(true)
        }
      })
    }

    if (item.status === 1) {
      _menuItems.push({
        label: t('cancel_appointment'),
        key: 'cancel_appointment',
        action: async () => {
          setOpenCancelModal(true)
        }
      })
    }

    return _menuItems
  }, [item, t])

  return (
    <div>
      <IconButton
        id="appointment-action-button"
        aria-controls={open ? 'appointment-action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="appointment-action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'appointment-action-button'
        }}
      >
        {menuItems.map((menuItem) => (
          <MenuItem key={menuItem.key} onClick={menuItem.action}>
            {menuItem.label}
          </MenuItem>
        ))}
      </Menu>

      <BaseModal
        open={openCancelModal}
        setOpen={setOpenCancelModal}
        title={t('cancel_appointment')}
        handleUpdate={handleCancelAppointment}
        size={'xs'}
        labelButton={t('confirm')}
        onHandleClose={handleCancelClose}
      >
        <form onSubmit={handleCancelAppointment}>
          <Box>
            <TextField
              size="small"
              type="text"
              value={cancelReason}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setCancelReason(event.target.value)
              }}
              fullWidth
              label={t('cancellation_reason')}
              placeholder={t('optional')}
            />
          </Box>
        </form>
      </BaseModal>

      <BaseModal
        open={openConfirmGuestsModal}
        setOpen={setOpenConfirmGuestsModal}
        title={t('confirm_appointment')}
        handleUpdate={handleConfirmGuests}
        size={'md'}
        labelButton={t('confirm')}
        onHandleClose={handleConfirmGuestsClose}
      >
        <form onSubmit={handleConfirmGuests}>
          <List>
            {guestStatuses.map((guest) => (
              <ListItem key={guest.id}>
                {formatGuestInvitationStatus(guest.status, guest.statusLabel)}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={guest.checked}
                      onChange={() => {
                        setGuestStatuses((prevStatuses) =>
                          prevStatuses.map((g) =>
                            g.id === guest.id ? { ...g, checked: !g.checked } : g
                          )
                        )
                      }}
                    />
                  }
                  label={guest.name}
                />
              </ListItem>
            ))}
          </List>
        </form>
      </BaseModal>
    </div>
  )
}
