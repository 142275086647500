import { useTranslation } from 'react-i18next'
import React from 'react'
import type { CenterDetails } from 'api/models'
import InfoCard from 'app/components/card/info-card.component'
import { formatDateWithFormat, formatYesNo } from 'app/utils/format'
import { Edit } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

interface ICenterConfigurationProps {
  center: CenterDetails
  companies?: { id: string | number; label: string }[]
  isLoading?: boolean
}
export const CenterConfigurationCard = ({
  center,
  isLoading,
  companies
}: ICenterConfigurationProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const companyLabel = companies?.find((company) => company.id === center.company)?.label

  return (
    <InfoCard
      className="Grid3"
      title={t('informations')}
      titleAction={{
        icon: Edit,
        onClick: () => navigate(`/centers/${center?.id}/configuration/edit`)
      }}
      isLoading={isLoading}
      columns={[
        {
          label: t('opening_day'),
          value: center.centerOpeningDays
            ?.map((dayObj: { openingDay: number }) => dayjs().day(dayObj.openingDay).format('dddd'))
            .join(', ')
        },
        {
          label: t('opening_hours'),
          value:
            formatDateWithFormat(center?.openingHour ?? '', 'LT') +
            ' - ' +
            formatDateWithFormat(center?.closureHour ?? '', 'LT')
        },
        {
          label: t('phone'),
          value: center?.phone
        },
        {
          label: t('email'),
          value: center?.email
        },
        {
          label: t('equipped_stations'),
          value: center?.nbDesktops
        },
        {
          label: t('meeting_room'),
          value: center?.nbMeetingRooms
        },
        {
          label: t('community_room'),
          value: center?.communityRoom ? center.communityRoom?.label : ''
        },
        {
          label: t('cluster'),
          value: center?.clusterName
        },
        {
          label: t('company'),
          value: companyLabel
        },
        {
          label: t('visible_back_office'),
          value: t(formatYesNo(center?.isActive))
        },
        {
          label: t('visible_customer_app'),
          value: t(formatYesNo(center?.isActiveFront))
        }
      ]}
    ></InfoCard>
  )
}
