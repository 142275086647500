import { z } from 'zod'
import dayjs from 'dayjs'

const individualSchema = z.object({
  center: z.string(),
  createdAt: z.string(),
  email: z.string(),
  enterpriseNames: z.string(),
  firstname: z.string(),
  id: z.number(),
  isInvalidEmail: z.number().transform((e) => e === 1),
  isPresent: z.number().transform((e) => e === 1),
  lastname: z.string(),
  phone: z.string(),
  reference: z.string(),
  type: z.string(),
  welcomrUuid: z.string()
})
export type Individual = z.infer<typeof individualSchema>

const individualListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(individualSchema)
})
export type IndividualList = z.infer<typeof individualListSchema>

const individualClientLinkSchema = z.object({
  clientLinkId: z.number(),
  enterpriseId: z.number(),
  role: z.string(),
  job: z.string(),
  isPresent: z.number().transform((e) => e === 1),
  isDelegated: z.boolean(),
  isAccountingMailRecipient: z.number().transform((e) => e === 1),
  enterpriseName: z.string(),
  isActive: z.number().transform((e) => e === 1),
  isMainEnterprise: z.number().transform((e) => e === 1),
  oldMainEnterpriseId: z.number().nullable().optional(),
  center: z.string(),
  individualId: z.number(),
  reference: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  id: z.number(),
  hasWifi: z.boolean()
})
export type ClientLink = z.infer<typeof individualClientLinkSchema>
export const individualClientsListSchema = z.array(individualClientLinkSchema)

const updateClientLink = z.object({
  id: z.number(),
  individualId: z.number(),
  data: individualClientLinkSchema
})
export type UpdateClientLinkProps = z.infer<typeof updateClientLink>
export type ClientLinkList = z.infer<typeof individualClientsListSchema>

export const individualDetailsSchema = z.object({
  id: z.number(),
  civility: z.number(),
  reference: z.string(),
  labelledName: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  centerName: z.string(),
  mainCenter: z.number(),
  email: z.string(),
  phone: z.string(),
  mobile: z.string(),
  address: z.string(),
  zipCode: z.string(),
  city: z.string(),
  country: z.string(),
  countryLabel: z.string(),
  lastConnection: z.instanceof(dayjs as any),
  createdAt: z.instanceof(dayjs as any),
  updatedAt: z.instanceof(dayjs as any),
  isPresent: z
    .number()
    .optional()
    .transform((e) => e === 1),
  isInvalidEmail: z
    .number()
    .optional()
    .transform((e) => e === 1),
  activCornerAccess: z
    .number()
    .optional()
    .transform((e) => e === 1),
  isRemoteOpening: z
    .number()
    .optional()
    .transform((e) => e === 1),
  language: z.string(),
  languageId: z.coerce.number(),
  status: z.coerce.number(),
  statusLabel: z.string(),
  isAccountActive: z.boolean(),
  welcomrUuid: z.string()
})
export type IndividualDetails = z.infer<typeof individualDetailsSchema>
export type IndividualAccountings = z.infer<typeof individualDetailsSchema>

export const wifiPasswordUpdateSchema = z.object({
  mode: z.string(),
  individual_radiuspassword: z.string()
})
export type WifiPasswordUpdate = z.infer<typeof wifiPasswordUpdateSchema>

export const individualFormDetailsSchema = z.object({
  id: z.number().optional(),
  enterpriseId: z.number().optional(),
  civility: z.coerce.number().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  mainCenter: z.coerce.number(),
  email: z.string().email(),
  phone: z.string().nullable().optional(),
  mobile: z.string().nullable().optional(),
  address: z.string().nullable().optional(),
  zipCode: z.string().nullable().optional(),
  city: z.string().nullable().optional(),
  country: z.string().nullable().optional(),
  isRemoteOpening: z.boolean().optional().default(false),
  isPresent: z.boolean().optional().default(false),
  language: z.number({ coerce: true }).optional(),
  languageId: z.coerce.number().optional(),
  status: z.coerce.number().nullable().optional(),
  role: z.string().optional(),
  job: z.string().optional(),
  welcomrUuid: z.string().nullable().optional()
})

export const individualUpdateSchema = z.object({
  civility: z.coerce.number().optional(),
  firstName: z.string().optional(),
  lastName: z.string(),
  mainCenter: z.coerce.number(),
  email: z.string().email(),
  phone: z.string().nullable().optional(),
  mobile: z.string().nullable().optional(),
  address: z.string().nullable().optional(),
  zipCode: z.string().nullable().optional(),
  city: z.string().nullable().optional(),
  country: z.string().nullable().optional(),
  isRemoteOpening: z.boolean().optional(),
  isPresent: z.boolean().optional(),
  activCornerAccess: z.boolean().optional(),
  isInvalidEmail: z.boolean().optional(),
  welcomrUuid: z.string().nullable().optional(),
  language: z.number({ coerce: true }).nullable().optional(),
  status: z.coerce.number().nullable().optional()
})

export type IndividualFormDetails = z.infer<typeof individualFormDetailsSchema>

export const individualWifiSchema = z.object({
  access: z.boolean(),
  login: z.string().nullable(),
  password: z.string().nullable()
})
export type IndividualWifi = z.infer<typeof individualWifiSchema>

export const individualPartnersSchema = z.object({
  activCorner: z.boolean()
})
export type IndividualPartners = z.infer<typeof individualPartnersSchema>

const individualConsumptions = z.object({
  contractId: z.number(),
  contractState: z.number(),
  contractReference: z.string(),
  centerName: z.string(),
  contractStateLabel: z.string()
})
export type IndividualConsumption = z.infer<typeof individualConsumptions>
const individualConsumptionsSchema = z.array(individualConsumptions)
export type IndividualConsumptions = z.infer<typeof individualConsumptionsSchema>

const individualInvoiceSchema = z.object({
  invoiceId: z.number(),
  invoiceReference: z.string(),
  invoiceState: z.number(),
  invoicePeriod: z.string(),
  invoiceAmount: z.number()
})
export type IndividualInvoice = z.infer<typeof individualInvoiceSchema>
const individualInvoicesSchema = z.array(individualInvoiceSchema)
export type IndividualInvoices = z.infer<typeof individualInvoicesSchema>

const individualDeviceSchema = z.object({
  deviceId: z.number(),
  deviceReference: z.string(),
  deviceIsValid: z.boolean()
})
export type IndividualDevice = z.infer<typeof individualDeviceSchema>
const individualDevicesSchema = z.array(individualDeviceSchema)
export type IndividualDevices = z.infer<typeof individualDevicesSchema>

export const linkIndividualSchema = z.object({
  individual: z.number(),
  role: z.string(),
  job: z.string().optional()
})
export type LinkIndividualProps = z.infer<typeof linkIndividualSchema>

const accessGroupReaderSchema = z.object({
  readerIndex: z.number(),
  readerName: z.string()
})
const individualAccessGroupSchema = z.object({
  groupIndex: z.number(),
  groupName: z.string(),
  forbidden: z.boolean(),
  readers: z.array(accessGroupReaderSchema)
})

const individualAccess = z.object({
  centerId: z.number(),
  centerName: z.string(),
  groups: z.array(individualAccessGroupSchema)
})

const individualAccessList = z.array(individualAccess)
const visorsSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string()
  })
)

export type Visors = z.infer<typeof visorsSchema>
export type AccessGroup = z.infer<typeof individualAccessGroupSchema>
export type IndividualAccess = z.infer<typeof individualAccess>
export type IndividualAccessList = z.infer<typeof individualAccessList>

export const formVisorAccessData = z
  .object({
    center: z.number().nullable(),
    groups: z.array(z.string({ coerce: true }))
  })
  .transform((data) => ({
    center: data.center,
    groups: JSON.stringify(data.groups)
  }))

export type FormVisorAccessDataForm = z.input<typeof formVisorAccessData>
export type FormVisorAccessData = z.output<typeof formVisorAccessData>

const accessRoomSchema = z.object({
  room: z.string(),
  service: z.string(),
  isConnected: z.boolean()
})

export type AccessRoom = z.infer<typeof accessRoomSchema>

const guestSchema = z.object({
  id: z.number(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  mobile: z.string(),
  language: z.number(),
  appointmentCenter: z.number()
})

export type Guest = z.infer<typeof guestSchema>

export const guestFormSchema = z.object({
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  mobile: z.string(),
  language: z.number(),
  appointmentCenter: z.number()
})

export type GuestForm = z.infer<typeof guestFormSchema>
