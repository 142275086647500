import React, { RefObject, useCallback, useEffect, useState } from 'react'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { useTranslation } from 'react-i18next'
import { Button, Grid, TextField, MenuItem } from '@mui/material'
import { FormProvider, useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FloorPlansCreate, floorPlansCreateSchema } from 'api/models/floor_plans_management'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useMutationFetcher } from 'app/hooks/use-mutation-fetcher'
import { FormItems, ListFloors } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ControlledDatePicker } from 'app/components/form/controlled-datepicker.component'
import { ControlledTextField } from 'app/components/form/controlled-textfield.component'
import { ControlledSelectField } from 'app/components/form/controlled-select.component'
import { useFeedback } from 'app/providers/feedback.provider'

interface IDialogAddFloorPlanProps {
  dialogRef: RefObject<DialogRef>
  refreshList: () => void
}

export const DialogAddFloorPlan = function ({ dialogRef, refreshList }: IDialogAddFloorPlanProps) {
  const { t } = useTranslation()
  const { FloorPlansManagement } = useFetcherV2()
  const { getFormItems } = useFetcher()
  const [formItems, setFormItems] = useState({} as FormItems)
  const [floors, setFloors] = useState<ListFloors>([])
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([['centers', 'center']])
  )
  const { FloorPlans } = useFetcherV2()

  const initOptions = useCallback(async (commonOptions: Map<string, string>) => {
    await getFormItems.mutateAsync(Array.from(commonOptions.keys() as any)).then((optionsData) => {
      setFormItems(optionsData as FormItems)
    })
  }, [])

  const method = useForm<FloorPlansCreate>({
    resolver: zodResolver(floorPlansCreateSchema),
    mode: 'onChange'
  })

  useEffect(() => {
    const centerId = method.getValues('center')
    if (centerId) {
      FloorPlans.listFloors(centerId.toString()).then((data: ListFloors) => {
        setFloors(data)
      })
    }
  }, [method.watch('center')])

  useEffect(() => {
    initOptions(commonOptions).then()
  }, [commonOptions, initOptions])

  const handleSubmit = async (data: FloorPlansCreate) => {
    createFloorPlan(data)
  }

  const { toast } = useFeedback()
  const isFormValid = method.formState.isValid

  const createFloorPlan = useMutationFetcher({
    confirm: { content: t('confirm_add_plan') },
    mutationKey: ['floor-plans-management', 'create'],
    mutationFn: (data: FloorPlansCreate) => {
      return FloorPlansManagement.create(data)
    },
    toastSuccess: t('floor_plan_created_success'),
    onError: (error: any) => {
      toast({ message: error.message ?? t('an_error_occurred'), variant: 'error' })
    },
    onSuccess: () => {
      dialogRef.current?.close()
      refreshList()
    }
  })

  return (
    <Dialog
      ref={dialogRef}
      title={t('floor_plan_add')}
      maxWidth="xs"
      actions={
        <>
          <Button onClick={() => dialogRef.current?.close()} data-cy="cancel-button">
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={!isFormValid}
            onClick={method.handleSubmit(handleSubmit)}
            data-cy="save-button"
          >
            {t('save')}
          </Button>
        </>
      }
    >
      <FormProvider {...method}>
        <form onSubmit={method.handleSubmit(handleSubmit)} style={{ width: '100%' }}>
          <Grid container spacing={4} columns={1} sx={{ paddingTop: 2 }}>
            <Grid item xs={1}>
              <ControlledSelectField
                name={'center'}
                control={method.control}
                label={t('center')}
                formItem={formItems.centers}
                scrollable={true}
                data-cy="center-input"
              />
            </Grid>
            <Grid item xs={1}>
              <Controller
                name="floor"
                control={method.control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    select
                    label={t('floor')}
                    fullWidth
                    required
                    value={field.value || ''}
                    data-cy="floor-input"
                  >
                    {(floors ?? []).map((floor) => (
                      <MenuItem key={floor.floor} value={floor.floor}>
                        {floor.floor}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={1} data-cy="date-input">
              <ControlledDatePicker
                label={t('begin_date')}
                control={method.control}
                required
                name="begin"
              />
            </Grid>
            <Grid item xs={1} data-cy="version-input">
              <ControlledTextField
                label={t('version')}
                name="index"
                control={method.control}
                variant="outlined"
                required={true}
                type="number"
                fullWidth
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Dialog>
  )
}
