import { useCallback, useEffect, useState } from 'react'
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
  Chip,
  Box,
  Stack
} from '@mui/material'
import { Client } from 'api/models'
import { Control, Controller } from 'react-hook-form'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useTranslation } from 'react-i18next'
import { useDebouce } from 'app/hooks/use-debounce'

interface IControlledSelectGuestsFieldProps {
  label: string
  name: string
  control: Control<any>
  defaultValue?: string
  required?: boolean
  disabled?: boolean
  onChange?: (item: Client) => void
}

export function ControlledSelectGuestsField({
  name,
  label,
  control,
  defaultValue,
  required = false,
  disabled = false,
  onChange
}: IControlledSelectGuestsFieldProps) {
  const { getClientFilters } = useFetcher()
  const { t } = useTranslation()
  const [clients, setClients] = useState<Client[]>([])
  const [selectedGuests, setSelectedGuests] = useState<Client[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(defaultValue || '')

  const handleTyping = useCallback(async (event: any) => {
    if (!event) return
    const value = event.target.value as string
    setInputValue(value)
  }, [])

  useDebouce(
    () => {
      if (inputValue.length < 2) return
      setLoading(true)
      getClientFilters
        .mutateAsync({
          name: inputValue,
          is_enterprise: false,
          is_individual: true,
          is_individual_guest: true
        })
        .then((data) => {
          const _clients = data
            .map((obj) => {
              if (!obj.children) return null
              return obj.children
            })
            .filter((obj) => obj !== null)
            .flat() as unknown as Client[]

          const filteredClients = _clients.filter(
            (client) => !selectedGuests.some((selected) => selected.id === client.id)
          )

          setClients(filteredClients)
        })
        .finally(() => setLoading(false))
    },
    [inputValue, selectedGuests],
    350
  )

  useEffect(() => {
    setInputValue(defaultValue ?? '')
  }, [defaultValue])

  return (
    <Controller
      control={control}
      disabled={disabled}
      name={name}
      render={({ field: { onChange: onFormChange, value } }) => {
        const handleGuestSelection = (_: any, newValue: Client | null) => {
          if (newValue) {
            const updatedGuests = [...(value ?? []), Number(newValue.id)]
            setSelectedGuests((prevGuests) => [...prevGuests, newValue])
            onFormChange(updatedGuests)
            setInputValue('')
            setClients([])
            if (onChange) {
              onChange(newValue)
            }
          }
        }

        const handleDeleteGuest = (guestToDelete: Client) => {
          const updatedGuests = selectedGuests.filter((guest) => guest.id !== guestToDelete.id)
          setSelectedGuests(updatedGuests)
          onFormChange(updatedGuests.map((guest) => Number(guest.id)))
        }

        return (
          <FormControl fullWidth size={'small'} required={required}>
            <Stack spacing={2}>
              <Autocomplete
                disablePortal
                disabled={disabled}
                autoComplete
                size={'small'}
                onChange={handleGuestSelection}
                value={null}
                inputValue={inputValue}
                onInputChange={handleTyping}
                loadingText={t('loading')}
                noOptionsText={t('no_options')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.text}
                selectOnFocus
                options={clients}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required={required}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                    label={label}
                  />
                )}
              />
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {selectedGuests.map((guest) => (
                  <Chip
                    key={guest.id}
                    label={guest.text}
                    onDelete={() => handleDeleteGuest(guest)}
                    size="small"
                    sx={{ m: 0.5 }}
                  />
                ))}
              </Box>
            </Stack>
          </FormControl>
        )
      }}
    />
  )
}
