import { useFeedback } from 'app/providers/feedback.provider'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Confirm } from 'app/components/dialog/confirm-dialog.component'

interface IUseMutationFetcherOptions<T, U> extends UseMutationOptions<T, unknown, U> {
  toastSuccess?: string
  toastError?: string
  confirm?: Omit<Confirm, 'onConfirm'>
}

export const useMutationFetcher = function <T, U>(params: IUseMutationFetcherOptions<T, U>) {
  const { toast, confirm } = useFeedback()
  const mutation = useMutation({
    ...params,
    onSuccess: (data, variables, context) => {
      if (params.toastSuccess) {
        toast({
          variant: 'success',
          message: params.toastSuccess
        })
      }

      if (params.onSuccess) params.onSuccess(data, variables, context)
    },
    onError: (error, variables, context) => {
      toast({
        variant: 'error',
        message: params.toastError ?? 'error'
      })
      if (params.onError) {
        params.onError(error, variables, context)
      }
    }
  })

  if (params.confirm !== undefined) {
    return (data: U) => {
      const props = params.confirm
      confirm({
        ...props,
        content: props?.content,
        onConfirm: async () => {
          await mutation.mutateAsync(data)
        }
      })
    }
  } else {
    return mutation.mutateAsync
  }
}
