import { type Ctx, fetcher } from 'api'
import { BASE_URL, API } from 'api/constants'
import urlHelper from 'app/helpers/url.helper'
import {
  ClientRelationFilter,
  ClientRelationFilterResponse,
  ClientRelationList,
  CreateClientRelation
} from 'api/models'

export const clientRelationRouter = ({ token }: Ctx) => ({
  list: async (client: number) =>
    fetcher<ClientRelationList>({
      url: `${BASE_URL}${urlHelper(API.CLIENT_RELATION, { client })}`,
      method: 'GET',
      token: token
    }),
  filter: async (client: number, params: ClientRelationFilter) => {
    const nameParam = params.name ? `?name=${params.name}` : ''
    return fetcher<ClientRelationFilterResponse>({
      url: `${BASE_URL}${urlHelper(API.CLIENT_RELATION_FILTER, { client })}${nameParam}`,
      method: 'GET',
      token: token
    })
  },
  create: async (client: number, data: CreateClientRelation) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.CLIENT_RELATION, { client })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  remove: async (client: number, data: CreateClientRelation) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.CLIENT_RELATION, { client })}`,
      method: 'DELETE',
      token: token,
      body: data
    })
})
