import React, { useRef, useState } from 'react'
import { Button, Stack, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFeedback } from 'app/providers/feedback.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { useApp } from 'app/providers/app.provider'

export const AddGuestModal = () => {
  const dialogRef = useRef<DialogRef>(null)
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const { createGuest } = useFetcher()
  const { user } = useApp()

  const [firstname, setFirstname] = useState<string>('')
  const [lastname, setLastname] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [mobile, setMobile] = useState<string>('')

  const openDialog = () => {
    dialogRef.current?.open()
    setFirstname('')
    setLastname('')
    setEmail('')
    setMobile('')
  }

  const handleAddGuest = async () => {
    if (!firstname || !lastname || !email || !mobile) {
      return
    }

    await handleMutation({
      confirm: {
        content: t('confirm_guest_add')
      },
      mutation: createGuest,
      data: {
        firstname: firstname,
        lastname: lastname,
        email: email,
        mobile: mobile,
        language: user?.languages[0].id ?? 1,
        appointmentCenter: user?.mainCenter ?? 1
      },
      toastSuccess: t('guest_added_successfully'),
      toastError: t('guest_add_failed'),
      onSuccess: (data) => {
        dialogRef.current?.close()
      }
    })
  }

  return (
    <>
      <Button variant="contained" onClick={openDialog}>
        {t('add_guest')}
      </Button>
      <Dialog
        ref={dialogRef}
        title={t('add_guest')}
        actions={
          <>
            <Button onClick={() => dialogRef.current?.close()} variant="outlined">
              {t('cancel')}
            </Button>
            <Button
              onClick={handleAddGuest}
              variant="contained"
              disabled={!firstname || !lastname || !email || !mobile}
            >
              {t('add')}
            </Button>
          </>
        }
      >
        <Stack spacing={3} marginTop={2}>
          <TextField
            label={t('firstname')}
            size={'small'}
            variant="outlined"
            fullWidth
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />
          <TextField
            label={t('lastname')}
            size={'small'}
            variant="outlined"
            fullWidth
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
          <TextField
            label={t('email')}
            size={'small'}
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label={t('mobile')}
            size={'small'}
            variant="outlined"
            fullWidth
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </Stack>
      </Dialog>
    </>
  )
}
