import { Autocomplete, FormControl, TextField } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { FormItem } from 'api/models'
import { IAutocompleteSelect } from 'app/components/form/autocomplete-select'
import { Stack } from '@mui/system'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

type IControlledTextFieldProps<T> = {
  formItem: FormItem
  control: Control
  required: boolean
  allowNew: boolean
  returnIdOnly?: boolean
} & IAutocompleteSelect<T>

export function ControlledAutocomplete<T>({
  name,
  label,
  control,
  formItem,
  required,
  allowNew = false,
  returnIdOnly = false
}: IControlledTextFieldProps<T>) {
  const [customChoice, setCustomChoice] = useState<any>()
  const { t } = useTranslation()

  const items = useMemo(() => {
    let _items = formItem ? [...formItem.values] : []
    if (allowNew && customChoice) {
      _items.push(customChoice)
    }
    return _items
  }, [formItem, customChoice, allowNew])

  return (
    <Controller
      render={({ field: { value, onChange } }) => {
        const selectedValue =
          typeof value === 'number' ? items.find((item) => item.id === value) || null : value
        return (
          <FormControl fullWidth size={'small'}>
            <Stack>
              <Autocomplete
                autoComplete
                size={'small'}
                value={selectedValue}
                onChange={(_: any, newValue: any) => {
                  onChange(returnIdOnly ? newValue?.id : newValue)
                }}
                onInputChange={
                  allowNew
                    ? (_, value) => {
                        setCustomChoice({ id: '', label: value })
                      }
                    : undefined
                }
                loadingText={t('loading')}
                noOptionsText={t('no_options')}
                isOptionEqualToValue={(option, value) => option['id'] === value['id']}
                getOptionLabel={(option) => option['label']}
                getOptionKey={(option) => option['id']}
                options={items}
                clearOnBlur={false}
                renderInput={(params) => (
                  <TextField required={required} {...(params as any)} label={label} />
                )}
              />
            </Stack>
          </FormControl>
        )
      }}
      control={control}
      name={name}
    />
  )
}
