import React, { RefObject, useEffect, useMemo } from 'react'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { useTranslation } from 'react-i18next'
import { Button, Grid, TextField } from '@mui/material'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ControlledDatePicker } from 'app/components/form/controlled-datepicker.component'
import dayjs from 'dayjs'
import {
  FloorPlanDuplicateInput,
  floorPlanDuplicateInputSchema,
  FloorPlanListItem
} from 'api/models/floor_plans_management'
import { ControlledTextField } from 'app/components/form/controlled-textfield.component'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useMutationFetcher } from 'app/hooks/use-mutation-fetcher'

interface IDialogDuplicateFloorPlanProps {
  dialogRef: RefObject<DialogRef>
  floorPlan: FloorPlanListItem | null
  refreshList: () => void
}

export const DialogDuplicateFloorPlan = function ({
  dialogRef,
  floorPlan,
  refreshList
}: IDialogDuplicateFloorPlanProps) {
  const { t } = useTranslation()
  const { FloorPlansManagement } = useFetcherV2()

  const method = useForm<FloorPlanDuplicateInput>({
    mode: 'onChange',
    resolver: zodResolver(floorPlanDuplicateInputSchema)
  })

  useEffect(() => {
    if (floorPlan) {
      method.setValue('version', String(Number(floorPlan.index) + 1))
    }
  }, [floorPlan])

  const { isValid, isSubmitting } = method.formState

  const duplicateFloorPlan = useMutationFetcher({
    confirm: { content: t('confirm_duplicate_plan') },
    mutationKey: ['floor-plans-management', floorPlan?.id, 'duplicate'],
    mutationFn: (data: FloorPlanDuplicateInput) => {
      return FloorPlansManagement.duplicate(String(floorPlan?.id), data)
    },
    toastSuccess: t('duplicate_floor_plan_success'),
    toastError: t('an_error_occurred'),
    onSuccess: () => {
      dialogRef.current?.close()
      refreshList()
    }
  })

  const handleSubmit = async (data: FloorPlanDuplicateInput) => {
    duplicateFloorPlan(data)
  }

  const minDate = useMemo(() => {
    if (!floorPlan) return null
    const today = dayjs().startOf('day')
    const floorPlanBeginDate = dayjs(floorPlan.begin).startOf('day')
    if (today.isAfter(floorPlanBeginDate)) {
      return today.add(1, 'day')
    }
    return floorPlanBeginDate.add(1, 'day')
  }, [floorPlan])

  return (
    <Dialog
      ref={dialogRef}
      title={t('duplicate_floor')}
      maxWidth="xs"
      actions={
        <>
          <Button variant="outlined" onClick={dialogRef.current?.close} data-cy="cancel-button">
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={!isValid || isSubmitting}
            onClick={method.handleSubmit(handleSubmit)}
            data-cy="save-button"
          >
            {t('duplicate')}
          </Button>
        </>
      }
    >
      <Grid container spacing={4} columns={1} sx={{ paddingTop: 2 }}>
        <Grid item xs={1}>
          <TextField
            label={t('center')}
            size="small"
            fullWidth
            defaultValue={floorPlan?.centerName}
            disabled
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            label={t('floor')}
            size="small"
            fullWidth
            defaultValue={floorPlan?.floor}
            disabled
          />
        </Grid>
        <Grid item xs={1} data-cy="date-input">
          <ControlledDatePicker
            label={t('begin_date')}
            control={method.control}
            required
            name="begin"
            minDate={minDate}
          />
        </Grid>
        <Grid item xs={1} data-cy="version-input">
          <ControlledTextField
            label={t('version')}
            name="version"
            control={method.control}
            variant="outlined"
            required={true}
            size="small"
            type="number"
            fullWidth
            InputProps={{
              inputProps: { min: Number(floorPlan?.index) + 1 }
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}
