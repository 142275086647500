import React, { createContext, ReactNode, useCallback, useContext } from 'react'
import {
  DefaultError,
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  useQuery
} from '@tanstack/react-query'
import { NavigateOptions, URLSearchParamsInit, useSearchParams } from 'react-router-dom'
import {
  AddClusterProps,
  AvailableDesktopsSearch,
  CenterAddProps,
  CenterDetails,
  ClientContractParams,
  CompanyBankAccountBody,
  ConsumptionsListParams,
  ConsumptionsServiceBody,
  ContractServiceLinkUser,
  ContractUpdate,
  CreateCenterDataDiscount,
  CreateCenterPriceInputs,
  CreateHappeningForm,
  CreateHappeningPartnerForm,
  CreateUpdateStaffForm,
  CreditDeclarePayment,
  DiscountGridAdd,
  DiscountGridsCenterBody,
  EditClusterProps,
  EnterpriseDocumentsListParams,
  EventFormData,
  FilterCenterInput,
  FilterClientInput,
  FilterContractsParams,
  FilterMemberInput,
  FilterSelectInput,
  FiltersInput,
  FilterStaffInput,
  FloorPlansParams,
  FormItems,
  FormItemsInput,
  FormItemsInputWithFilters,
  FormVisorAccessData,
  IndividualDetails,
  IndividualFormDetails,
  LinkIndividualProps,
  MainServiceAddBody,
  MainServiceBody,
  MainServiceComputePriceBody,
  NotificationUpdate,
  OpportunityList,
  OptionServiceBody,
  ParkingServiceAddBody,
  ParkingServiceBody,
  Payment,
  PostCompany,
  PresenceScheduleList,
  Right,
  StaffsListAttribution,
  TertiaryBody,
  UpdateClientLinkProps,
  UpdateHappening,
  UpdateTermOfSalesInput,
  UploadTermOfSalesInput,
  WifiPasswordUpdate,
  AccoutingStatement,
  AppointmentList,
  GuestForm,
  IdReturn,
  PackagesVerifyCodeSchema,
  ClientRelationFilter,
  CreateClientRelation
} from 'api/models'
import {
  OpportunityCreateFormData,
  OpportunityUpdateFormProps,
  QuotationCreateFormData
} from 'api/models/forms/opportunities'
import { EmailTemplateForm } from 'api/models/forms/email_template'
import { UpdateQuotationFormItems } from 'api/models/forms/quotations'
import { PricingConsumptionInputs } from 'api/models/pricing'
import { AddReservationConsumptionItems, FormProps } from 'api/models/forms/consumptions'
import { CreateEnterpriseForm } from 'api/models/forms/enterprises'
import { ClientRequestFormProps } from 'api/models/forms/client_requests'
import { CreateCustomerReservation, LinkToAccess } from 'api/models/forms/customer_reservations'
import { CancelContractProps, UpdateAdminContractProps } from 'api/models/forms/contract'
import { EndorsementUpdateData } from 'api/models/forms/endorsement'
import {
  CaByTypesByCenters,
  CenterAcquisitions,
  PricePlaceParking,
  StaffAcquisitions
} from 'api/models/stats'
import { CreateSourceFormData } from 'api/models/sources'
import { UpdateAcquisitionCostProps } from 'api/models/forms/sources'
import { ClientCenterForm, ClientCenterMailForm } from 'api/models/forms/client_center'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { CreatePackageFormSchema } from 'api/models/packages'
import { infiniteQueryDefaultParams } from 'api/models/commons'
import { AppointmentForm } from 'api/models/forms/appointments'

type QueryInfiniteOptions<T> = Omit<
  UseInfiniteQueryOptions<T, DefaultError, InfiniteData<T>, T, QueryKey, number>,
  'queryKey' | 'queryFn' | 'getPreviousPageParam' | 'getNextPageParam' | 'initialPageParam'
> & {
  additionalQueryKeys?: Array<unknown>
}
type QueryInfiniteHook<T> = (
  options?: QueryInfiniteOptions<T>
) => UseInfiniteQueryResult<InfiniteData<T, unknown>, Error>

interface IFetcherProviderProps {
  children: ReactNode
}

type IdStruct = { id: number }

const useQueries = function () {
  const [searchParams, setParams] = useSearchParams()
  const api = useFetcherV2()

  const setSearchParams = useCallback(
    (
      nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
      navigateOpts?: NavigateOptions
    ) => {
      setParams(nextInit, { ...navigateOpts, replace: true })
    },
    [setParams]
  )

  const activateAccount = useMutation<void, unknown, IdStruct>({
    mutationKey: ['clients', 'activate'],
    mutationFn: ({ id }) => api.Individuals.activate(id)
  })

  const getActivities = useMutation({
    mutationKey: ['activities', 'list'],
    mutationFn: ({
      type,
      page,
      id,
      activityType
    }: {
      type: string
      id?: number
      page: number
      activityType: string
    }) => api.Activities.list(type, page, id, activityType)
  })

  const getCompanyBankAccounts = useMutation({
    mutationKey: ['companies', 'bank-account'],
    mutationFn: (id: number) => api.Companies.getCompanyBankAccounts(id)
  })

  const createCompanyBankAccount = useMutation({
    mutationKey: ['companies', 'bank-account', 'create'],
    mutationFn: (data: CompanyBankAccountBody) => {
      return api.Companies.createBankAccount(data.companyId || 0, data)
    }
  })

  const updateCompanyBankAccount = useMutation<
    void,
    unknown,
    { id: number; data: CompanyBankAccountBody }
  >({
    mutationKey: ['companies', 'bank-account', 'update'],
    mutationFn: ({ id, data }) => api.Companies.updateBankAccount(id, data)
  })

  const getCompanyBankAccount = useMutation({
    mutationKey: ['companies', 'bank-account', 'get'],
    mutationFn: (id: number) => api.Companies.getBankAccount(id)
  })

  const availableCentersCompany = useMutation({
    mutationKey: ['available', 'centers'],
    mutationFn: () => api.Companies.getAvailableCenters()
  })

  const postCompany = useMutation<void, unknown, PostCompany>({
    mutationKey: ['companies', 'create'],
    mutationFn: (data) => api.Companies.postCompany(data)
  })

  const getEnterprises = useMutation({
    mutationKey: ['enterprises', 'list'],
    mutationFn: () => api.Enterprises.list(searchParams.toString())
  })

  const createEnterprise = useMutation({
    mutationKey: ['clients', 'create'],
    mutationFn: (data: CreateEnterpriseForm) => api.Enterprises.create(data)
  })

  const createEnterprisePrescriber = useMutation<void, unknown, { id: number; type: string }>({
    mutationKey: ['clients', 'prescriber', 'create'],
    mutationFn: ({ id, type }) => api.Enterprises.createPrescriber(id, type)
  })

  const getEnterprisesExport = useMutation<void>({
    mutationKey: ['enterprises', 'export'],
    mutationFn: () => api.Enterprises.getExport(searchParams.toString())
  })

  const agreeContract = useMutation<void, unknown, number>({
    mutationKey: ['contracts', 'cancel'],
    mutationFn: (id) => api.Contracts.agree(id)
  })

  const cancelContract = useMutation<void, unknown, { id: number; data: CancelContractProps }>({
    mutationKey: ['contracts', 'cancel'],
    mutationFn: ({ id, data }) => api.Contracts.cancel(id, data)
  })

  const downloadContract = useMutation<void, unknown, { id: number; reference: string }>({
    mutationKey: ['contracts', 'download'],
    mutationFn: ({ id, reference }) => api.Contracts.download(id, reference)
  })

  const validateContract = useMutation<void, unknown, number>({
    mutationKey: ['contracts', 'validate'],
    mutationFn: (id) => api.Contracts.validate(id)
  })

  const createContractEndorsement = useMutation<any, unknown, { id: number; date: string }>({
    mutationKey: ['contracts', 'endorsements', 'create'],
    mutationFn: ({ id, date }) => api.Contracts.createEndorsement(id, date)
  })

  const updateContractEndorsement = useMutation<
    any,
    unknown,
    { id: number; data: EndorsementUpdateData }
  >({
    mutationKey: ['contracts', 'endorsements', 'update'],
    mutationFn: ({ id, data }) => api.Contracts.updateEndorsement(id, data)
  })
  const getContracts = useMutation({
    mutationKey: ['contracts', 'list'],
    mutationFn: () => api.Contracts.list(searchParams.toString())
  })

  const getContractEndorsements = useMutation({
    mutationKey: ['contracts', 'endorsements'],
    mutationFn: (id: number) => api.Contracts.getEndorsements(id)
  })

  const getContractAutoRenewalEndorsements = useMutation({
    mutationKey: ['contracts', 'endorsements'],
    mutationFn: (id: number) => api.Contracts.getAutoRenewalEndorsements(id)
  })

  const getContractSelectOptions = useMutation<
    any,
    unknown,
    { id: number; serviceTypeReference: string }
  >({
    mutationKey: ['contracts', 'select-options'],
    mutationFn: ({ id, serviceTypeReference }) =>
      api.Contracts.getSelectOptions(id, serviceTypeReference)
  })

  const stopContractService = useMutation<
    void,
    unknown,
    { id: number; serviceId: number; end: string }
  >({
    mutationKey: ['contracts', 'stop-service'],
    mutationFn: ({ id, serviceId, end }) => api.Contracts.stopService(id, serviceId, end)
  })

  const getContractSum = useMutation({
    mutationKey: ['contracts', 'sum'],
    mutationFn: () => api.Contracts.getSum(searchParams.toString())
  })

  const getContractExport = useMutation({
    mutationKey: ['contracts', 'export'],
    mutationFn: () => api.Contracts.getExport(searchParams.toString())
  })

  const getContract = useMutation({
    mutationKey: ['contract', 'read'],
    mutationFn: (id: number) => api.Contracts.getRead(id)
  })

  const updateContractAdmin = useMutation<
    void,
    unknown,
    { id: number; data: UpdateAdminContractProps }
  >({
    mutationKey: ['contract', 'admin-update'],
    mutationFn: ({ id, data }) => api.Contracts.adminUpdate(id, data)
  })

  const getContractMain = useMutation({
    mutationKey: ['contract main', 'main'],
    mutationFn: () => api.Contracts.getMain(Number(searchParams.get('id')))
  })

  const getContractOption = useMutation({
    mutationKey: ['contract option', 'option'],
    mutationFn: () => api.Contracts.getOption(Number(searchParams.get('id')))
  })

  const getContractConsumption = useMutation({
    mutationKey: ['contract consumption', 'consumption'],
    mutationFn: () => api.Contracts.getConsumption(Number(searchParams.get('id')))
  })

  const getContractInvoice = useMutation({
    mutationKey: ['contract invoice', 'invoice'],
    mutationFn: () => api.Contracts.getInvoice(Number(searchParams.get('id')))
  })

  const getContractPayments = useMutation<any, unknown, number>({
    mutationKey: ['contract', 'payments'],
    mutationFn: (id: number) => api.Contracts.getPayments(id)
  })

  const getContractUsers = useMutation({
    mutationKey: ['contract', 'payments'],
    mutationFn: (id: number) => api.Contracts.getUsers(id)
  })

  const getContractLitigations = useMutation({
    mutationKey: ['contract', 'litigations'],
    mutationFn: (id: number) => api.Contracts.getLitigations(id)
  })

  const getContractIndexingAnniversary = useMutation({
    mutationKey: ['contract indexing anniversary', 'indexing'],
    mutationFn: () => api.Contracts.getIndexingAnniversary(Number(searchParams.get('id')))
  })

  const linkContractServiceUser = useMutation({
    mutationKey: ['contract indexing anniversary', 'indexing'],
    mutationFn: ({ id, data }: { id: number; data: ContractServiceLinkUser }) =>
      api.Contracts.linkServiceUser(id, data)
  })

  const updateContractOccupant = useMutation<
    void,
    unknown,
    { id: number; occupants: { [key: string]: number } }
  >({
    mutationKey: ['contract occupant', 'occupant'],
    mutationFn: (params) => api.Contracts.updateOccupant(params)
  })

  const getWifiLogs = useMutation({
    mutationKey: ['wifi', 'logs'],
    mutationFn: () => api.Wifi.getLogs(searchParams.toString())
  })

  const getInvoices = useMutation({
    mutationKey: ['invoices', 'list'],
    mutationFn: () => api.Invoices.list(searchParams.toString())
  })

  const getInvoicesExport = useMutation({
    mutationKey: ['invoices', 'list'],
    mutationFn: () => api.Invoices.exportInvoices(searchParams.toString())
  })

  const getInvoice = useMutation({
    mutationKey: ['invoice', 'information'],
    mutationFn: (id: number) => api.Invoices.getInformation(id)
  })

  const getInvoicePdf = useMutation({
    mutationKey: ['invoice pdf', 'pdf'],
    mutationFn: (id: number) => api.Invoices.getPdf(id)
  })

  const postManualBankDebitInvoice = useMutation({
    mutationKey: ['invoice manual bank debit', 'manual bank debit'],
    mutationFn: ({ id, data }: { id: number; data: string }) =>
      api.Invoices.postManualBankDebit(id, data)
  })

  const postAddPaymentInvoice = useMutation<any, unknown, { id: number; data: any }>({
    mutationKey: ['invoice add payment', 'add payment'],
    mutationFn: ({ id, data }) => api.Invoices.addPayment(id, data)
  })

  const patchLinkPaymentInvoice = useMutation<any, unknown, { id: number; payment: number }>({
    mutationKey: ['invoice link payment', 'link payment'],
    mutationFn: ({ id, payment }) => api.Invoices.linkPayment(id, payment)
  })

  const patchUnLinkPaymentInvoice = useMutation<any, unknown, { id: number; payment: number }>({
    mutationKey: ['invoice unlink payment', 'unlink payment'],
    mutationFn: ({ id, payment }) => api.Invoices.unLinkPayment(id, payment)
  })

  const patchUnLinkCreditInvoice = useMutation<any, unknown, { id: number; credit: number }>({
    mutationKey: ['invoice unlink credit', 'unlink credit'],
    mutationFn: ({ id, credit }) => api.Invoices.unLinkCredit(id, credit)
  })

  const patchCancelledPaymentInvoice = useMutation<any, unknown, { id: number; payment: number }>({
    mutationKey: ['invoice cancelled payment', 'cancelled payment'],
    mutationFn: ({ id, payment }) => api.Invoices.cancelledGc(id, payment)
  })

  const getPaymentsInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice payments', 'payments'],
    mutationFn: (id) => api.Invoices.getPayments(id)
  })

  const getCreditsInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice credits', 'credits'],
    mutationFn: (id) => api.Invoices.getCredits(id)
  })

  const getInvoiceActivities = useMutation({
    mutationKey: ['invoice activities', 'activities'],
    mutationFn: ({ id, type }: { id: number; type: string }) => api.Invoices.getActivities(id, type)
  })

  const patchRegeneratePdfInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice regenerate pdf', 'regenerate pdf'],
    mutationFn: (id) => api.Invoices.patchRegeneratePdf(id)
  })

  const patchAccountInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice account', 'account'],
    mutationFn: (id) => api.Invoices.patchAccount(id)
  })

  const patchUnAccountInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice unaccount', 'unaccount'],
    mutationFn: (id) => api.Invoices.patchUnAccount(id)
  })

  const patchBlockInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice block', 'block'],
    mutationFn: (id) => api.Invoices.patchBlock(id)
  })

  const patchUnBlockInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice unblock', 'unblock'],
    mutationFn: (id) => api.Invoices.patchUnBlock(id)
  })

  const postUnCollectibleInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice uncollectible', 'uncollectible'],
    mutationFn: (id) => api.Invoices.postUnCollectible(id)
  })

  const postManualReminderInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice manual reminder', 'manual reminder'],
    mutationFn: (id) => api.Invoices.postManualReminder(id)
  })

  const getRowsInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice rows', 'rows'],
    mutationFn: (id) => api.Invoices.getRows(id)
  })

  const patchLitigationInvoice = useMutation<
    any,
    unknown,
    { id: number; data: { reference: string } }
  >({
    mutationKey: ['invoice litigation', 'litigation'],
    mutationFn: ({ id, data }) => api.Invoices.patchLitigation(id, data)
  })

  const postLitigationContract = useMutation<any, unknown, { id: number; reference: string }>({
    mutationKey: ['invoice litigation', 'litigation'],
    mutationFn: ({ id, reference }) => api.Contracts.postLitigation(id, reference)
  })

  const patchDiscountInvoice = useMutation<void, unknown, { id: number; data: any }>({
    mutationKey: ['invoice discount', 'discount'],
    mutationFn: ({ id, data }) => api.Invoices.patchDiscount(id, data)
  })

  const patchLanguageInvoice = useMutation<
    void,
    unknown,
    { id: number; data: { language: number } }
  >({
    mutationKey: ['invoice language', 'language'],
    mutationFn: ({ id, data }) => api.Invoices.patchLanguage(id, data)
  })

  const patchDueDateInvoice = useMutation<void, unknown, { id: number; data: { dueDate: string } }>(
    {
      mutationKey: ['invoice due date', 'due date'],
      mutationFn: ({ id, data }) => api.Invoices.patchDueDate(id, data)
    }
  )

  const getCredits = useMutation({
    mutationKey: ['credits', 'list'],
    mutationFn: () => api.Credits.list(searchParams.toString())
  })

  const getCreditSum = useMutation({
    mutationKey: ['credits', 'sum'],
    mutationFn: () => api.Credits.getSum(searchParams.toString())
  })

  const getCreditExport = useMutation({
    mutationKey: ['credits', 'export'],
    mutationFn: () => api.Credits.getExport(searchParams.toString())
  })

  const getCredit = useMutation({
    mutationKey: ['credit', 'information'],
    mutationFn: () => api.Credits.getInformation(Number(searchParams.get('id')))
  })

  const getCreditInvoiceSelect = useMutation({
    mutationKey: ['credit invoice select', 'invoice select'],
    mutationFn: () => api.Credits.getInvoiceSelect(Number(searchParams.get('id')))
  })

  const getCreditPdf = useMutation({
    mutationKey: ['credit pdf', 'pdf'],
    mutationFn: () => api.Credits.getPdf(Number(searchParams.get('id')))
  })

  const updateCreditRegeneratePdf = useMutation({
    mutationKey: ['credit update pdf', 'update'],
    mutationFn: () => api.Credits.updatePdf(Number(searchParams.get('id')))
  })

  const updateCreditApplyInvoice = useMutation<void, unknown, { id: number; invoiceId: number }>({
    mutationKey: ['credit apply on invoice', 'apply'],
    mutationFn: (params) => api.Credits.updateApplyInvoice(params)
  })

  const addCreditDeclarePayment = useMutation<void, unknown, CreditDeclarePayment & IdStruct>({
    mutationKey: ['credit declare payment', 'declare'],
    mutationFn: (params) => api.Credits.addDeclarePayment(params)
  })

  const getCreditInvoice = useMutation({
    mutationKey: ['credit invoice', 'invoice'],
    mutationFn: () => api.Credits.getInvoice(Number(searchParams.get('id')))
  })

  const getCreditPayment = useMutation({
    mutationKey: ['credit payment', 'payment'],
    mutationFn: () => api.Credits.getPayment(Number(searchParams.get('id')))
  })

  const patchLanguageCredit = useMutation<void, unknown, any>({
    mutationKey: ['credit language', 'language'],
    mutationFn: (data) => api.Credits.patchLanguage(Number(searchParams.get('id')), data)
  })

  const getCreditActivities = useMutation({
    mutationKey: ['credit activities', 'activities'],
    mutationFn: (type: string) => api.Credits.getActivities(Number(searchParams.get('id')), type)
  })

  const getInvoiceSum = useMutation({
    mutationKey: ['invoices', 'sum'],
    mutationFn: () => api.Invoices.getSum(searchParams.toString())
  })

  const getFilters = useMutation({
    mutationKey: ['filters', 'list'],
    mutationFn: ({ variables, params }: { variables: FiltersInput; params: any }) =>
      api.Filters.list(variables, params)
  })

  const getFormItems = useMutation({
    mutationKey: ['formItems', 'list'],
    mutationFn: (variables: FormItemsInput) => api.FormItems.list(variables)
  })

  const getFormItemsWithFilters = useMutation({
    mutationKey: ['formItems', 'listWithFilter'],
    mutationFn: (variables: FormItemsInputWithFilters) => api.FormItems.listWithFilters(variables)
  })

  const useGetFormItems = (variables: FormItemsInput) =>
    useQuery<FormItems>({
      queryKey: ['query', 'formItems', 'list', ...variables],
      queryFn: () => api.FormItems.list(variables)
    })

  const getClientFilters = useMutation({
    mutationKey: ['filters', 'client'],
    mutationFn: (variables: FilterClientInput) => api.Filters.getClient(variables)
  })

  const getCenter = useMutation({
    mutationKey: ['centers', 'access groups'],
    mutationFn: (id: number) => api.Centers.getOne(id)
  })

  const useGetCenter = (id: number) =>
    useQuery({
      queryKey: ['centers', id === 0 ? '' : id],
      queryFn: () => api.Centers.getOne(id)
    })

  const getCenterAccessGroups = useMutation({
    mutationKey: ['centers', 'access groups'],
    mutationFn: (id: number) => api.Centers.listAccessGroups(id)
  })

  const getCenterAccessRooms = useMutation({
    mutationKey: ['centers', 'access groups'],
    mutationFn: ({
      id,
      status,
      type
    }: {
      id: number
      status: string | null
      type: string | null
    }) => api.Centers.listAccessRooms(id, type, status)
  })

  const getCenterPrices = useMutation({
    mutationKey: ['center_prices', 'list'],
    mutationFn: () => api.Centers.listPrices(searchParams.toString())
  })

  const uploadBusinessPlanCSV = useMutation<void, unknown, File | FileList>({
    mutationKey: ['centers', 'business plan', 'upload'],
    mutationFn: (file) => api.Centers.uploadCSV(file)
  })

  const getBusinessPlan = useMutation({
    mutationKey: ['centers', 'business plan', 'get'],
    mutationFn: () => api.Centers.businessPlan()
  })

  const updateCenter = useMutation<void, unknown, { id: number; data: CenterDetails }>({
    mutationKey: ['center', 'update'],
    mutationFn: ({ id, data }) => api.Centers.update(id, data)
  })

  const getTertiaries = useMutation({
    mutationKey: ['tertiaries', 'list'],
    mutationFn: () => api.Tertiaries.list()
  })

  const getTypologies = useMutation({
    mutationKey: ['typologies', 'list'],
    mutationFn: () => api.Typologies.list(searchParams.toString())
  })

  const getCommitments = useMutation({
    mutationKey: ['commitments', 'list'],
    mutationFn: () => api.Commitments.list(searchParams.toString())
  })

  const createTertiary = useMutation({
    mutationKey: ['tertiary', 'create'],
    mutationFn: (data: TertiaryBody) => api.Tertiaries.create(data)
  })

  const createCenterPrice = useMutation({
    mutationKey: ['center_prices', 'create'],
    mutationFn: (data: CreateCenterPriceInputs) => api.Centers.createPrice(data)
  })

  const getCenterBlockedPrices = useMutation({
    mutationKey: ['center_price', 'list', 'blocked_prices'],
    mutationFn: (id: number) => api.Centers.listBlockedServicePrices(id)
  })

  const getCentersClusters = useMutation({
    mutationKey: ['centers', 'clusters'],
    mutationFn: () => api.Centers.listClusters(searchParams.toString())
  })

  const createCenterCluster = useMutation({
    mutationKey: ['centers', 'clusters', 'create'],
    mutationFn: (data: AddClusterProps) => api.Centers.createCluster(data)
  })

  const updateCenterCluster = useMutation<void, unknown, { id: number; data: EditClusterProps }>({
    mutationKey: ['centers', 'clusters', 'update'],
    mutationFn: ({ id, data }) => api.Centers.updateCluster(id, data)
  })

  const availableCentersCluster = useMutation({
    mutationKey: ['centers', 'clusters', 'available'],
    mutationFn: (id: number) => api.Centers.getAvailableCentersForCluster(id)
  })

  const getCenterVisors = useMutation({
    mutationKey: ['centers', 'visors'],
    mutationFn: () => api.Centers.listVisors()
  })

  const getCenterFilters = useMutation({
    mutationKey: ['filters', 'center'],
    mutationFn: (variables: FilterCenterInput) => api.Filters.getCenter(variables)
  })

  const getCentersSelect = useMutation({
    mutationKey: ['filters', 'select'],
    mutationFn: (variables: FilterCenterInput) => api.Filters.getCenters(variables)
  })

  const getEnterprisePrescriber = useMutation({
    mutationKey: ['enteprises', 'prescriber'],
    mutationFn: ({ prescriberId, enterpriseId }: { prescriberId: number; enterpriseId: number }) =>
      api.Prescribers.getEnterprisePrescriber(prescriberId, enterpriseId)
  })

  const getPrescriberMembers = useMutation({
    mutationKey: ['prescribers', 'members'],
    mutationFn: ({ prescriberId, enterpriseId }: { prescriberId: number; enterpriseId: number }) =>
      api.Prescribers.listMembers(prescriberId, enterpriseId)
  })

  const createPrescriberMemberSource = useMutation<
    void,
    unknown,
    { prescriberId: number; enterpriseId: number; individualId: number }
  >({
    mutationKey: ['prescribers', 'members'],
    mutationFn: ({ prescriberId, enterpriseId, individualId }) =>
      api.Prescribers.createMemberSource(prescriberId, enterpriseId, individualId)
  })

  const getPrescriberOpportunities = useMutation({
    mutationKey: ['prescribers', 'opportunities'],
    mutationFn: ({ prescriberId, enterpriseId }: { prescriberId: number; enterpriseId: number }) =>
      api.Prescribers.listOpportunities(prescriberId, enterpriseId)
  })

  const getCenters = useMutation({
    mutationKey: ['centers', 'list'],
    mutationFn: () => api.Centers.list()
  })

  const createCenters = useMutation({
    mutationKey: ['centers', 'add'],
    mutationFn: (data: CenterAddProps) => api.Centers.createCenter(data)
  })

  const getEnterpriseMembersFilters = useMutation({
    mutationKey: ['filters', 'enterprise_members'],
    mutationFn: (variables: FilterMemberInput) => api.Filters.getEnterpriseMembers(variables)
  })

  const getWifiSsidsFilters = useMutation({
    mutationKey: ['filters', 'enterprise_members'],
    mutationFn: (variables: FilterSelectInput) => api.Filters.getWifiSsids(variables)
  })

  const getWifiStationsFilters = useMutation({
    mutationKey: ['filters', 'enterprise_members'],
    mutationFn: (variables: FilterSelectInput) => api.Filters.getWifiStations(variables)
  })

  const getOptions = useMutation({
    mutationKey: ['options', 'list'],
    mutationFn: () => api.Options.list(searchParams.toString())
  })

  const getServiceCommitmentPrice = useMutation<any, void, { id: string; commitment: number }>({
    mutationKey: ['service', 'commitment-price'],
    mutationFn: ({ id, commitment }) => api.Services.getCommitmentPrice(id, commitment)
  })

  const getServiceAvailability = useMutation<
    { message: string },
    void,
    { id: number; begin?: string }
  >({
    mutationKey: ['service', 'availability'],
    mutationFn: ({ id, begin }) => api.Services.getServiceAvailability(id, begin)
  })

  const getServiceAvailableDesktops = useMutation<any, void, AvailableDesktopsSearch>({
    mutationKey: ['service', 'available'],
    mutationFn: (data) => api.Services.getAvailableDesktops(data)
  })

  const changeContractService = useMutation<
    void,
    unknown,
    {
      id: string
      contractServiceId: number
      data: {
        contractServiceId: number
        serviceId: number
      }
    }
  >({
    mutationKey: ['customer reservations', 'contract service', 'change'],
    mutationFn: ({ id, contractServiceId, data }) =>
      api.Contracts.changeService(id, contractServiceId, data)
  })

  const getServicePricing = useMutation({
    mutationKey: ['service', 'pricing-consumptions'],
    mutationFn: ({ id, data }: any) => api.Services.getPricing(id, data)
  })

  const getServicesAvailable = useMutation<any, void, any>({
    mutationKey: ['service', 'available'],
    mutationFn: (data) => api.Services.getAvailable(data)
  })

  const getServiceConsumptionsPricing = useMutation({
    mutationKey: ['service', 'pricing-consumptions'],
    mutationFn: (params: PricingConsumptionInputs) => api.Services.getConsumptionPricing(params)
  })

  const getServicesOccupancy = useMutation({
    mutationKey: ['occupancy', 'list'],
    mutationFn: () => api.Occupancy.list(searchParams.toString())
  })

  const getServicesOccupancySum = useMutation({
    mutationKey: ['occupancy', 'sum'],
    mutationFn: () => api.Occupancy.getSum(searchParams.toString())
  })

  const getServicesOccupancyExport = useMutation({
    mutationKey: ['occupancy', 'export'],
    mutationFn: () => api.Occupancy.getExport(searchParams.toString())
  })

  const getStatsOccupancyExport = useMutation({
    mutationKey: ['occupancy', 'export'],
    mutationFn: () => {
      return api.Stats.statsOccupationExport(searchParams.toString())
    }
  })

  const getRights = useMutation({
    mutationKey: ['rights', 'list'],
    mutationFn: () => api.Rights.list(searchParams.toString())
  })

  const getRight = useMutation({
    mutationKey: ['rights', 'get'],
    mutationFn: (id: number) => api.Rights.get(id)
  })

  const editRight = useMutation({
    mutationKey: ['rights', 'edit'],
    mutationFn: (data: Right) => api.Rights.edit(data)
  })

  const getLitigations = useMutation({
    mutationKey: ['litigations', 'list'],
    mutationFn: () => api.Litigations.list(searchParams.toString())
  })
  const getLitigation = useMutation({
    mutationKey: ['litigation', 'get'],
    mutationFn: (id: number) => api.Litigations.getOne(id)
  })
  const getLitigationContracts = useMutation({
    mutationKey: ['litigation', 'contracts'],
    mutationFn: (id: number) => api.Litigations.getContracts(id)
  })

  const getContactByContract = useMutation({
    mutationKey: ['contact', 'list'],
    mutationFn: () => api.Contacts.getContacts(searchParams.toString())
  })

  const getContactExport = useMutation({
    mutationKey: ['contact', 'export'],
    mutationFn: () => api.Contacts.getContactExport(searchParams.toString())
  })

  const getLitigationInvoices = useMutation<any, unknown, number>({
    mutationKey: ['litigation', 'invoices'],
    mutationFn: (id) => api.Litigations.getInvoices(id)
  })

  const getOpportunities = useMutation<OpportunityList>({
    mutationKey: ['opportunities', 'list'],
    mutationFn: () => api.Opportunities.list(searchParams.toString())
  })

  const getOpportunity = useMutation({
    mutationKey: ['opportunities', 'get'],
    mutationFn: (id: number) => api.Opportunities.getOne(id)
  })

  const getOpportunityContracts = useMutation<any, void, number>({
    mutationKey: ['opportunities', 'contracts'],
    mutationFn: (id) => api.Opportunities.getContracts(id)
  })

  const getOpportunityEvents = useMutation({
    mutationKey: ['opportunities', 'events'],
    mutationFn: (id: number) => api.Opportunities.getEvents(id, searchParams.toString())
  })

  const getOpportunityUsers = useMutation<any, void, number>({
    mutationKey: ['opportunities', 'users'],
    mutationFn: (id) => api.Opportunities.getUsers(id)
  })

  const getOpportunitySolutions = useMutation<any, void, number>({
    mutationKey: ['opportunities', 'solutions'],
    mutationFn: (id) => api.Opportunities.getSolutions(id)
  })

  const getOpportunitiesExport = useMutation<void>({
    mutationKey: ['opportunities', 'export'],
    mutationFn: () => api.Opportunities.getExport(searchParams.toString())
  })

  const updateOpportunity = useMutation<
    void,
    void,
    { id: number; data: OpportunityUpdateFormProps }
  >({
    mutationKey: ['opportunities', 'id', 'update'],
    mutationFn: ({ id, data }) => api.Opportunities.update(id, data)
  })

  const getActualPipeline = useMutation({
    mutationKey: ['actual pipeline'],
    mutationFn: () => api.Opportunities.actualPipeline(searchParams.toString())
  })

  const getStaffFilters = useMutation({
    mutationKey: ['filters', 'staff'],
    mutationFn: (variables: FilterStaffInput) => api.Filters.getStaff(variables)
  })

  const getIndividual = useMutation({
    mutationKey: ['individuals', 'get'],
    mutationFn: (id: number) => api.Individuals.getOne(id)
  })

  const getIndividualPartners = useMutation({
    mutationKey: ['individuals', 'partners', 'get'],
    mutationFn: (id: number) => api.Individuals.getPartners(id)
  })

  const updateActivCorner = useMutation<void, unknown, number>({
    mutationKey: ['individuals', 'partners', 'patch'],
    mutationFn: (id) => api.Individuals.updateActivCorner(id)
  })

  const getIndividualAccess = useMutation({
    mutationKey: ['individuals', 'access', 'get'],
    mutationFn: (id: number) => api.Individuals.listAccess(id)
  })

  const createIndividualAccess = useMutation<
    void,
    unknown,
    { id: number; data: FormVisorAccessData }
  >({
    mutationKey: ['individuals', 'access', 'create'],
    mutationFn: ({ id, data }) => api.Individuals.createAccess(id, data)
  })

  const updateIndividualAccess = useMutation<
    void,
    unknown,
    { id: number; data: FormVisorAccessData }
  >({
    mutationKey: ['individuals', 'access', 'update'],
    mutationFn: ({ id, data }) => api.Individuals.updateAccess(id, data)
  })

  const getIndividualWifi = useMutation({
    mutationKey: ['individuals', 'wifi', 'get'],
    mutationFn: (id: number) => api.Individuals.getWifi(id)
  })

  const createWifiCredentials = useMutation<
    void,
    unknown,
    { id: number; data: WifiPasswordUpdate }
  >({
    mutationKey: ['individuals', 'wifi', 'create'],
    mutationFn: ({ id, data }) => api.Individuals.createWifiCredentials(id, data)
  })

  const updateWifiPassword = useMutation<void, unknown, { id: number; data: WifiPasswordUpdate }>({
    mutationKey: ['individuals', 'wifi', 'update'],
    mutationFn: ({ id, data }) => api.Individuals.updateWifiPassword(id, data)
  })

  const sendWifiPassword = useMutation<void, unknown, number>({
    mutationKey: ['individuals', 'wifi', 'send'],
    mutationFn: (id) => api.Individuals.sendWifiPassword(id)
  })

  const getIndividualEnterprises = useMutation({
    mutationKey: ['individuals', 'enterprises'],
    mutationFn: (id: number) => api.Individuals.listEnterprises(id)
  })

  const getIndividualConsumptions = useMutation({
    mutationKey: ['individuals', 'consumptions'],
    mutationFn: (id: number) => api.Individuals.listConsumptions(id)
  })

  const getIndividualDevices = useMutation({
    mutationKey: ['individuals', 'devices'],
    mutationFn: (id: number) => api.Individuals.listDevices(id)
  })

  const individualDevicesUpdate = useMutation<any, unknown, { id: number; data: any }>({
    mutationKey: ['individuals update', 'devices'],
    mutationFn: ({ id, data }) => api.Individuals.updateDevices(id, data)
  })

  const getIndividualEvents = useMutation({
    mutationKey: ['individuals', 'events'],
    mutationFn: (id: number) => api.Individuals.listEvents(id)
  })

  const getIndividualInvoices = useMutation({
    mutationKey: ['individuals', 'invoices'],
    mutationFn: (id: number) => api.Individuals.listInvoices(id)
  })

  const getIndividuals = useMutation({
    mutationKey: ['individuals', 'list'],
    mutationFn: () => api.Individuals.list(searchParams.toString())
  })

  const updateIndividual = useMutation<void, unknown, { id: number; data: IndividualDetails }>({
    mutationKey: ['individuals', 'update'],
    mutationFn: ({ id, data }) => api.Individuals.update(id, data)
  })

  const getIndividualsExport = useMutation<void>({
    mutationKey: ['individuals', 'export'],
    mutationFn: () => api.Individuals.getExport(searchParams.toString())
  })

  const getCheckouts = useMutation({
    mutationKey: ['checkouts', 'list'],
    mutationFn: () => api.Checkouts.list(searchParams.toString())
  })

  const getCheckoutSum = useMutation({
    mutationKey: ['checkouts', 'sum'],
    mutationFn: () => api.Checkouts.getSum(searchParams.toString())
  })

  const getContractsOptions = useMutation({
    mutationKey: ['contracts_options', 'list'],
    mutationFn: () => api.Contracts.listOptions(searchParams.toString())
  })

  const updateContract = useMutation<
    void,
    unknown,
    {
      id: number
      data: ContractUpdate
    }
  >({
    mutationKey: ['contract signatory', 'update'],
    mutationFn: ({ id, data }) => api.Contracts.updateSignatoryAndLanguage(id, data)
  })

  const getContractsOptionsSum = useMutation({
    mutationKey: ['contracts_options', 'sum'],
    mutationFn: () => api.Contracts.getOptionsSum(searchParams.toString())
  })

  const getMandates = useMutation({
    mutationKey: ['mandates', 'list'],
    mutationFn: () => api.Mandates.list(searchParams.toString())
  })

  const getPaymentMethod = useMutation({
    mutationKey: ['payment method', 'sheet'],
    mutationFn: (id: number) => api.Mandates.paymentMethod(id)
  })

  const getBankAccount = useMutation({
    mutationKey: ['bank account', 'sheet'],
    mutationFn: (id: number) => api.Mandates.bankAccount(id)
  })

  const getPaymentMethodPayments = useMutation({
    mutationKey: ['payments', 'sheet'],
    mutationFn: (id: number) => api.Mandates.payments(id)
  })

  const paymentMethodCancelled = useMutation<any, void, number>({
    mutationKey: ['cancelled', 'sheet'],
    mutationFn: (id) => api.Mandates.cancelled(id)
  })

  const getMainServices = useMutation({
    mutationKey: ['main services', 'list'],
    mutationFn: () => api.Services.mainList(searchParams.toString())
  })

  const getCenterDiscounts = useMutation({
    mutationKey: ['center discounts', 'list'],
    mutationFn: () => api.CenterDiscount.list(searchParams.toString())
  })

  const getCenterDiscountsNotActive = useMutation({
    mutationKey: ['center discounts not active', 'not active'],
    mutationFn: () => api.CenterDiscount.notActive()
  })

  const createCenterDiscount = useMutation({
    mutationKey: ['center discount', 'create'],
    mutationFn: (data: CreateCenterDataDiscount) => api.CenterDiscount.create(data)
  })

  const getMainService = useMutation({
    mutationKey: ['main services', 'get'],
    mutationFn: (id: number) => api.Services.mainGet(id)
  })

  const createMainService = useMutation({
    mutationKey: ['main services', 'create'],
    mutationFn: (data: MainServiceAddBody) => api.Services.createMain(data)
  })

  const createParkingService = useMutation({
    mutationKey: ['parking services', 'create'],
    mutationFn: (data: ParkingServiceAddBody) => api.Services.createParking(data)
  })

  const updateMainService = useMutation<void, unknown, { id: number; data: MainServiceBody }>({
    mutationKey: ['update main services', 'update'],
    mutationFn: ({ id, data }) => api.Services.updateMain(id, data)
  })

  const computePrice = useMutation<{ computedPrice: number }, unknown, MainServiceComputePriceBody>(
    {
      mutationKey: ['main services', 'compute price'],
      mutationFn: (data) => api.Services.computePrice(data)
    }
  )

  const getOptionService = useMutation({
    mutationKey: ['option services', 'get'],
    mutationFn: (id: number) => api.Services.optionGet(id)
  })

  const updateOptionService = useMutation<void, unknown, { id: number; data: OptionServiceBody }>({
    mutationKey: ['option services', 'update'],
    mutationFn: ({ id, data }) => api.Services.updateOption(id, data)
  })

  const createOptionService = useMutation<void, unknown, { data: OptionServiceBody }>({
    mutationKey: ['option services', 'create'],
    mutationFn: ({ data }) => api.Services.createOption(data)
  })

  const getParkingService = useMutation({
    mutationKey: ['service', 'parking', 'get'],
    mutationFn: (id: number) => api.Services.getParking(id)
  })

  const getConsumptionService = useMutation({
    mutationKey: ['service', 'consumption', 'get'],
    mutationFn: (id: number) => api.Services.getConsumption(id)
  })

  const updateConsumptionService = useMutation<void, unknown, any>({
    mutationKey: ['service', 'consumption', 'update'],
    mutationFn: ({ id, data }) => api.Services.updateConsumption(id, data)
  })

  const createConsumptionService = useMutation<void, unknown, ConsumptionsServiceBody>({
    mutationKey: ['service', 'consumption', 'create'],
    mutationFn: (data) => api.Services.createConsumption(data)
  })

  const updateParkingService = useMutation<void, unknown, { id: number; data: ParkingServiceBody }>(
    {
      mutationKey: ['update parking services', 'update'],
      mutationFn: ({ id, data }) => api.Services.updateParking(id, data)
    }
  )

  const getMainServiceSum = useMutation({
    mutationKey: ['main services', 'sum'],
    mutationFn: () => api.Services.getMainSum(searchParams.toString())
  })

  const getMainTemplateSpreadsheet = useMutation({
    mutationKey: ['template', 'spreadsheet'],
    mutationFn: () => api.Services.getMainTemplateSpreadsheet()
  })

  const getParkingServices = useMutation<any>({
    mutationKey: ['parking services', 'list'],
    mutationFn: () => api.Services.parkingList(searchParams.toString())
  })

  const getMessages = useMutation({
    mutationKey: ['messages', 'list'],
    mutationFn: () => api.Monitoring.messages(searchParams.toString())
  })

  const getAuthentificationRadius = useMutation({
    mutationKey: ['authentifications radius', 'list'],
    mutationFn: () => api.Monitoring.authentificationRadius(searchParams.toString())
  })

  const patchLitigation = useMutation<void, unknown, { id: number; reference: string }>({
    mutationKey: ['litigations', 'update'],
    mutationFn: ({ id, reference }) => api.Litigations.update(id, reference)
  })

  const closeLitigation = useMutation<void, unknown, number>({
    mutationKey: ['litigations', 'close'],
    mutationFn: (id) => api.Litigations.close(id)
  })

  const getPrescribers = useMutation({
    mutationKey: ['prescribers', 'list'],
    mutationFn: () => api.Prescribers.list(searchParams.toString())
  })

  const getPlanningsDesktops = useMutation({
    mutationKey: ['plannings', 'desktops'],
    mutationFn: () => api.Plannings.list(searchParams.toString())
  })

  const getPlanningsDesktopsFloors = useMutation({
    mutationKey: ['plannings', 'desktops-floors'],
    mutationFn: () => api.Plannings.getFloors(searchParams.toString())
  })

  const getPayment = useMutation({
    mutationKey: ['payment', 'get'],
    mutationFn: (id: number) => api.Payments.read(id)
  })

  const updatePayment = useMutation<void, unknown, { id: number; state: number }>({
    mutationKey: ['payment', 'update'],
    mutationFn: ({ id, state }) => api.Payments.update(id, state)
  })

  const deletePayment = useMutation<void, unknown, { id: number }>({
    mutationKey: ['payment', 'delete'],
    mutationFn: ({ id }) => api.Payments.delete(id)
  })

  const useGetPayment = (id: number) =>
    useQuery<Payment, unknown>({
      queryKey: ['query', 'payment', 'get', id],
      queryFn: () => api.Payments.read(id)
    })

  const getPayments = useMutation({
    mutationKey: ['payment', 'list'],
    mutationFn: () => api.Payments.list(searchParams.toString())
  })

  const getPaymentsSum = useMutation({
    mutationKey: ['payment', 'sum'],
    mutationFn: () => api.Payments.getSum(searchParams.toString())
  })

  const getAccountingDocuments = useMutation({
    mutationKey: ['accounting_documents', 'list'],
    mutationFn: () => api.Accounting.documentsList(searchParams.toString())
  })

  const getAccountingVariousOperations = useMutation({
    mutationKey: ['accounting_various_operations', 'list'],
    mutationFn: () => api.Accounting.getVariousOperationsList(searchParams.toString())
  })

  const getStaffs = useMutation({
    mutationKey: ['staffs', 'list'],
    mutationFn: () => api.Staffs.list(searchParams.toString())
  })

  const useGetDefaultStaffQuery = () =>
    useQuery({
      queryKey: ['query', 'attributions', 'staff', 'default'],
      queryFn: () => api.Staffs.getDefault()
    })

  const useUpdateDefaultStaffMutation = () =>
    useMutation({
      mutationKey: ['mutation', 'attributions', 'staff', 'default', 'update'],
      mutationFn: (staffId: number) => api.Staffs.updateDefault(staffId)
    })

  const useGetAttributableStaffs: QueryInfiniteHook<StaffsListAttribution> = (options) => {
    return useInfiniteQuery({
      ...infiniteQueryDefaultParams<StaffsListAttribution>(searchParams),
      queryKey: ['attribution', 'list', ...(options?.additionalQueryKeys ?? [])],
      queryFn: ({ pageParam, direction }) => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('offset', `${pageParam}`)
        if (direction === 'forward') setSearchParams(newSearchParams)
        return api.Attribution.list(newSearchParams.toString())
      },
      ...options
    })
  }

  const getStaff = useMutation({
    mutationKey: ['staffs', 'get'],
    mutationFn: (id: number) => api.Staffs.getOne(id)
  })

  const createStaff = useMutation({
    mutationKey: ['staffs', 'create'],
    mutationFn: (data: CreateUpdateStaffForm) => api.Staffs.create(data)
  })

  const updateStaff = useMutation<void, unknown, CreateUpdateStaffForm>({
    mutationKey: ['staffs', 'update'],
    mutationFn: (data) => api.Staffs.update(data)
  })

  const updateStaffLanguage = useMutation<void, unknown, { language: number; id: number }>({
    mutationKey: ['staff language', 'update'],
    mutationFn: ({ language, id }) => api.Staffs.updateLanguage(language, id)
  })

  const updateStaffIsAttributable = useMutation<
    void,
    unknown,
    { isAttributable: boolean; id: number }
  >({
    mutationKey: ['attributions', 'staff', 'update'],
    mutationFn: ({ isAttributable, id }) => api.Staffs.updateIsAttributable(isAttributable, id)
  })

  const createOrUpdateStaffPhoneSystems = useMutation<void, unknown, CreateUpdateStaffForm>({
    mutationKey: ['staffs', 'update', 'phone-systems'],
    mutationFn: (data) => api.Staffs.createOrUpdatePhoneSystem(data)
  })

  const getStatsAcquisitions = useMutation({
    mutationKey: ['stats', 'acquisitions'],
    mutationFn: () => api.Stats.acquisitions(searchParams.toString())
  })

  const useGetStatsProductionByCenters = () =>
    useQuery<CenterAcquisitions>({
      queryKey: ['stats', 'centerAcquisitions', searchParams.toString()],
      queryFn: () => api.Stats.centerAcquisitions(searchParams.toString())
    })

  const useGetStatsProductionByStaffs = () =>
    useQuery<StaffAcquisitions>({
      queryKey: ['stats', 'staffAcquisitions', searchParams.toString()],
      queryFn: () => api.Stats.staffAcquisitions(searchParams.toString())
    })

  const getStatsOpportunities = useMutation({
    mutationKey: ['stats', 'opportunities'],
    mutationFn: () => api.Stats.opportunities(searchParams.toString())
  })

  const getStatsAccounting = useMutation({
    mutationKey: ['stats', 'accounting'],
    mutationFn: () => api.Stats.accounting(searchParams.toString())
  })

  const getStatsPricePlaceParking = useMutation<PricePlaceParking>({
    mutationKey: ['stats', 'price parking'],
    mutationFn: () => api.Stats.pricePlaceParking(searchParams.toString())
  })

  const getMonthlyStatsCaVsBp = useMutation({
    mutationKey: ['stats', 'MonthlyStats'],
    mutationFn: () => api.Stats.monthlyCaVsBp(searchParams.toString())
  })

  const getStatsCaVsBp = useMutation({
    mutationKey: ['stats', 'CAvsBP'],
    mutationFn: () => api.Stats.caVsBp(searchParams.toString())
  })

  const getStatsOccupations = useMutation({
    mutationKey: ['stats occupations', 'occupations'],
    mutationFn: () => api.Stats.occupations(searchParams.toString())
  })

  const useGetStatsAllOccupations = () =>
    useQuery({
      queryKey: [
        'stats occupations',
        'allOccupations',
        searchParams.get('date_begin'),
        searchParams.get('date_end')
      ],
      queryFn: () => {
        const queryParams = new URLSearchParams(searchParams)
        queryParams.delete('centerId')
        return api.Stats.occupations(queryParams.toString())
      }
    })

  const getStatsInventories = useMutation({
    mutationKey: ['stats inventories', 'inventories'],
    mutationFn: () => api.Stats.inventories(searchParams.toString())
  })

  const getStatsSurface = useMutation({
    mutationKey: ['stats surface', 'surface'],
    mutationFn: () => api.Stats.surface(searchParams.toString())
  })

  const getStatsClientRequests = useMutation({
    mutationKey: ['stats client requests', 'requests'],
    mutationFn: () => api.Stats.clientRequests(searchParams.toString())
  })

  const getStatsPerformance = useMutation({
    mutationKey: ['stats', 'performance'],
    mutationFn: () => api.Stats.performance(searchParams.toString())
  })

  const useGetStatsCaByTypesByCenters = () =>
    useQuery<CaByTypesByCenters>({
      queryKey: ['stats', 'caByTypesByCenters', searchParams.toString()],
      queryFn: () => api.Stats.caByTypesByCenters(searchParams.toString())
    })

  const getSources = useMutation({
    mutationKey: ['sources', 'list'],
    mutationFn: () => api.Sources.list(searchParams.toString())
  })

  const createSource = useMutation({
    mutationKey: ['sources', 'create'],
    mutationFn: (data: CreateSourceFormData) => api.Sources.create(data)
  })

  const getInternalDocuments = useMutation({
    mutationKey: ['terms_of_sales', 'list'],
    mutationFn: (type: string | undefined) => api.TermsOfSales.list(type, searchParams.toString())
  })

  const getInternalDocument = useMutation({
    mutationKey: ['terms_of_sales', 'list'],
    mutationFn: ({ type, id }: { type: string; id: number }) => api.TermsOfSales.get(type, id)
  })

  const getInternalDocumentPdf = useMutation<any, unknown, { type: string; id: number }>({
    mutationKey: ['terms_of_sales', 'list'],
    mutationFn: ({ type, id }) => api.TermsOfSales.pdf(type, id)
  })

  const updateInternalDocument = useMutation<
    void,
    unknown,
    { type: string; id: number; data: UpdateTermOfSalesInput }
  >({
    mutationKey: ['terms_of_sales', 'update'],
    mutationFn: ({ type, id, data }) => api.TermsOfSales.update(type, id, data)
  })

  const uploadTermOfSales = useMutation<
    void,
    unknown,
    { type: string; data: UploadTermOfSalesInput }
  >({
    mutationKey: ['terms_of_sales', 'upload'],
    mutationFn: ({ type, data }) => api.TermsOfSales.upload(type, data)
  })

  const getAcquisitionsCost = useMutation({
    mutationKey: ['sources', 'acquisition-cost'],
    mutationFn: () => api.Sources.getAcquisitionCosts(searchParams.toString())
  })

  const uploadAcquisitionCostsCSV = useMutation<void, unknown, any>({
    mutationKey: ['sources', 'acquisition-cost', 'upload'],
    mutationFn: (file) => api.Sources.uploadCSV(file)
  })

  const updateAcquisitionCost = useMutation<
    void,
    unknown,
    { id: number; data: UpdateAcquisitionCostProps }
  >({
    mutationKey: ['sources', 'acquisition-cost', 'update'],
    mutationFn: ({ id, data }) => api.Sources.update(id, data)
  })

  const getQuotations = useMutation({
    mutationKey: ['quotations', 'list'],
    mutationFn: () => api.Quotations.list(searchParams.toString())
  })

  const getQuotation = useMutation({
    mutationKey: ['quotations', 'get'],
    mutationFn: (id: number) => api.Quotations.getOne(id)
  })

  const getQuotationsExport = useMutation<void>({
    mutationKey: ['quotations', 'export'],
    mutationFn: () => api.Quotations.getExport(searchParams.toString())
  })

  const getQuotationDownload = useMutation<void, unknown, number>({
    mutationKey: ['quotations', 'pdf'],
    mutationFn: (id) => api.Quotations.getPdf(id)
  })

  const getQuotationSolutions = useMutation({
    mutationKey: ['quotations', 'solutions'],
    mutationFn: (id: number) => api.Quotations.getSolutions(id)
  })

  const cancelQuotation = useMutation<void, void, number>({
    mutationKey: ['quotations', 'cancel'],
    mutationFn: (id) => api.Quotations.cancel(id)
  })

  const resetPassword = useMutation<void, unknown, IdStruct>({
    mutationKey: ['clients', 'activate'],
    mutationFn: ({ id }) => api.Individuals.resetPassword(id)
  })

  const sendQuotation = useMutation<void, void, number>({
    mutationKey: ['quotations', 'cancel'],
    mutationFn: (id) => api.Quotations.send(id)
  })

  const updateQuotation = useMutation<void, void, UpdateQuotationFormItems>({
    mutationKey: ['quotations', 'update'],
    mutationFn: (data) => api.Quotations.update(data)
  })

  const transformQuotationSolution = useMutation<void, void, { id: number; solutionId: number }>({
    mutationKey: ['quotations', 'transfers'],
    mutationFn: (data) => api.Quotations.transformSolution(data)
  })

  const updateQuotationSolution = useMutation<
    void,
    void,
    { id: number; solutionId: number; data: any }
  >({
    mutationKey: ['quotations', 'solution', 'update'],
    mutationFn: (data) => api.Quotations.updateSolution(data)
  })

  const getQuotationSolutionPrice = useMutation({
    mutationKey: ['quotations', 'solution', 'price'],
    mutationFn: (data: { id: number; solutionId: number; data: any }) =>
      api.Quotations.priceSolution(data)
  })
  const getQuotationSolutionCommitments = useMutation({
    mutationKey: ['quotations', 'solution', 'price'],
    mutationFn: ({ id, solutionId }: { id: number; solutionId: number }) =>
      api.Quotations.getSelectCommitments(id, solutionId)
  })

  const getContractsConsumptions = useMutation({
    mutationKey: ['contractsConsumptions', 'list'],
    mutationFn: () => api.Contracts.listConsumptions(searchParams.toString())
  })

  const getContractsConsumptionsSum = useMutation({
    mutationKey: ['contractsConsumptions', 'sum'],
    mutationFn: () => api.Contracts.getConsumptionsSum(searchParams.toString())
  })

  const getContractsNomadicSoldStats = useMutation({
    mutationKey: ['contractsNomadicSoldStats', 'get'],
    mutationFn: () => api.Stats.contractsNomadicSoldStats(searchParams.toString())
  })

  const getConsumptionContract = useMutation({
    mutationKey: ['consumption-contract', 'get'],
    mutationFn: (id: number) => api.ConsumptionContracts.get(id)
  })

  const getConsumptionContractConsumptions = useMutation({
    mutationKey: ['consumptions-contract-consumptions', 'get'],
    mutationFn: (id: number) => api.ConsumptionContracts.getConsumptions(id)
  })

  const getConsumptions = useMutation({
    mutationKey: ['consumptions', 'list'],
    mutationFn: () => api.Consumptions.list(searchParams.toString())
  })

  const getDuplicateEnterprises = useMutation({
    mutationKey: ['duplicate enterprises', 'list'],
    mutationFn: () => api.DuplicateEnterprises.list(searchParams.toString())
  })

  const getIndexing = useMutation({
    mutationKey: ['indexing', 'list'],
    mutationFn: () => api.Indexing.list(searchParams.toString())
  })

  const addEndorsementService = useMutation<void, unknown, { id: number; service: string }>({
    mutationKey: ['endorsements', 'add_service'],
    mutationFn: ({ id, service }) => api.Endorsements.addService(id, service)
  })

  const getEndorsements = useMutation({
    mutationKey: ['endorsements', 'list'],
    mutationFn: () => api.Endorsements.list(searchParams.toString())
  })

  const getClientCenter = useMutation({
    mutationKey: ['client center', 'get'],
    mutationFn: ({ id, centerId }: { id: number; centerId: number }) =>
      api.ClientCenters.get(id, centerId)
  })

  const updateClientCenter = useMutation<
    void,
    unknown,
    { id: number; clientCenterId: number; data: ClientCenterForm }
  >({
    mutationKey: ['client center', 'update'],
    mutationFn: ({ id, clientCenterId, data }) => api.ClientCenters.update(id, clientCenterId, data)
  })

  const updateClientCenterMail = useMutation<
    void,
    unknown,
    { id: number; clientCenterId: number; data: ClientCenterMailForm }
  >({
    mutationKey: ['client center mail', 'update'],
    mutationFn: ({ id, clientCenterId, data }) =>
      api.ClientCenters.updateMail(id, clientCenterId, data)
  })

  const getClientRequest = useMutation({
    mutationKey: ['client requests', 'get'],
    mutationFn: (id: number) => api.ClientRequests.getOne(id)
  })

  const getClientRequests = useMutation({
    mutationKey: ['client requests', 'list'],
    mutationFn: () => api.ClientRequests.list(searchParams.toString())
  })

  const getClientRequestSum = useMutation({
    mutationKey: ['client requests', 'sum'],
    mutationFn: () => api.ClientRequests.getSum(searchParams.toString())
  })

  const updateClientRequest = useMutation<void, unknown, ClientRequestFormProps>({
    mutationKey: ['client requests', 'update'],
    mutationFn: (data) => api.ClientRequests.update(data)
  })

  const getAccountingClients = useMutation({
    mutationKey: ['accountingClients', 'list'],
    mutationFn: () => api.Accounting.clientsList(searchParams.toString())
  })

  const getAccountingClientsExport = useMutation({
    mutationKey: ['accountingclients', 'export'],
    mutationFn: () => api.Accounting.getClientsExport(searchParams.toString())
  })

  const getAccountingClientsExportSage = useMutation({
    mutationKey: ['accountingclients', 'export-sage'],
    mutationFn: () => api.Accounting.getClientsExportSage(searchParams.toString())
  })

  const getPackagesList = useMutation({
    mutationKey: ['packages', 'list'],
    mutationFn: () => api.Packages.list(searchParams.toString())
  })

  const checkCodePackage = useMutation<
    PackagesVerifyCodeSchema,
    unknown,
    { packageId: number; securityCode: string; handoverClient: number }
  >({
    mutationKey: ['packages', 'verifyCode'],
    mutationFn: ({ packageId, securityCode, handoverClient }) =>
      api.Packages.verifyCode(packageId, securityCode, handoverClient)
  })

  const createCustomerReservation = useMutation({
    mutationKey: ['customer reservations', 'add'],
    mutationFn: (data: CreateCustomerReservation) => api.CustomerReservations.create(data)
  })
  const linkToAccessCustomerReservation = useMutation<void, unknown, LinkToAccess, void>({
    mutationKey: ['customer reservations', 'link'],
    mutationFn: (data) => api.CustomerReservations.linkToAccess(data)
  })
  const getCustomerReservations = useMutation({
    mutationKey: ['customer reservations', 'list'],
    mutationFn: () => api.CustomerReservations.list(searchParams.toString())
  })

  const getCustomerReservationSum = useMutation({
    mutationKey: ['customer reservations', 'sum'],
    mutationFn: () => api.CustomerReservations.getSum(searchParams.toString())
  })

  const getCustomerReservation = useMutation({
    mutationKey: ['customer reservations', 'information'],
    mutationFn: (id: number) => api.CustomerReservations.getOne(id)
  })

  const getCustomerReservationServices = useMutation({
    mutationKey: ['customer reservations', 'services'],
    mutationFn: (id: number) => api.CustomerReservations.getServices(id)
  })

  const getCustomerReservationContracts = useMutation({
    mutationKey: ['customer reservations', 'contracts'],
    mutationFn: (id: number) => api.CustomerReservations.getContracts(id)
  })

  const getCustomerReservationIndividuals = useMutation({
    mutationKey: ['customer reservations', 'contracts'],
    mutationFn: (id: number) => api.CustomerReservations.getIndividuals(id)
  })

  const cancelCustomerReservation = useMutation<void, unknown, number>({
    mutationKey: ['customer reservations', 'cancel'],
    mutationFn: (id) => api.CustomerReservations.cancel(id)
  })

  const confirmCustomerReservation = useMutation<void, unknown, number>({
    mutationKey: ['customer reservations', 'confirm'],
    mutationFn: (id) => api.CustomerReservations.confirm(id)
  })

  const updateCustomerReservation = useMutation<void, unknown, any>({
    mutationKey: ['customer reservations', 'update'],
    mutationFn: (data) => api.CustomerReservations.update(data)
  })

  const addCustomerReservationService = useMutation<void, unknown, AddReservationConsumptionItems>({
    mutationKey: ['customer_reservations', 'add'],
    mutationFn: (data) => api.CustomerReservations.addService(data)
  })

  const getSelectCommitments = useMutation({
    mutationKey: ['endorsements', 'select_commitments'],
    mutationFn: (id: number) => api.Endorsements.getSelectCommitments(id)
  })

  const sendEndorsementToDirection = useMutation<void, unknown, number>({
    mutationKey: ['endorsements', 'send_to_direction'],
    mutationFn: (id) => api.Endorsements.sendToDirection(id)
  })

  const validateEndorsement = useMutation<void, unknown, number>({
    mutationKey: ['endorsements', 'validate'],
    mutationFn: (id) => api.Endorsements.validate(id)
  })

  const getAccountingPayments = useMutation({
    mutationKey: ['accounting payments', 'list'],
    mutationFn: () => api.Accounting.paymentsList(searchParams.toString())
  })

  const getAccountingPaymentsExport = useMutation({
    mutationKey: ['accounting payments', 'export'],
    mutationFn: () => api.Accounting.getPaymentsExport(searchParams.toString())
  })

  const getAccountingVariousOperationsExport = useMutation({
    mutationKey: ['accounting various operations', 'export'],
    mutationFn: () => api.Accounting.getVariousOperationsExport(searchParams.toString())
  })

  const getMatchingTransfersExceptions = useMutation({
    mutationKey: ['matching transfers exceptions', 'list'],
    mutationFn: () => api.Accounting.getMatchingTransfersExceptions(searchParams.toString())
  })

  const getConsumptionsClient = useMutation({
    mutationKey: ['client consumptions', 'consumptions'],
    mutationFn: (id: number) => api.Consumptions.getClientContractsConsumptions(id)
  })

  const getEvents = useMutation({
    mutationKey: ['enterprises', 'events'],
    mutationFn: (id: number) => api.Enterprises.listEvents(id)
  })

  const getEventsList = useMutation({
    mutationKey: ['enterprises', 'events'],
    mutationFn: () => api.Events.list(searchParams.toString())
  })

  const getHappeningsList = useMutation({
    mutationKey: ['happenings', 'list'],
    mutationFn: () => api.Happenings.list(searchParams.toString())
  })

  const createHappening = useMutation({
    mutationKey: ['happenings', 'create'],
    mutationFn: (data: CreateHappeningForm) => api.Happenings.create(data)
  })

  const updateHappening = useMutation<void, unknown, { id: number; data: UpdateHappening }>({
    mutationKey: ['happenings', 'update'],
    mutationFn: ({ id, data }) => api.Happenings.update(id, data)
  })

  const getHappeningsDetails = useMutation({
    mutationKey: ['happening', 'read'],
    mutationFn: (id: number) => api.Happenings.getRead(id)
  })

  const getHappeningsPartners = useMutation({
    mutationKey: ['happenings', 'partnersList'],
    mutationFn: () => api.Happenings.getPartners(searchParams.toString())
  })

  const createPartners = useMutation({
    mutationKey: ['happenings', 'create'],
    mutationFn: (data: CreateHappeningPartnerForm) => api.Happenings.createPartners(data)
  })

  const getEnterpriseDocuments = useMutation({
    mutationKey: ['enterprises', 'documents'],
    mutationFn: (data: EnterpriseDocumentsListParams) => api.Enterprises.listDocuments(data)
  })

  const getEnterpriseAccounting = useMutation<
    AccoutingStatement,
    unknown,
    { id: number; centerId: number }
  >({
    mutationKey: ['enterprises', 'accounting'],
    mutationFn: ({ id, centerId }) => api.Enterprises.getAccountingStatement(id, centerId)
  })

  const getEnterpriseMembers = useMutation({
    mutationKey: ['enterprises', 'members'],
    mutationFn: (id: number) => api.Enterprises.listMembers(id)
  })

  const linkEnterpriseMember = useMutation<
    void,
    unknown,
    {
      id: number
      data: LinkIndividualProps
    }
  >({
    mutationKey: ['enterprises', 'link-members'],
    mutationFn: ({ id, data }) => api.Enterprises.linkMember(id, data)
  })

  const createEnterpriseMember = useMutation<void, unknown, IndividualFormDetails>({
    mutationKey: ['enterprises', 'link-members'],
    mutationFn: (data) => api.Enterprises.createMember(data.enterpriseId!, data)
  })

  const getEnterpriseOpportunities = useMutation({
    mutationKey: ['enterprises', 'opportunities'],
    mutationFn: (id: number) => api.Enterprises.listOpportunities(id)
  })

  const getEnterpriseContracts = useMutation({
    mutationKey: ['enterprises', 'contracts'],
    mutationFn: (id: number) => api.Enterprises.listContracts(id)
  })

  const getEnterpriseSsids = useMutation<any, unknown, number>({
    mutationKey: ['enterprises', 'ssids'],
    mutationFn: (id) => api.Enterprises.listSsids(id)
  })

  const getEnterpriseSsidsUsers = useMutation<any, unknown, { id: number; ssid: string }>({
    mutationKey: ['enterprises', 'ssids'],
    mutationFn: ({ id, ssid }) => api.Enterprises.listSsidsUsers(id, ssid)
  })

  const updateEnterpriseSsidUser = useMutation<
    void,
    unknown,
    { id: number; ssid: string; ssidUser: string; data: any }
  >({
    mutationKey: ['enterprises', 'ssid', 'user', 'update'],
    mutationFn: ({ id, ssid, ssidUser, data }) =>
      api.Enterprises.updateSsidUser(id, ssid, ssidUser, data)
  })

  const createEnterpriseSsid = useMutation<void, unknown, { id: number; data: any }>({
    mutationKey: ['enterprises', 'ssid', 'create'],
    mutationFn: ({ id, data }) => api.Enterprises.createSsid(id, data)
  })

  const createEnterpriseSsidUser = useMutation<
    void,
    unknown,
    { id: number; ssid: string; data: any }
  >({
    mutationKey: ['enterprises', 'ssid', 'user', 'create'],
    mutationFn: ({ id, ssid, data }) => api.Enterprises.createSsidUser(id, ssid, data)
  })

  const createEnterpriseOpportunity = useMutation({
    mutationKey: ['opportunity', 'create'],
    mutationFn: ({ id, data }: { id: number; data: OpportunityCreateFormData }) =>
      api.Enterprises.createOpportunity(id, data)
  })

  const updateClientLink = useMutation<void, unknown, UpdateClientLinkProps>({
    mutationKey: ['clientlink', 'update'],
    mutationFn: (params) => api.Individuals.updateClientLink(params)
  })

  const uploadDocument = useMutation<void, unknown, { id: number; data: any }>({
    mutationKey: ['document', 'upload'],
    mutationFn: (params) => api.Enterprises.uploadDocument(params)
  })

  const getClientConsumptions = useMutation({
    mutationKey: ['client', 'consumptions'],
    mutationFn: (params: ConsumptionsListParams) => api.Consumptions.listForClient(params)
  })

  const getClientContracts = useMutation({
    mutationKey: ['contract', 'listClient'],
    mutationFn: (data: ClientContractParams) => api.Contracts.listForClient(data)
  })

  const generatePartialContractInvoice = useMutation<
    void,
    unknown,
    { id: number; contractServices: number[] }
  >({
    mutationKey: ['invoices', 'partial_contract_invoice'],
    mutationFn: ({ id, contractServices }) =>
      api.Invoices.generatePartialContractInvoice(id, contractServices)
  })

  const createContract = useMutation({
    mutationKey: ['contracts', 'create'],
    mutationFn: (params: FormProps) => api.Contracts.create(params)
  })

  const createContractServices = useMutation({
    mutationKey: ['contracts', 'create_service'],
    mutationFn: ({ id, params }: { id: number; params: any }) =>
      api.Contracts.createService(id, params)
  })

  const deleteContractService = useMutation<
    void,
    unknown,
    { contractId: number; serviceId: number }
  >({
    mutationKey: ['contracts', 'delete_service'],
    mutationFn: ({ contractId, serviceId }) => api.Contracts.deleteService(contractId, serviceId)
  })

  const createEvent = useMutation({
    mutationKey: ['events', 'create'],
    mutationFn: (data: EventFormData) => api.Events.create(data)
  })

  const editEvent = useMutation({
    mutationKey: ['events', 'edit'],
    mutationFn: ({ id, data }: { id: number; data: EventFormData }) => api.Events.edit(id, data)
  })

  const getServicesPricer = useMutation({
    mutationKey: ['pricer', 'services'],
    mutationFn: ({ id }: IdStruct) => api.Pricer.services(id)
  })

  const getInformationsPricer = useMutation({
    mutationKey: ['pricer', 'informations'],
    mutationFn: ({ id }: IdStruct) => api.Pricer.informations(id)
  })

  const getDiscountGridsPricer = useMutation({
    mutationKey: ['pricer', 'discount-grids'],
    mutationFn: ({ center, begin }: { center: number; begin: string }) =>
      api.Pricer.discountGrids(center, begin)
  })

  const getDiscountGrids = useMutation({
    mutationKey: ['discount grid', 'list'],
    mutationFn: () => api.DiscountGrid.list(searchParams.toString())
  })

  const getDiscountGrid = useMutation({
    mutationKey: ['discount grid', 'read'],
    mutationFn: ({ id }: IdStruct) => api.DiscountGrid.read(id)
  })

  const createDiscountGrid = useMutation<void, unknown, DiscountGridAdd>({
    mutationKey: ['discount grid', 'add'],
    mutationFn: (data) => api.DiscountGrid.create(data)
  })

  const getDiscountGridsCenter = useMutation({
    mutationKey: ['discount grid center', 'list'],
    mutationFn: () => api.DiscountGridCenter.list(searchParams.toString())
  })

  const getDiscountGridsCenterAdd = useMutation({
    mutationKey: ['discount grid center', 'add'],
    mutationFn: (body: DiscountGridsCenterBody) => api.DiscountGridCenter.add(body)
  })

  const createQuotation = useMutation({
    mutationKey: ['quotation', 'create'],
    mutationFn: ({
      opportunityId,
      data
    }: {
      opportunityId: number
      data: QuotationCreateFormData
    }) => api.Opportunities.createQuotation(opportunityId, data)
  })

  const getListFloors = useMutation({
    mutationKey: ['list floors', 'floors'],
    mutationFn: () => api.FloorPlans.listFloors(String(searchParams.get('center')))
  })

  const getDocument = useMutation({
    mutationKey: ['document', 'get'],
    mutationFn: ({ id }: IdStruct) => api.Documents.getOne(id)
  })

  const invalidateDocument = useMutation({
    mutationKey: ['document', 'invalidate'],
    mutationFn: ({ id }: IdStruct) => api.Documents.invalidate(id)
  })

  const validateDocument = useMutation({
    mutationKey: ['document', 'validate'],
    mutationFn: ({ id }: IdStruct) => api.Documents.validate(id)
  })

  const getFloorPlans = useMutation({
    mutationKey: ['floor plans', 'floorPlans'],
    mutationFn: (data: FloorPlansParams) => api.FloorPlans.informations(data)
  })

  const getFilterContractsFloorPlans = useMutation({
    mutationKey: ['filter contracts floor', 'contracts floor'],
    mutationFn: (data: FilterContractsParams) => api.FloorPlans.getFilterContracts(data)
  })

  const createActivity = useMutation({
    mutationKey: ['activities', 'create'],
    mutationFn: (params: any) => api.Activities.create(params)
  })

  const getWifiClientsLogs = useMutation({
    mutationKey: ['wifi', 'clients'],
    mutationFn: () => api.Wifi.getClientsLogs(searchParams.toString())
  })

  const getEmailTemplates = useMutation({
    mutationKey: ['emails', 'list'],
    mutationFn: () => api.EmailTemplate.list(searchParams.toString())
  })

  const getOneEmailTemplate = useMutation({
    mutationKey: ['emails', 'sheet'],
    mutationFn: (id: number) => api.EmailTemplate.getOne(id)
  })

  const updateEmailTemplate = useMutation<void, unknown, EmailTemplateForm>({
    mutationKey: ['emails', 'update'],
    mutationFn: (data) => api.EmailTemplate.edit(data)
  })

  const encrypt = useMutation({
    mutationKey: ['crypt', 'encrypt'],
    mutationFn: (data: { text: string }) => api.Crypt.encrypt(data)
  })

  const decrypt = useMutation({
    mutationKey: ['crypt', 'decrypt'],
    mutationFn: (data: { text: string }) => api.Crypt.decrypt(data)
  })

  const getNotifications = useMutation({
    mutationKey: ['notifications', 'list'],
    mutationFn: () => api.Notifications.list(searchParams.toString())
  })

  const updateNotification = useMutation<void, unknown, { id: number; data: NotificationUpdate }>({
    mutationKey: ['notifications', 'update'],
    mutationFn: ({ id, data }) => api.Notifications.update(id, data)
  })

  const getAttributionLogs = useMutation({
    mutationKey: ['attribution logs', 'list'],
    mutationFn: () => api.Attributions.logsList(searchParams.toString())
  })

  const getServicesTypes = useMutation({
    mutationKey: ['services', 'types'],
    mutationFn: () => api.Services.getServicesTypes()
  })
  const useGetServiceTypeAccountingById = (id: number | null | undefined) =>
    useQuery({
      queryKey: ['services-types', 'accounting', id],
      queryFn: () => api.Services.getServicesTypeAccountingById(id!),
      enabled: id !== null && id !== undefined
    })

  const useCheckManyServicesExcel = () =>
    useMutation({
      mutationKey: ['check', 'add', 'main', 'services', 'excel'],
      mutationFn: (formData: FormData) => api.Services.checkAddManyExcel(formData)
    })

  const useAddManyMainServices = () =>
    useMutation({
      mutationKey: ['add', 'many', 'main', 'services'],
      mutationFn: (formData: FormData) => api.Services.createManyMain(formData)
    })

  const getAssets = useMutation({
    mutationKey: ['assets', 'list'],
    mutationFn: ({ type, id }: { type: string; id: number }) => api.Assets.list(type, id)
  })

  const useGetAssets = ({ type, id, enabled }: { type: string; id: number; enabled?: boolean }) =>
    useQuery({
      queryKey: ['query', 'assets', 'list', type, id === 0 ? '' : id],
      queryFn: () => api.Assets.list(type, id),
      enabled: enabled
    })

  const useDeleteAsset = () =>
    useMutation<void, unknown, { type: string; id: number; imageId: string }>({
      mutationKey: ['assets', 'delete'],
      mutationFn: ({ type, id, imageId }) => api.Assets.delete(type, id, imageId)
    })

  const useAddAsset = () =>
    useMutation<void, unknown, { id: number; type: string; formData: FormData }>({
      mutationKey: ['assets', 'add'],
      mutationFn: ({ id, type, formData }) => api.Assets.add(id, type, formData)
    })

  const getPresenceLogs = useMutation({
    mutationKey: ['presence', 'logs'],
    mutationFn: () => api.Presences.logsList(searchParams.toString())
  })

  const useGetPresenceSchedules: QueryInfiniteHook<PresenceScheduleList> = (options) =>
    useInfiniteQuery({
      ...infiniteQueryDefaultParams<PresenceScheduleList>(searchParams),
      queryKey: ['query', 'presences', 'schedule', 'list', ...(options?.additionalQueryKeys ?? [])],
      queryFn: ({ pageParam }) => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('offset', `${pageParam}`)
        setSearchParams(newSearchParams)
        return api.Presences.schedulesList(searchParams.toString())
      },
      ...options
    })

  const getPresenceConsumption = useMutation({
    mutationKey: ['presence consumption', 'list'],
    mutationFn: () => api.PresencesConsumption.list(searchParams.toString())
  })

  const getPresenceConsumptionSum = useMutation({
    mutationKey: ['presence consumption', 'sum'],
    mutationFn: () => api.PresencesConsumption.getSum(searchParams.toString())
  })

  const createPackage = useMutation({
    mutationKey: ['packages', 'create'],
    mutationFn: (data: CreatePackageFormSchema) => api.Packages.create(data)
  })

  const packageClientRelated = useMutation({
    mutationKey: ['packages', 'clientRelated'],
    mutationFn: (clientId: number) => api.Packages.getRecipientRelated(clientId)
  })

  const createAppointment = useMutation<void, unknown, AppointmentForm>({
    mutationKey: ['appointments', 'create'],
    mutationFn: (data) => api.Appointments.create(data)
  })

  const getAppointments = useMutation<AppointmentList>({
    mutationKey: ['appointments', 'get'],
    mutationFn: () => api.Appointments.list(searchParams.toString())
  })

  const createGuest = useMutation<IdReturn, unknown, GuestForm>({
    mutationKey: ['guest', 'create'],
    mutationFn: (data) => api.Individuals.createGuest(data)
  })

  const cancelAppointment = useMutation<
    void,
    unknown,
    { id: number; cancellationReason: string | null }
  >({
    mutationKey: ['appointments', 'cancel'],
    mutationFn: ({ id, cancellationReason }) => api.Appointments.cancel(id, cancellationReason)
  })

  const getLastSentReminder = useMutation<{ date: string }, unknown, { id: number }>({
    mutationKey: ['appointments', 'last-reminder'],
    mutationFn: ({ id }) => api.Appointments.getLastSentReminder(id)
  })

  const sendReminderAppointment = useMutation<void, unknown, { id: number }>({
    mutationKey: ['appointments', 'reminder'],
    mutationFn: ({ id }) => api.Appointments.sendReminder(id)
  })

  const confirmPresence = useMutation<
    void,
    unknown,
    { id: number; guests: { id: number; presence: boolean }[] }
  >({
    mutationKey: ['appointments', 'confirm-presence'],
    mutationFn: ({ id, guests }) => api.Appointments.confirmPresence(id, guests)
  })

  const getClientRelationList = useMutation<any, unknown, { client: number }>({
    mutationKey: ['clientRelation', 'list'],
    mutationFn: ({ client }) => api.ClientRelation.list(client)
  })

  const getClientRelationFilter = useMutation<
    any,
    unknown,
    { client: number; params: ClientRelationFilter }
  >({
    mutationKey: ['clientRelation', 'list'],
    mutationFn: ({ client, params }) => api.ClientRelation.filter(client, params)
  })

  const createClientRelation = useMutation<
    any,
    unknown,
    { client: number; data: CreateClientRelation }
  >({
    mutationKey: ['clientRelation', 'create'],
    mutationFn: ({ client, data }) => api.ClientRelation.create(client, data)
  })

  const removeClientRelation = useMutation<
    any,
    unknown,
    { client: number; data: CreateClientRelation }
  >({
    mutationKey: ['clientRelation', 'remove'],
    mutationFn: ({ client, data }) => api.ClientRelation.remove(client, data)
  })

  return {
    updateStaffIsAttributable,
    updateStaffLanguage,
    getAuthentificationRadius,
    getCenterDiscounts,
    getCenterDiscountsNotActive,
    createCenterDiscount,
    getMonthlyStatsCaVsBp,
    getStatsCaVsBp,
    getCommitments,
    useGetStatsCaByTypesByCenters,
    updateOptionService,
    createOptionService,
    getTertiaries,
    getTypologies,
    createTertiary,
    getCenters,
    createCenters,
    getDiscountGrid,
    createDiscountGrid,
    getDiscountGridsCenterAdd,
    getDiscountGridsCenter,
    getDiscountGrids,
    useGetDefaultStaffQuery,
    useUpdateDefaultStaffMutation,
    getStaffs,
    useGetAttributableStaffs,
    getStaff,
    createStaff,
    updateStaff,
    createOrUpdateStaffPhoneSystems,
    createMainService,
    updateMainService,
    computePrice,
    getStatsAcquisitions,
    useGetStatsProductionByCenters,
    useGetStatsProductionByStaffs,
    getStatsOpportunities,
    getStatsAccounting,
    getStatsOccupations,
    useGetStatsAllOccupations,
    getStatsInventories,
    getAcquisitionsCost,
    getSources,
    createSource,
    uploadAcquisitionCostsCSV,
    updateAcquisitionCost,
    getStatsSurface,
    getStatsClientRequests,
    getStatsPerformance,
    getInternalDocuments,
    getInternalDocument,
    getInternalDocumentPdf,
    updateInternalDocument,
    uploadTermOfSales,
    getDocument,
    invalidateDocument,
    validateDocument,
    activateAccount,
    getActivities,
    getCompanyBankAccounts,
    createCompanyBankAccount,
    updateCompanyBankAccount,
    getCompanyBankAccount,
    availableCentersCompany,
    postCompany,
    getEnterprises,
    getEvents,
    getEventsList,
    getHappeningsList,
    getHappeningsPartners,
    createPartners,
    getHappeningsDetails,
    createHappening,
    updateHappening,
    createEnterprise,
    createEnterprisePrescriber,
    agreeContract,
    cancelContract,
    downloadContract,
    createContractEndorsement,
    updateContractEndorsement,
    getContracts,
    getContractEndorsements,
    getContractAutoRenewalEndorsements,
    getContractSelectOptions,
    stopContractService,
    getContractSum,
    getContractExport,
    getContract,
    getContractMain,
    getContractOption,
    getContractConsumption,
    getContractInvoice,
    getContractIndexingAnniversary,
    linkContractServiceUser,
    updateContractAdmin,
    getContractLitigations,
    getContractPayments,
    getContractUsers,
    updateContractOccupant,
    validateContract,
    postLitigationContract,
    getWifiLogs,
    getInvoices,
    getInvoicesExport,
    getInvoiceSum,
    getInvoice,
    getInvoicePdf,
    getInvoiceActivities,
    getCreditActivities,
    getFilters,
    getFormItems,
    useGetFormItems,
    getFormItemsWithFilters,
    getClientFilters,
    getCenter,
    useGetCenter,
    getCenterAccessGroups,
    getCenterAccessRooms,
    getCenterBlockedPrices,
    getCenterPrices,
    uploadBusinessPlanCSV,
    getBusinessPlan,
    updateCenter,
    createCenterPrice,
    getCentersClusters,
    createCenterCluster,
    updateCenterCluster,
    availableCentersCluster,
    getCenterVisors,
    getCenterFilters,
    getCentersSelect,
    getContractsOptions,
    getContractsOptionsSum,
    updateContract,
    getOptions,
    searchParams,
    setSearchParams,
    getCredits,
    getCreditSum,
    getCreditExport,
    getCredit,
    getCreditPdf,
    getCreditInvoiceSelect,
    updateCreditRegeneratePdf,
    updateCreditApplyInvoice,
    addCreditDeclarePayment,
    getCreditInvoice,
    getCreditPayment,
    getServiceCommitmentPrice,
    getServiceAvailability,
    getServiceAvailableDesktops,
    changeContractService,
    getServicePricing,
    getServicesAvailable,
    getServiceConsumptionsPricing,
    getServicesOccupancy,
    getServicesOccupancySum,
    getServicesOccupancyExport,
    getStatsOccupancyExport,
    getRights,
    getRight,
    editRight,
    getLitigations,
    getLitigation,
    getLitigationContracts,
    getContactByContract,
    getContactExport,
    getLitigationInvoices,
    getOpportunities,
    getOpportunity,
    getOpportunityContracts,
    getOpportunityEvents,
    getOpportunityUsers,
    getOpportunitySolutions,
    getOpportunitiesExport,
    updateOpportunity,
    getActualPipeline,
    getStaffFilters,
    getIndividual,
    getIndividualAccess,
    getIndividualEnterprises,
    getIndividualConsumptions,
    getIndividualDevices,
    getIndividualEvents,
    getIndividualInvoices,
    getIndividuals,
    getIndividualPartners,
    updateActivCorner,
    getIndividualWifi,
    createIndividualAccess,
    createWifiCredentials,
    updateIndividualAccess,
    updateWifiPassword,
    sendWifiPassword,
    updateIndividual,
    getStatsPricePlaceParking,
    getPayment,
    updatePayment,
    deletePayment,
    useGetPayment,
    getPayments,
    getPaymentsSum,
    getCheckouts,
    getCheckoutSum,
    getMandates,
    getPaymentMethod,
    getBankAccount,
    getPaymentMethodPayments,
    paymentMethodCancelled,
    getMainServices,
    getMainService,
    getMainServiceSum,
    getIndividualsExport,
    getMainTemplateSpreadsheet,
    getOptionService,
    getParkingServices,
    getParkingService,
    getConsumptionService,
    updateConsumptionService,
    createConsumptionService,
    createParkingService,
    updateParkingService,
    getPrescribers,
    getPlanningsDesktops,
    getPlanningsDesktopsFloors,
    getAccountingDocuments,
    getAccountingVariousOperations,
    getQuotations,
    getQuotation,
    getQuotationDownload,
    getQuotationSolutions,
    cancelQuotation,
    sendQuotation,
    updateQuotation,
    transformQuotationSolution,
    updateQuotationSolution,
    getQuotationSolutionPrice,
    getQuotationSolutionCommitments,
    getContractsConsumptions,
    getContractsConsumptionsSum,
    getContractsNomadicSoldStats,
    getConsumptionContract,
    getConsumptionContractConsumptions,
    getConsumptions,
    getDuplicateEnterprises,
    getIndexing,
    addEndorsementService,
    getEndorsements,
    getSelectCommitments,
    sendEndorsementToDirection,
    validateEndorsement,
    getClientCenter,
    updateClientCenter,
    updateClientCenterMail,
    getClientRequest,
    getClientRequests,
    getClientRequestSum,
    updateClientRequest,
    getAccountingClients,
    getAccountingClientsExport,
    getAccountingClientsExportSage,
    getPackagesList,
    checkCodePackage,
    createCustomerReservation,
    linkToAccessCustomerReservation,
    getCustomerReservations,
    getCustomerReservationSum,
    getCustomerReservation,
    getCustomerReservationServices,
    getCustomerReservationContracts,
    getCustomerReservationIndividuals,
    cancelCustomerReservation,
    confirmCustomerReservation,
    updateCustomerReservation,
    addCustomerReservationService,
    getAccountingPayments,
    getAccountingPaymentsExport,
    getAccountingVariousOperationsExport,
    getMatchingTransfersExceptions,
    getEnterpriseAccounting,
    getEnterpriseDocuments,
    getEnterpriseMembers,
    getEnterpriseOpportunities,
    getEnterpriseContracts,
    getEnterpriseSsids,
    getEnterpriseSsidsUsers,
    linkEnterpriseMember,
    createEnterpriseMember,
    updateEnterpriseSsidUser,
    createEnterpriseSsid,
    createEnterpriseSsidUser,
    createEnterpriseOpportunity,
    updateClientLink,
    uploadDocument,
    getClientConsumptions,
    getClientContracts,
    generatePartialContractInvoice,
    createContract,
    createContractServices,
    deleteContractService,
    createEvent,
    editEvent,
    getEnterpriseMembersFilters,
    getEnterprisePrescriber,
    createPrescriberMemberSource,
    getPrescriberMembers,
    getPrescriberOpportunities,
    getWifiSsidsFilters,
    getWifiStationsFilters,
    patchRegeneratePdfInvoice,
    patchAccountInvoice,
    patchUnAccountInvoice,
    patchBlockInvoice,
    patchUnBlockInvoice,
    patchLitigationInvoice,
    postUnCollectibleInvoice,
    postManualReminderInvoice,
    getServicesPricer,
    getInformationsPricer,
    getDiscountGridsPricer,
    createQuotation,
    getListFloors,
    getFloorPlans,
    getFilterContractsFloorPlans,
    getRowsInvoice,
    patchLanguageInvoice,
    patchDiscountInvoice,
    postManualBankDebitInvoice,
    postAddPaymentInvoice,
    patchLinkPaymentInvoice,
    resetPassword,
    getPaymentsInvoice,
    patchUnLinkPaymentInvoice,
    patchCancelledPaymentInvoice,
    getCreditsInvoice,
    patchUnLinkCreditInvoice,
    patchDueDateInvoice,
    createActivity,
    patchLanguageCredit,
    getConsumptionsClient,
    getMessages,
    individualDevicesUpdate,
    patchLitigation,
    closeLitigation,
    getWifiClientsLogs,
    getEmailTemplates,
    getOneEmailTemplate,
    updateEmailTemplate,
    encrypt,
    decrypt,
    getNotifications,
    updateNotification,
    getAttributionLogs,
    getPresenceLogs,
    useGetPresenceSchedules,
    getPresenceConsumption,
    getPresenceConsumptionSum,
    getServicesTypes,
    useGetServiceTypeAccountingById,
    useCheckManyServicesExcel,
    useAddManyMainServices,
    getAssets,
    useGetAssets,
    useDeleteAsset,
    useAddAsset,
    getQuotationsExport,
    getEnterprisesExport,
    createPackage,
    createAppointment,
    getAppointments,
    createGuest,
    cancelAppointment,
    getLastSentReminder,
    sendReminderAppointment,
    confirmPresence,
    getClientRelationList,
    getClientRelationFilter,
    createClientRelation,
    removeClientRelation,
    packageClientRelated
  }
}

type FetcherContextProps = ReturnType<typeof useQueries>
const FetcherContext = createContext({} as FetcherContextProps)

export const FetcherProvider = ({ children }: IFetcherProviderProps): React.JSX.Element => {
  const values = useQueries()
  return <FetcherContext.Provider value={values}>{children}</FetcherContext.Provider>
}

export const useFetcher = () => useContext(FetcherContext)
