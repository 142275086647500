import { Ctx, fetcher, formatParams, uploader } from 'api'
import { API, BASE_URL } from 'api/constants'
import {
  FloorPlanDuplicateInput,
  FloorPlansList,
  FloorPlan,
  FloorPlanEditItem,
  ServiceShapeList,
  FloorPlansCreate
} from 'api/models/floor_plans_management'
import urlHelper from 'app/helpers/url.helper'

export const floorPlansManagementRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<FloorPlansList>({
      url: `${BASE_URL}${API.FLOOR_PLANS_MANAGEMENT}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  create: async (data: FloorPlansCreate) =>
    fetcher<void>({
      url: `${BASE_URL}${API.FLOOR_PLANS_MANAGEMENT_CREATE}`,
      method: 'POST',
      token: token,
      body: data
    }),
  read: async (id: number) =>
    fetcher<FloorPlan>({
      url: `${BASE_URL}${urlHelper(API.FLOOR_PLANS_MANAGEMENT_READ, { id })}`,
      method: 'GET',
      token: token
    }),
  serviceShapesList: async (id: string, params: string) =>
    fetcher<ServiceShapeList>({
      url: `${BASE_URL}${urlHelper(API.FLOOR_PLANS_MANAGEMENT_SERVICE_SHAPES, {
        id
      })}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  edit: async (id: number, data: FloorPlanEditItem) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.FLOOR_PLANS_MANAGEMENT_READ, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  duplicate: async (id: string, data: FloorPlanDuplicateInput) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.FLOOR_PLANS_MANAGEMENT_DUPLICATE, { id })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  uploadImage: async (id: number, type: string, formData: FormData) => {
    return uploader<void>({
      url: `${BASE_URL}${urlHelper(API.FLOOR_PLANS_MANAGEMENT_UPLOAD_IMAGE, { id, type })}`,
      method: 'POST',
      token: token,
      body: formData
    })
  },
  deleteImage: async (id: number, type: string) => {
    return fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.FLOOR_PLANS_MANAGEMENT_DELETE_IMAGE, { id, type })}`,
      method: 'DELETE',
      token: token
    })
  }
})
