import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { AppointmentList } from 'api/models'
import { AppointmentForm } from 'api/models/forms/appointments'
import urlHelper from 'app/helpers/url.helper'

export const appointmentsRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<AppointmentList>({
      url: `${BASE_URL}${API.APPOINTMENTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  create: async (data: AppointmentForm) =>
    fetcher<void>({
      url: `${BASE_URL}${API.APPOINTMENTS}`,
      method: 'POST',
      token: token,
      body: data
    }),
  cancel: async (id: number, cancellationReason: string | null) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.APPOINTMENTS_CANCEL, { id })}`,
      method: 'PATCH',
      token: token,
      body: cancellationReason ? { cancellationReason } : {}
    }),
  getLastSentReminder: async (id: number) =>
    fetcher<{ date: string }>({
      url: `${BASE_URL}${urlHelper(API.APPOINTMENTS_LAST_SENT_INVITATION, { id })}`,
      method: 'GET',
      token: token
    }),
  sendReminder: async (id: number) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.APPOINTMENTS_SEND_REMINDER, { id })}`,
      method: 'POST',
      token: token
    }),
  confirmPresence: async (id: number, guests: { id: number; presence: boolean }[]) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.APPOINTMENT_CONFIRM_PRESENCE, { id })}`,
      method: 'PATCH',
      token: token,
      body: { guests }
    })
})
