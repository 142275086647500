import { type Ctx, fetcher } from 'api'
import { BASE_URL, API } from 'api/constants'
import type {
  FiltersInput,
  Filters,
  FilterClientInput,
  FilterClient,
  FilterCenterInput,
  FilterCenter,
  FilterStaffInput,
  FilterStaff,
  FilterMemberInput,
  SelectCenters,
  FilterSelectSearch
} from 'api/models'
import { FilterSelectInput } from 'api/models'

export const filtersRouter = ({ token }: Ctx) => ({
  list: async (filters: FiltersInput, params: any) =>
    fetcher<Filters>({
      url: `${BASE_URL}${API.FILTERS}?references=${JSON.stringify(filters)}&params=${JSON.stringify(
        params
      )}`,
      method: 'GET',
      token: token
    }),
  getClient: async ({
    name,
    is_individual,
    is_enterprise,
    is_individual_guest,
    id
  }: FilterClientInput) =>
    fetcher<FilterClient>({
      url: `${BASE_URL}${API.CLIENTS_FILTER}?name=${name}${
        is_individual ? '&is_individual=true' : ''
      }${is_enterprise ? '&is_enterprise=true' : ''}${
        is_individual_guest ? '&is_individual_guest=true' : ''
      }${id ? `&id=${id}` : ''}`,
      method: 'GET',
      token: token
    }),
  getCenter: async ({ name, is_cluster, is_center }: FilterCenterInput) =>
    fetcher<FilterCenter>({
      url: `${BASE_URL}${API.CENTER_FILTER}?name=${name}${is_cluster ? '&is_cluster=true' : ''}${
        is_center ? '&is_center=true' : ''
      }`,
      method: 'GET',
      token: token
    }),
  getCenters: async ({
    name,
    is_cluster,
    is_center,
    all_centers,
    bypass_all_centers
  }: FilterCenterInput) =>
    fetcher<SelectCenters>({
      url: `${BASE_URL}${API.CENTER_FILTER_SELECT}?name=${name}${
        is_cluster ? '&is_cluster=true' : ''
      }${is_center ? '&is_center=true' : ''}${all_centers ? '&all_centers_options=true' : ''}${
        bypass_all_centers ? '&bypass_all_centers=true' : ''
      }`,
      method: 'GET',
      token: token
    }),
  getStaff: async ({ name }: FilterStaffInput) =>
    fetcher<FilterStaff>({
      url: `${BASE_URL}${API.STAFF_FILTER}?name=${name}`,
      method: 'GET',
      token: token
    }),
  getEnterpriseMembers: async ({ id, name }: FilterMemberInput) =>
    fetcher<FilterStaff>({
      url: `${BASE_URL}${API.ENTERPRISE_MEMBERS_FILTER.replace(':id', String(id))}?name=${name}`,
      method: 'GET',
      token: token
    }),
  getWifiSsids: async ({ name, id }: FilterSelectInput) =>
    fetcher<FilterSelectSearch>({
      url: `${BASE_URL}${API.WIFI_FILTER_SSIDS}?name=${name}${id ? `&id=${id}` : ''}`,
      method: 'GET',
      token: token
    }),
  getWifiStations: async ({ name, id }: FilterSelectInput) =>
    fetcher<FilterSelectSearch>({
      url: `${BASE_URL}${API.WIFI_FILTER_STATIONS}?name=${name}${id ? `&id=${id}` : ''}`,
      method: 'GET',
      token: token
    }),
  formItems: async (filters: FiltersInput) =>
    fetcher<Filters>({
      url: `${BASE_URL}${API.FORM_ITEMS}?references=${JSON.stringify(filters)}`,
      method: 'GET',
      token: token
    })
})
