import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate } from 'react-router-dom'
import { FormItems } from 'api/models'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { FormCard } from 'app/components/form/form-card.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { zodResolver } from '@hookform/resolvers/zod'
import { CreatePackageFormSchema, createPackageFormSchema } from 'api/models/packages'
import { useApp } from 'app/providers/app.provider'
import dayjs from 'dayjs'

export const PackageAddView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { user } = useApp()
  const { getFormItemsWithFilters, createPackage } = useFetcher()
  const navigate = useNavigate()
  const { handleMutation } = useFeedback()
  const [formItems, setFormItems] = useState({} as FormItems)
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([['packages_type', '']])
  )
  const methods = useForm<CreatePackageFormSchema>({
    defaultValues: {
      center: Number(user?.mainCenter),
      sender: null,
      receptionDate: dayjs()
    },
    mode: 'onChange',
    resolver: zodResolver(createPackageFormSchema)
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const initOptions = useCallback(async (commonOptions: Map<string, string>) => {
    const response = (await getFormItemsWithFilters.mutateAsync({
      filters: Array.from(commonOptions.keys() as any),
      references_filters: {}
    })) as FormItems
    setFormItems(response as FormItems)
    return response
  }, [])

  useEffect(() => {
    initOptions(commonOptions).then()
  }, [])

  const handleSubmit = async (data: CreatePackageFormSchema) => {
    const { receptionDate, ...rest } = data
    const receptionDateFormat = data.receptionDate.format('YYYY-MM-DD HH:mm:ss')

    await handleMutation({
      confirm: {
        content: t('confirm_create_package')
      },
      onStart: () => setIsLoading(true),
      data: { ...rest, receptionDate: receptionDateFormat },
      mutation: createPackage,
      onSuccess: () => navigate('/packages'),
      toastSuccess: t('create_package_success'),
      toastError: t('create_package_error'),
      onEnd: () => setIsLoading(false)
    })
  }

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('package_add_title')}
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <Grid
          container
          rowSpacing={8}
          columnSpacing={{ xs: 2, sm: 4, md: 8 }}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={12}>
            <FormCard
              isLoading={false}
              title={t('mail_or_package')}
              control={methods.control}
              items={[
                { type: 'clients', label: t('recipient'), name: 'recipient', required: true },
                {
                  type: 'centers',
                  label: t('main_center'),
                  name: 'center',
                  required: true
                },
                {
                  type: 'select',
                  label: t('type'),
                  name: 'type',
                  required: true,
                  formItem: formItems.packages_type
                },
                { type: 'textfield', label: t('sender'), name: 'sender', required: false },
                {
                  type: 'datepicker',
                  label: t('reception_date'),
                  name: 'receptionDate',
                  required: true,
                  inputProps: {
                    disableFuture: true
                  }
                }
              ]}
            />
          </Grid>
        </Grid>
        <Grid sx={{ marginTop: 4 }} container columns={12} columnSpacing={4}>
          <Grid item xs={6} textAlign={'right'}>
            <Button variant={'contained'} color={'primary'} onClick={() => navigate('/packages')}>
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item xs={6} textAlign={'left'}>
            <Button
              disabled={isLoading || methods.formState.isSubmitting || !methods.formState.isValid}
              variant={'contained'}
              color={'primary'}
              onClick={methods.control.handleSubmit(handleSubmit)}
            >
              {t('save')}
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  )
}
