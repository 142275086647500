import { Ctx, fetcher } from '../index'
import { API, BASE_URL } from '../constants'

export const aircallRouter = ({ token }: Ctx) => ({
  clickToCall: async (individual: number, telephone: string) =>
    fetcher<any>({
      url: `${BASE_URL}${API.AIRCALL_CALL}/${individual}/${telephone}`,
      method: 'POST',
      token: token,
      body: { individual, telephone }
    })
})
