import { z } from 'zod'

const referenceEnum = z.enum([
  'individual_roles',
  'languages',
  'languages_to_pdf',
  'document_types',
  'countries',
  'status',
  'centers',
  'sources',
  'opportunity_steps',
  'opportunity_status',
  'opportunity_services',
  'opportunity_qualifications',
  'commitments',
  'event_types',
  'event_status',
  'prescriber_types',
  'consumption_types',
  'ground_refusal',
  'opportunity_ground_refusals',
  'opportunities',
  'civilities',
  'credit_payment_mode',
  'credit_payment_mode_all',
  'credit_payment_mode_tpe',
  'credit_payment_mode_without_transfer',
  'canals',
  'subcanals',
  'staffs',
  'contracts',
  'individuals',
  'consumptions_services_types',
  'consumption_services_types',
  'clientrequest_subcategory',
  'clientrequest_status',
  'meeting_rooms',
  'customer_reservation_services',
  'open_desktops',
  'activities',
  'invoicing_types',
  'activities',
  'ssid_centers',
  'endorsement_reason',
  'options_types',
  'discount_grids',
  'staff_phone_systems',
  'staff_roles',
  'options_services_types',
  'main_services_types',
  'main_services_types_multiple',
  'main_services_typologies',
  'tertiary_years',
  'tertiary_trimester',
  'opportunity_ground_refusals',
  'parking_services_typologies',
  'payment_status',
  'staffs_notification',
  'staff_attributable_default',
  'discount_grid_type',
  'currencies',
  'packages_type',
  'companies'
])

export const formItemValueSchema = z.object({
  // country is using it and id is here a string in this case
  id: z.union([z.string(), z.number()]),
  label: z.string()
})

export type FormItemValue = z.infer<typeof formItemValueSchema>

export const formItemSchema = z.object({
  reference: referenceEnum,
  label: z.string(),
  name: z.string(),
  values: z.array(formItemValueSchema),
  multiple: z.boolean(),
  default: z.array(z.string())
})

export type FormItem = z.infer<typeof formItemSchema>

const formItemsSchema = z.record(referenceEnum, formItemSchema)

export type FormItems = z.infer<typeof formItemsSchema>

const formItemsInput = z.array(referenceEnum)

export type FormItemsInput = z.infer<typeof formItemsInput>

const formItemFilterSchema = z.record(referenceEnum, z.record(z.string(), z.any()))

const formItemsInputWithFiltersSchema = z.object({
  filters: formItemsInput,
  references_filters: formItemFilterSchema
})

export type FormItemsInputWithFilters = z.infer<typeof formItemsInputWithFiltersSchema>
