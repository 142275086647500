import React, { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Grid, Typography, Box, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { FormCard } from 'app/components/form/form-card.component'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormInputProps } from 'app/components/form/controlled-form.component'
import { FloorPlanEditItem, floorPlanEditFormSchema } from 'api/models/floor_plans_management'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'
import { useMutationFetcher } from 'app/hooks/use-mutation-fetcher'
import dayjs from 'dayjs'

export const FloorPlansEditView = (): React.JSX.Element => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams()
  const { FloorPlansManagement } = useFetcherV2()

  const { data: floorPlan, isLoading: floorPlanIsLoading } = useQueryFetcher({
    queryKey: ['floor-plans-management', 'get', id],
    queryFn: () => FloorPlansManagement.read(Number(id))
  })

  const methods = useForm<FloorPlanEditItem>({
    defaultValues: {
      centerName: floorPlan?.centerName,
      floor: floorPlan?.floor,
      version: '',
      description: '',
      begin: dayjs()
    },
    resolver: zodResolver(floorPlanEditFormSchema)
  })

  useEffect(() => {
    if (!floorPlan) return
    methods.setValue('floor', floorPlan.floor)
    methods.setValue('centerName', floorPlan.centerName)
    methods.setValue('version', floorPlan.version)
    methods.setValue('description', floorPlan.description)
    methods.setValue('begin', dayjs(floorPlan.begin))
    if (floorPlan.end) {
      methods.setValue('end', dayjs(floorPlan.end))
    }
  }, [floorPlan, methods])

  const { isValid, isSubmitting } = methods.formState

  const editFloorPlan = useMutationFetcher({
    confirm: {
      content: t('confirm_edit_floor_plan')
    },
    mutationKey: ['floor-plan', 'edit'],
    mutationFn: (data: FloorPlanEditItem) => {
      return FloorPlansManagement.edit(Number(id), data)
    },
    toastSuccess: t('update_floor_plan_success'),
    onSettled: () => navigate(`/floor-plans-management/${id}`)
  })

  const handleSubmit = useCallback(
    async (data: FloorPlanEditItem) => {
      data.begin = data.begin ? dayjs(data.begin).format('YYYY-MM-DD') : null
      data.end = data.end ? dayjs(data.end).format('YYYY-MM-DD') : null
      if (!floorPlan) return
      editFloorPlan(data)
    },
    [editFloorPlan, floorPlan]
  )

  const fields = useMemo(() => {
    return [
      {
        type: 'textfield',
        label: t('center'),
        name: 'centerName',
        inputProps: {
          required: true,
          disabled: true
        }
      },
      {
        type: 'number',
        label: t('floors'),
        name: 'floor',
        inputProps: { disabled: true, required: true }
      },
      {
        type: 'number',
        label: t('version'),
        name: 'version',
        inputProps: {
          required: true,
          min: 0,
          max: 100,
          step: 1
        }
      },
      {
        type: 'textfield',
        label: t('description'),
        name: 'description'
      },
      {
        type: 'datepicker',
        label: t('begin_at') + '*',
        name: 'begin',
        inputProps: { required: true }
      },
      {
        type: 'datepicker',
        label: t('end_at'),
        name: 'end',
        inputProps: { minDate: methods.watch('begin') }
      }
    ] as FormInputProps[]
  }, [t])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('floor_plan_management_edit')} | {floorPlan?.centerName}
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <Grid
          container
          rowSpacing={8}
          columnSpacing={{ xs: 2, sm: 4, md: 8 }}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={12} md={12}>
            <FormCard
              isLoading={floorPlanIsLoading}
              title={t('main_informations')}
              control={methods.control}
              items={fields}
            />
          </Grid>
          <Grid sx={{ marginTop: 4 }} container columns={12} columnSpacing={4}>
            <Grid item xs={6} textAlign={'right'}>
              <Button
                variant={'outlined'}
                color={'error'}
                onClick={() => navigate(`/floor-plans-management/${id}`)}
                data-cy="cancel-button"
              >
                {t('cancel')}
              </Button>
            </Grid>
            <Grid item xs={6} textAlign={'left'}>
              <Button
                disabled={isSubmitting || !isValid}
                variant={'contained'}
                color={'primary'}
                onClick={methods.handleSubmit(handleSubmit)}
                data-cy="save-button"
              >
                {t('save')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  )
}
