import { Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  DashboardContract,
  DashboardMeetings,
  DashboardNextEntries,
  DashboardOccupations,
  DashboardOpenDesktops,
  DashboardOtherInformation,
  DashboardVisits,
  DashboardAppointments
} from 'api/models/dashboard'

export const dashboardRouter = ({ token }: Ctx) => ({
  getContracts: async (params: string) =>
    fetcher<DashboardContract>({
      url: `${BASE_URL}${API.DASHBOARD_CONTRACTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getOccupations: async (params: string) =>
    fetcher<DashboardOccupations>({
      url: `${BASE_URL}${API.DASHBOARD_OCCUPATIONS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getVisits: async (params: string) =>
    fetcher<DashboardVisits>({
      url: `${BASE_URL}${API.DASHBOARD_VISITS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getNexEntries: async (params: string) =>
    fetcher<DashboardNextEntries>({
      url: `${BASE_URL}${API.DASHBOARD_NEXT_ENTRIES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getMeetings: async (params: string) =>
    fetcher<DashboardMeetings>({
      url: `${BASE_URL}${API.DASHBOARD_MEETINGS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getOpenDesktops: async (params: string) =>
    fetcher<DashboardOpenDesktops>({
      url: `${BASE_URL}${API.DASHBOARD_OPEN_DESKTOPS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getOtherInformation: async (params: string) =>
    fetcher<DashboardOtherInformation>({
      url: `${BASE_URL}${API.DASHBOARD_OTHER_INFORMATION}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getAppointments: async (params: string) =>
    fetcher<DashboardAppointments>({
      url: `${BASE_URL}${API.DASHBOARD_APPOINTMENTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
})
