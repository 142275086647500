import { z } from 'zod'
import dayjs from 'dayjs'

export const appointmentFormSchema = z.object({
  organiser: z.number(),
  staff: z.number(),
  center: z.number({ coerce: true }),
  description: z.string().optional().nullable(),
  beginDate: z.instanceof(dayjs as any).transform((date) => date.format('YYYY-MM-DD HH:mm:ss')),
  endDate: z.instanceof(dayjs as any).transform((date) => date.format('YYYY-MM-DD HH:mm:ss')),
  guests: z.array(z.number())
})

export type AppointmentForm = z.infer<typeof appointmentFormSchema>
