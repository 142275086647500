import { z } from 'zod'
import { itemListSchema } from 'api/models/commons'

const transformGuestsSum = (input: unknown) => {
  if (typeof input === 'string') {
    try {
      const jsonString = `[${input}]`
      return JSON.parse(jsonString)
    } catch (error) {
      console.error('Erreur lors de la transformation de guestsSum:', error)
      return []
    }
  }
  return []
}

const appointmentSchema = z.object({
  id: z.number(),
  organiser: z.string(),
  organiserId: z.number(),
  staff: z.string(),
  staffId: z.number(),
  center: z.string(),
  type: z.number(),
  description: z.string(),
  status: z.number(),
  statusLabel: z.string(),
  cancellationDate: z.string().nullable(),
  begin: z.string(),
  end: z.string(),
  guestsSum: z
    .string()
    .transform(transformGuestsSum)
    .pipe(
      z.array(
        z.object({
          id: z.number(),
          name: z.string(),
          status: z.number(),
          statusLabel: z.string()
        })
      )
    )
})

export type Appointment = z.infer<typeof appointmentSchema>

const appointmentListSchema = itemListSchema(appointmentSchema)
export type AppointmentList = z.infer<typeof appointmentListSchema>
