import styled from '@emotion/styled'
import {
  Business,
  CalendarMonth,
  Computer,
  Edit,
  EventBusy,
  Group,
  Handshake,
  SentimentDissatisfied,
  SentimentNeutral,
  SentimentVerySatisfied,
  SquareFoot
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Divider, Grid, IconButton, Paper as MuiPaper, Skeleton, Typography } from '@mui/material'
import { Container, Stack } from '@mui/system'

import type { Opportunity } from 'api/models'
import { formatDate, formatSurface } from 'app/utils/format'
import { green, grey, yellow } from '@mui/material/colors'
import { Link } from 'app/components/link.component'
import dayjs from 'dayjs'
import { darken } from 'polished'
import { AircallComponent } from '../../aircall/components/aircall.component'

const Paper = styled(MuiPaper)`
  background: ${(props) =>
    props.theme.palette.mode === 'dark'
      ? darken(0.03, props.theme.palette.background.paper)
      : props.theme.palette.grey[400]};
`

const Icon = styled('div')`
  background-color: ${(props) =>
    props.theme.palette.mode === 'dark'
      ? darken(0.1, props.theme.palette.background.paper)
      : props.theme.palette.grey[100]};
  color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 4px;
  padding: 8px;
`

const Item = styled(Stack)`
  gap: 8px;
  flex-direction: row;
  height: fit-content;
  align-items: center;
`

const ItemName = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 600;
`

const ItemTitle = styled(Typography)`
  font-weight: 600;
  margin-right: 0.25rem;
`

const CustomGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 1rem;

  ${({ theme }) => theme.breakpoints.between('md', 'lg')} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const QUALIFICATION_ICONS: { [key: string]: any } = {
  0: <SentimentDissatisfied color={'warning'} />,
  1: <SentimentNeutral color={'primary'} />,
  2: <SentimentVerySatisfied color={'action'} />
}

const QUALIFICATION_COLORS: { [key: string]: any } = {
  0: yellow[300],
  1: grey[300],
  2: green[500]
}
interface IOpportunityDetailsProps {
  opportunity: Opportunity
  isLoading: boolean
}

export const OpportunityDetails = ({ opportunity, isLoading }: IOpportunityDetailsProps) => {
  const { t } = useTranslation()

  if (isLoading) return <Skeleton />

  const items = [
    { icon: Business, label: t('center'), value: opportunity.centerName },
    { icon: Computer, label: t('type'), value: opportunity.typeLabel },
    {
      icon: SquareFoot,
      label: t('surface'),
      value: opportunity.surface ? formatSurface(opportunity.surface) : 'N/A'
    },
    { icon: Group, label: t('capacity'), value: opportunity.capacity || 'N/A' },
    {
      icon: CalendarMonth,
      label: t('begin'),
      value: formatDate(opportunity.begin ? opportunity.begin : '')
    },
    { icon: Handshake, label: t('commitment'), value: opportunity.commitmentLabel },
    {
      icon: EventBusy,
      label: t('end'),
      value: opportunity.end
        ? formatDate(opportunity.end)
        : formatDate(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
    }
  ]
  return (
    <Container sx={{ marginY: '2rem' }}>
      <Paper sx={{ position: 'relative' }}>
        <Grid textAlign={'right'} padding={2} position={'absolute'} right={0}>
          <IconButton size={'small'} href={`/opportunities/${opportunity.id}/edit`}>
            <Edit fontSize={'small'} />
          </IconButton>
        </Grid>
        <Grid container columns={12}>
          <Grid item xs={12}>
            <CustomGrid>
              {items.map((item, index) => (
                <Item key={index}>
                  <Icon>
                    <item.icon />
                  </Icon>
                  <Stack>
                    <Typography variant="caption">{item.label}</Typography>
                    <ItemName variant="body2">{item.value}</ItemName>
                  </Stack>
                </Item>
              ))}
              <Item>
                <Icon
                  style={{ backgroundColor: QUALIFICATION_COLORS[opportunity.qualificationId] }}
                >
                  {QUALIFICATION_ICONS[opportunity.qualificationId]}
                </Icon>
                <Stack>
                  <Typography variant="caption">{t('qualification')}</Typography>
                  <ItemName variant="body2">{opportunity.qualificationLabel}</ItemName>
                </Stack>
              </Item>
            </CustomGrid>
          </Grid>

          <Divider component="div" sx={{ width: '100%' }} />

          <Grid container columns={12} spacing={2} paddingX={6} paddingY={4}>
            <Grid item xs={4}>
              <Stack direction={'row'} flexWrap={'wrap'}>
                <ItemTitle variant="caption">{t('user')}:</ItemTitle>
                <ItemName variant="caption">
                  <Link to={`/individuals/${opportunity.userId}`}>{opportunity.userName}</Link>
                </ItemName>
                <AircallComponent individualId={opportunity.userId} />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack direction={'row'}>
                <ItemTitle variant="caption">{t('prescriber')}:</ItemTitle>
                <ItemName variant="caption">
                  {opportunity.prescriberId ? (
                    <Link to={`/individuals/${opportunity.prescriberId}`}>
                      {opportunity.prescriberName}
                    </Link>
                  ) : (
                    ''
                  )}
                </ItemName>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack direction={'row'}>
                <ItemTitle variant="caption">{t('canal')}:</ItemTitle>
                <ItemName variant="caption">{opportunity.canalLabel}</ItemName>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack direction={'row'}>
                <ItemTitle variant="caption">{t('enterprise')}:</ItemTitle>
                <ItemName variant="caption">
                  <Link to={`/enterprises/${opportunity.enterpriseId}`}>
                    {opportunity.enterpriseName}
                  </Link>
                </ItemName>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack direction={'row'}>
                <ItemTitle variant="caption">{t('signatory')}:</ItemTitle>
                <ItemName variant="caption">
                  {opportunity.signatoryId ? (
                    <Link to={`/individuals/${opportunity.signatoryId}`}>
                      {opportunity.signatoryName}
                    </Link>
                  ) : (
                    ''
                  )}
                </ItemName>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack direction={'row'}>
                <ItemTitle variant="caption">{t('subcanal')}:</ItemTitle>
                <ItemName variant="caption">{opportunity.subCanalLabel}</ItemName>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack direction={'row'}>
                <ItemTitle variant="caption">{t('main_center')}:</ItemTitle>
                <ItemName variant="caption">{opportunity.enterpriseMainCenter}</ItemName>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack direction={'row'}>
                <ItemTitle variant="caption">{t('decider')}:</ItemTitle>
                <ItemName variant="caption">
                  {opportunity.deciderId ? (
                    <Link to={`/individuals/${opportunity.deciderId}`}>
                      {opportunity.deciderName}
                    </Link>
                  ) : (
                    ''
                  )}
                </ItemName>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack direction={'row'}>
                <ItemTitle variant="caption">{t('source')}:</ItemTitle>
                <ItemName variant="caption">{opportunity.sourceLabel}</ItemName>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}
