import { Grid, IconButton, Typography, Button, TextField, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AddCircle } from '@mui/icons-material'
import { Box, Container } from '@mui/system'
import { formatDateWithTime, formatTypePackage } from 'app/utils/format'
import { List } from 'app/components/lists/list'
import React, { useCallback, useEffect, useState, ChangeEvent } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { PackageListSchema, PackagesClientsRelated } from 'api/models/packages'
import BaseModal from 'app/components/modals/base.modal'
import { useFeedback } from 'app/providers/feedback.provider'
import CircularProgress from '@mui/material/CircularProgress'

export const PackagesView = (): React.JSX.Element => {
  const { setSearchParams, searchParams, getPackagesList, checkCodePackage, packageClientRelated } =
    useFetcher()
  const { t } = useTranslation()
  const { total, orderBy, setTotal, handleSort, handleFilter, filtersList, initFilters } = useList()
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [packages, setPackages] = useState<PackageListSchema[]>([])
  const [openActive, setOpenActive] = useState<boolean>(false)
  const [securityCode, setSecurityCode] = useState<string>()
  const [disabled, setDisabled] = useState<boolean>(true)
  const [checkCode, setCheckCode] = useState<{
    packageId: number
    securityCode: string
    handoverClient: number
  }>({
    packageId: 0,
    securityCode: '',
    handoverClient: 0
  })
  const [error, setError] = useState<boolean>(false)
  const { handleMutation } = useFeedback()
  const { toast } = useFeedback()
  const [clientsRelated, setClientsRelated] = useState<PackagesClientsRelated>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['packages_type', 'type'],
      ['packages_status', 'status']
    ])
  )

  const refreshList = useCallback(async () => {
    setListIsLoading(true)
    await getPackagesList
      .mutateAsync()
      .then((data) => {
        setTotal(data.total)
        setPackages(data.items)
      })
      .finally(() => setListIsLoading(false))
  }, [getPackagesList])

  useEffect(() => {
    initFilters(commonFilters).then(refreshList)
  }, [])

  const handleUpdate = useCallback(
    async (e: any) => {
      e.preventDefault()

      await handleMutation({
        mutation: checkCodePackage,
        data: checkCode,
        onSuccess: (data) => {
          const { found } = data
          setError(!found)
          if (found) {
            toast({
              variant: 'success',
              message: t('success_received_package')
            })
            setOpenActive(false)
            refreshList()
          }
        }
      })
      setSecurityCode(undefined)
      setCheckCode({ packageId: 0, securityCode: '', handoverClient: 0 })
    },
    [checkCodePackage, checkCode, refreshList]
  )

  const handleOpenModal = useCallback(
    async (packageId: number, clientId: number) => {
      setOpenActive(true)

      setIsLoading(true)
      await handleMutation({
        mutation: packageClientRelated,
        data: clientId,
        onSuccess: (data) => {
          setCheckCode({ packageId: packageId, securityCode: '', handoverClient: data[0].id })
          setClientsRelated(data)
        }
      })
      setIsLoading(false)
    },
    [handleMutation, packageClientRelated]
  )

  const statusFormatter = useCallback((item: PackageListSchema) => {
    const currentValue = Number(item.status)
    return (
      <Button
        onClick={() => {
          if (currentValue === 1) {
            handleOpenModal(Number(item.id), Number(item.recipientId)).then()
          }
        }}
        variant="contained"
        size={'small'}
        color={currentValue === 2 ? 'success' : 'primary'}
      >
        {item.statusLabel}
      </Button>
    )
  }, [])

  useEffect(() => {
    setDisabled(true)
    if (securityCode !== undefined && securityCode.length === 4) {
      setCheckCode((prevState) => ({ ...prevState, securityCode: String(securityCode) }))
      setDisabled(false)
    }
  }, [securityCode])

  return (
    <Container>
      <Grid item xs={12}>
        <Box marginBottom="2rem">
          <Typography variant="h2" gutterBottom display="inline">
            {t('mail_packages')}
            <IconButton
              title={t('mail_packages_add')}
              aria-label={t('mail_packages_add')}
              color="primary"
              href={'/packages/add'}
              style={{ marginLeft: 7 }}
            >
              <AddCircle fontSize="small" />
            </IconButton>
          </Typography>
        </Box>
        <Grid>
          <FiltersBox
            filters={filtersList}
            handleFilters={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          >
            <SearchClientInput
              slug="recipient"
              label={t('recipient')}
              onChange={() => handleFilter(refreshList, true)}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
            />
            <SearchCenterInput
              slug="center"
              onChange={() => handleFilter(refreshList, true)}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
              byPassAllCenters={true}
            />
          </FiltersBox>
        </Grid>
        <Grid>
          <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
            <Typography variant="body2" gutterBottom>
              {packages.length} / {total}
            </Typography>
          </Grid>
          <List
            items={packages}
            columns={[
              {
                label: t('reference'),
                slug: 'reference'
              },
              {
                label: t('recipient'),
                slug: 'recipientFullName',
                link: { base: '/individuals', slug: 'recipientId' }
              },
              {
                label: t('center'),
                slug: 'siteName'
              },
              {
                label: t('type'),
                slug: 'type',
                valueFormatter: formatTypePackage,
                text: 'typeLabel'
              },
              {
                label: t('status'),
                slug: 'status',
                condition: (item: PackageListSchema) => statusFormatter(item)
              },
              {
                label: t('reception_date'),
                slug: 'receptionDate',
                valueFormatter: formatDateWithTime
              },
              {
                label: t('handover_date'),
                slug: 'handoverDate',
                valueFormatter: formatDateWithTime
              },
              {
                label: t('handover_client'),
                slug: 'handoverClientFullName'
              },
              {
                label: t('sender'),
                slug: 'sender'
              },
              {
                label: t('last_sent_invitation_date'),
                slug: 'lastSentInvitationDate',
                valueFormatter: formatDateWithTime
              }
            ]}
            handleReset={() => handleFilter(refreshList, true)}
            sort={orderBy}
            handleSort={(property) => handleSort(refreshList, property)}
            isLoading={listIsLoading}
          />
          <BaseModal
            open={openActive}
            setOpen={setOpenActive}
            title={'security_code_package'}
            handleUpdate={handleUpdate}
            size={'xs'}
            labelButton={'Vérifier'}
            disabled={disabled}
            onHandleClose={() => {
              setError(false)
              setDisabled(true)
            }}
          >
            <Box
              display={'flex'}
              justifyContent={'center'}
              flexDirection={'column'}
              alignItems={'center'}
              height={100}
            >
              {isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <>
                  <TextField
                    size="small"
                    inputProps={{
                      maxLength: 4
                    }}
                    style={{ marginBottom: 25 }}
                    error={error}
                    type="text"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setError(false)
                      setSecurityCode(event.target.value)
                    }}
                    fullWidth
                    required
                    id="outlined-required"
                    label="Entrer le code de sécurité"
                  />
                  <TextField
                    fullWidth
                    id="standard-select-currency"
                    select
                    variant="standard"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setCheckCode((prevState) => ({
                        ...prevState,
                        handoverClient: Number(event.target.value)
                      }))
                    }}
                    value={checkCode.handoverClient}
                  >
                    {clientsRelated.map((option) => {
                      return (
                        <MenuItem key={option.id} value={option.id}>
                          {option.fullName}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </>
              )}
            </Box>
          </BaseModal>
        </Grid>
      </Grid>
    </Container>
  )
}
