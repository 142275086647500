import { Card, CardContent, Divider, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ArrowBack, Edit } from '@mui/icons-material'
import { Box, Stack } from '@mui/system'
import InfoCard from 'app/components/card/info-card.component'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ListIcon from '@mui/icons-material/List'
import { TitleComponent } from 'app/components/titles/title.component'
import { List } from 'app/components/lists/list'
import { useList } from 'app/providers/list.provider'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { formatDate } from 'app/utils/format'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useQueryFetcherList } from 'app/hooks/use-query-fetcher-list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useEffect, useRef } from 'react'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { ServiceShapeListItem } from 'api/models/floor_plans_management'
import { useApp } from 'app/providers/app.provider'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { UpdateImageDialog } from 'modules/floorplans/components/update_image_dialog.component'

export const FloorPlanDetailsView = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { searchParams, setSearchParams } = useFetcher()
  const { FloorPlansManagement } = useFetcherV2()
  const { id } = useParams()
  const { orderBy, handleSort, isLast, total, handleFilter } = useList()
  const { getRight } = useApp()

  const {
    data: floorPlan,
    isLoading: floorPlanIsLoading,
    refetch
  } = useQueryFetcher({
    queryKey: ['floor-plans-management', 'get', id],
    queryFn: () => FloorPlansManagement.read(Number(id))
  })

  const {
    isFetching: servicesIsLoading,
    items: services,
    refreshList
  } = useQueryFetcherList({
    queryKey: ['floor-plans-management', id, 'services-shapes-list'],
    queryFn: () => FloorPlansManagement.serviceShapesList(id ?? '', searchParams.toString())
  })

  useEffect(() => {
    setSearchParams((searchParams) => {
      searchParams.delete('offset')
      return searchParams
    })
    void refreshList()
  }, [])

  const dialogRefImageInternal = useRef<DialogRef>(null)
  const dialogRefImageExternal = useRef<DialogRef>(null)

  const handleOpenDialogInternal = () => dialogRefImageInternal.current?.open()
  const handleOpenDialogExternal = () => dialogRefImageExternal.current?.open()

  return (
    <div>
      <TitleComponent
        text={
          <>
            <span>{t('floor_plan')}</span>
            {floorPlan && (
              <>
                <span> | {floorPlan.centerName}</span>
                <span>
                  {' '}
                  - {t('floor')} {floorPlan.floor}
                </span>
                <span> - {floorPlan?.version}</span>
              </>
            )}
          </>
        }
        variant={'h2'}
        paddingTop={0}
        icon={
          <Link to={`/floor-plans-management`}>
            <IconButton size="small" color="primary">
              <ArrowBack />
            </IconButton>
          </Link>
        }
      />

      <InfoCard
        title={t('informations')}
        titleAction={{
          icon: Edit,
          onClick: () => navigate(`/floor-plans-management/${id}/edit`),
          right: getRight('floor_plan', 'isEdit')
        }}
        isLoading={floorPlanIsLoading}
        columns={[
          { label: t('center'), value: floorPlan?.centerName },
          { label: t('floor'), value: floorPlan?.floor },
          { label: t('version'), value: floorPlan?.version },
          { label: t('description'), value: floorPlan?.description },
          {
            label: t('floor_plan_internal_image'),
            value: (
              <Stack direction="row" alignItems="center">
                {floorPlan?.internalImage && (
                  <img
                    src={floorPlan?.internalImage}
                    alt="floorPlan internalImage"
                    style={{ maxWidth: 100 }}
                  />
                )}
                <IconButton size="small" color={'primary'} onClick={handleOpenDialogInternal}>
                  <Edit fontSize="small" />
                </IconButton>
              </Stack>
            )
          },
          {
            label: t('floor_plan_external_image'),
            value: (
              <Stack direction="row" alignItems="center">
                {floorPlan?.externalImage && (
                  <img
                    src={floorPlan?.externalImage}
                    alt="floorPlan externalImage"
                    style={{ maxWidth: 100 }}
                  />
                )}
                <IconButton size="small" color={'primary'} onClick={handleOpenDialogExternal}>
                  <Edit fontSize="small" />
                </IconButton>
              </Stack>
            )
          },
          { label: t('begin_date'), value: formatDate(floorPlan?.begin ?? '') },
          { label: t('end_date'), value: formatDate(floorPlan?.end ?? '') }
        ]}
      ></InfoCard>

      {floorPlan && (
        <>
          <UpdateImageDialog
            dialogRef={dialogRefImageInternal}
            floorPlan={floorPlan}
            imageKey="internalImage"
            refetch={refetch}
          />
          <UpdateImageDialog
            dialogRef={dialogRefImageExternal}
            floorPlan={floorPlan}
            imageKey="externalImage"
            refetch={refetch}
          />
        </>
      )}

      <Card variant="outlined" sx={{ mt: 4 }} data-cy="list-services">
        <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
            <TitleComponent
              text={
                <Stack direction="row" alignItems="center" gap={2}>
                  {t('floor_plan_services')}
                </Stack>
              }
              variant={'h3'}
              paddingTop={0}
              icon={<ListIcon color="action" fontSize="small" />}
            />
            <Typography display="block" sx={{ ml: 'auto!important', mr: '12px!important' }}>
              {services?.length} / {total}
            </Typography>
          </Stack>
        </Box>
        <Divider />
        <CardContent>
          <List
            items={services}
            columns={[
              { label: t('service'), slug: 'serviceLabel' },
              { label: t('points'), slug: 'points', unsorted: true },
              {
                label: t('online'),
                slug: 'isOnline',
                condition: (item: ServiceShapeListItem) =>
                  item.isOnline ? <CheckIcon color="success" /> : <CloseIcon color="error" />
              }
            ]}
            sort={orderBy}
            handleSort={(property) => handleSort(refreshList, property)}
            isLoading={servicesIsLoading}
          />
          {!isLast && !servicesIsLoading && (
            <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
          )}
        </CardContent>
      </Card>
    </div>
  )
}
