import {
  Card,
  CardContent,
  Typography,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Chip as MuiChip
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import React from 'react'
import styled from '@emotion/styled'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { DashboardAppointment, DashboardAppointments } from 'api/models/dashboard'

const StyledChip = styled(MuiChip)`
  padding: 5px;
  height: auto;
  opacity: 0.75;

  span {
    padding: 0 2px;
    font-size: 12px;
    font-weight: bold;
  }
`

interface IDashboardCardAppointments {
  appointments: DashboardAppointments
  isLoading: boolean
}
export function DashboardCardAppointments({ appointments, isLoading }: IDashboardCardAppointments) {
  if (isLoading) {
    return <CardSkeleton height={160}></CardSkeleton>
  }

  const getStatusColor = (status: number) => {
    switch (status) {
      case 1:
        return 'secondary'
      case 2:
        return 'error'
      case 3:
        return 'success'
    }
  }

  return (
    <Card sx={{ minHeight: 'calc(100% - 35px)' }}>
      <CardContent>
        <RouterLink to={`/appointments`} style={{ textDecoration: 'none', color: 'inherit' }}>
          <Table size="small">
            <TableBody>
              {appointments?.map((appointment: DashboardAppointment, index: number) => (
                <TableRow hover key={index}>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>{appointment.beginDate}</TableCell>
                  <TableCell>
                    <Typography component={'span'} variant={'body1'} sx={{ opacity: 0.75 }}>
                      {appointment.beginTime}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                    <RouterLink
                      to={`/individuals/${appointment.organiserId}`}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {appointment.organiser}
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    <StyledChip
                      label={appointment.statusLabel}
                      color={getStatusColor(appointment.status)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </RouterLink>
      </CardContent>
    </Card>
  )
}
