import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectProps,
  MenuProps
} from '@mui/material'
import { FormItem } from 'api/models'
import { Control, Controller } from 'react-hook-form'

type IControlledTextFieldProps = {
  formItem: FormItem | any
  label?: string
  name: string
  control: Control<any>
  required?: boolean
  scrollable?: boolean
} & SelectProps

const DROPDOWN_MAX_HEIGHT = 48 * 4.5 + 8 // 4.5 items with some padding

export function ControlledSelectField({
  name,
  label,
  control,
  required,
  formItem,
  scrollable,
  ...otherProps
}: IControlledTextFieldProps) {
  const menuProps: Partial<MenuProps> = scrollable
    ? {
        PaperProps: {
          style: {
            maxHeight: DROPDOWN_MAX_HEIGHT,
            width: 250
          }
        }
      }
    : {}

  return (
    <Controller
      render={({ field: { name, ref, value, onChange }, fieldState: { error } }) => {
        return (
          <FormControl fullWidth required={required}>
            <InputLabel size="small" id={`${name}_label`} data-cy={`input-label-${name}`}>
              {label}
            </InputLabel>
            <Select
              labelId={label ? `${name}_label` : ''}
              size={'small'}
              fullWidth
              value={value ? String(value) : ''}
              inputRef={ref}
              onChange={onChange}
              label={label || ''}
              data-cy={`combobox-${name}`}
              error={!!error?.message}
              MenuProps={menuProps}
              {...otherProps}
            >
              {formItem?.values
                ? Array.isArray(formItem?.values)
                  ? formItem.values.map((v: any) => (
                      <MenuItem key={v.id} value={String(v.id)} data-cy={`item-${name}-${v.id}`}>
                        {v.label}
                      </MenuItem>
                    ))
                  : formItem.values &&
                    Object.keys(formItem?.values).map((category) => {
                      return [
                        <ListSubheader key={category}>{category}</ListSubheader>,
                        formItem.values[category].map((v: any) => (
                          <MenuItem
                            key={v.id}
                            value={String(v.id)}
                            data-cy={`item-${name}-${v.id}`}
                          >
                            {v.label}
                          </MenuItem>
                        ))
                      ]
                    })
                : null}
            </Select>
          </FormControl>
        )
      }}
      control={control}
      name={name}
    />
  )
}
