import React, { useEffect } from 'react'
import { Grid, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { List } from 'app/components/lists/list'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { Box, Container } from '@mui/system'
import { AddCircle } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'
import { useNavigate } from 'react-router-dom'
import { useCompaniesList } from 'app/hooks/api/companies.hooks'

export const CompaniesView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getRight } = useApp()
  const navigate = useNavigate()

  const { initSort, isLast, total, orderBy, handleSort, handleFilter } = useList()

  const { items: companies, isFetching: listIsLoading, refreshList } = useCompaniesList()

  useEffect(() => {
    initSort([]).then(() => refreshList(true))
  }, [])

  return (
    <Container>
      <Grid item xs={12}>
        <Box marginBottom="2rem">
          <Typography variant="h2" gutterBottom display="inline">
            {t('companies')}
            {getRight('company', 'isAdd') ? (
              <IconButton
                title={t('add_company')}
                aria-label={t('add_company')}
                color="primary"
                onClick={() => navigate('/companies/add')}
              >
                <AddCircle fontSize="small" />
              </IconButton>
            ) : (
              <></>
            )}
          </Typography>
        </Box>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom>
            {companies.length} / {total}
          </Typography>
        </Grid>
        <List
          items={companies}
          columns={[
            {
              label: t('id'),
              slug: 'id',
              link: { base: '/companies', slug: 'id' }
            },
            {
              label: t('reference'),
              slug: 'reference',
              link: { base: '/companies', slug: 'id' }
            },
            {
              label: t('company_name'),
              slug: 'company_name',
              link: { base: '/companies', slug: 'id' }
            },
            {
              label: t('siret'),
              slug: 'siret'
            },
            {
              label: t('par'),
              slug: 'par'
            },
            {
              label: t('vat'),
              slug: 'vat'
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {companies.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
