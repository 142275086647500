import React, { useEffect, useRef, useState } from 'react'
import { Box, Card, Container, Grid, IconButton, Typography } from '@mui/material'
import { List } from 'app/components/lists/list'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import dayjs from 'dayjs'
import { MonthPicker } from 'app/components/filters/month-picker'
import { formatDate } from 'app/utils/format'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { FloorSelectInput } from 'app/components/filters/floor_select_input'
import { FiltersContainer } from 'app/components/filters/filters-container'
import { useQueryFetcherList } from 'app/hooks/use-query-fetcher-list'
import { useNavigate } from 'react-router-dom'
import { ListPagination } from 'app/components/lists/list-pagination'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { DialogAddFloorPlan } from 'modules/floorplans/components/dialog_add_floor_plan.component'
import { AddCircle } from '@mui/icons-material'
import { FloorPlanListItem } from 'api/models/floor_plans_management'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { DialogDuplicateFloorPlan } from 'modules/floorplans/components/dialog_duplicate_floor_plan.component'
import { useApp } from 'app/providers/app.provider'

export const FloorPlansManagementView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { setSearchParams, searchParams } = useFetcher()
  const { orderBy, handleSort, isLast, total, handleFilter } = useList()
  const { FloorPlansManagement } = useFetcherV2()
  const navigate = useNavigate()
  const { getRight } = useApp()

  useEffect(() => {
    if (!searchParams.get('date')) {
      const now = dayjs()
      searchParams.set('date', now.endOf('month').format('YYYY-MM-DD'))
      setSearchParams(searchParams)
    }
  }, [searchParams, setSearchParams])

  useEffect(() => {
    setSearchParams((searchParams) => {
      searchParams.delete('offset')
      return searchParams
    })
  }, [])

  const {
    isFetching: isLoading,
    items,
    refreshList
  } = useQueryFetcherList({
    queryKey: ['floor-plans-management', 'list'],
    queryFn: () => FloorPlansManagement.list(searchParams.toString()),
    enabled: searchParams.has('date')
  })

  const handleCenterChange = () => {
    setSearchParams((searchParams) => {
      searchParams.delete('floor')
      return searchParams
    })
  }

  const dialogDuplicateRef = useRef<DialogRef>(null)
  const [selectedFloorPlan, setSelectedFloorPlan] = useState<FloorPlanListItem | null>(null)

  const handleDuplicate = (e: React.MouseEvent, floorPlan: FloorPlanListItem) => {
    e.stopPropagation()
    setSelectedFloorPlan(floorPlan)
    dialogDuplicateRef.current?.open()
  }

  const dialogAddRef = useRef<DialogRef>(null)
  const handleOpenDialog = () => {
    dialogAddRef.current?.open()
  }

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('floor_plan_management')}
        </Typography>
      </Box>
      <Grid container justifyContent="space-between" alignItems="center" data-cy="filters">
        <Grid item>
          <FiltersContainer
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            onChange={() => handleFilter(refreshList, true)}
          >
            <MonthPicker
              slug={'date'}
              label={t('date')}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
              views={['year', 'month', 'day']}
              format={'YYYY-MM-DD'}
            />
            <SearchCenterInput
              slug="center"
              setSearchParams={setSearchParams}
              searchParams={searchParams}
              byPassAllCenters={true}
              onChange={handleCenterChange}
            />
            <FloorSelectInput searchParams={searchParams} setSearchParams={setSearchParams} />
            <IconButton color="primary" onClick={handleOpenDialog} data-cy="add-button">
              <AddCircleOutlineIcon />
            </IconButton>
          </FiltersContainer>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
        <Typography variant="body2" gutterBottom>
          {items.length} / {total}
        </Typography>
      </Grid>
      <Card variant="outlined">
        <List
          items={items}
          columns={[
            { label: t('center'), slug: 'centerName' },
            { label: t('floor'), slug: 'floor' },
            { label: t('version'), slug: 'index' },
            { label: t('begin'), slug: 'begin', valueFormatter: formatDate },
            {
              label: t('end'),
              slug: 'end',
              condition: (floorPlan: FloorPlanListItem) => {
                if (floorPlan.end) return formatDate(floorPlan.end)
                if (getRight('floor_plan', 'isAdd')) {
                  return (
                    <IconButton
                      color="primary"
                      size={'small'}
                      onClick={(e) => handleDuplicate(e, floorPlan)}
                      data-cy="duplicate-button"
                    >
                      <AddCircle fontSize={'small'} />
                    </IconButton>
                  )
                }
              }
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(null, property)}
          isLoading={isLoading}
          onClick={(item) => navigate(`/floor-plans-management/${item.id}`)}
        />
        {!isLast && !isLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Card>
      <DialogDuplicateFloorPlan
        floorPlan={selectedFloorPlan}
        dialogRef={dialogDuplicateRef}
        refreshList={refreshList}
      />
      <DialogAddFloorPlan dialogRef={dialogAddRef} refreshList={refreshList} />
    </Container>
  )
}
