import { useTranslation } from 'react-i18next'
import { Autocomplete, Button, Paper as MuiPaper, Stack, TextField } from '@mui/material'
import { ClientsRecipientRelated, IndividualDetails } from 'api/models'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Box } from '@mui/system'
import { TitleComponent } from 'app/components/titles/title.component'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { useDebouce } from 'app/hooks/use-debounce'

interface IIndividualDelegate {
  individual: IndividualDetails
}

export function IndividualDelegate({ individual }: IIndividualDelegate) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const {
    getClientRelationList,
    getClientRelationFilter,
    createClientRelation,
    removeClientRelation
  } = useFetcher()
  const { handleMutation } = useFeedback()
  const [clients, setClients] = useState<any>([])
  const [clientRelation, setClientRelation] = useState<ClientsRecipientRelated>()
  const [selectedClient, setSelectedClient] = useState<any>(null)
  const [inputValue, setInputValue] = useState('')

  const getFilterIndividual = useCallback(
    async (name = '') => {
      await handleMutation({
        mutation: getClientRelationFilter,
        data: { client: individual.id, params: { name: name } },
        onSuccess: (data) => {
          setClients(data)
        }
      })
    },
    [handleMutation, getClientRelationFilter]
  )

  const getIndividualList = useCallback(async () => {
    await handleMutation({
      mutation: getClientRelationList,
      data: { client: individual.id },
      onSuccess: (data) => {
        setClientRelation(data)
      }
    })
  }, [handleMutation, getClientRelationList, individual])

  const create = useCallback(async () => {
    await handleMutation({
      mutation: createClientRelation,
      data: { client: individual.id, data: { client: Number(selectedClient.id) } },
      onSuccess: async () => {
        setSelectedClient(null)
        setInputValue('')
        await getIndividualList()
        await getFilterIndividual()
      },
      confirm: {
        content: selectedClient.fullName,
        title: 'confirm_add_delegate'
      },
      toastSuccess: t('delegate_add_success')
    })
  }, [
    handleMutation,
    createClientRelation,
    getIndividualList,
    getFilterIndividual,
    selectedClient,
    individual
  ])

  const remove = useCallback(
    async (item: any) => {
      await handleMutation({
        mutation: removeClientRelation,
        data: { client: individual.id, data: { client: Number(item.id) } },
        onSuccess: async () => {
          await getIndividualList()
          await getFilterIndividual()
        },
        confirm: {
          content: item.firstname + ' ' + item.lastname,
          title: 'confirm_delete_delegate',
          variant: 'error'
        },
        toastSuccess: t('delegate_delete_success')
      })
    },
    [handleMutation, removeClientRelation, getIndividualList, getFilterIndividual, individual]
  )

  const initRequest = useCallback(async () => {
    setIsLoading(true)
    await getIndividualList()
    setIsLoading(false)
  }, [getFilterIndividual, getIndividualList])

  useEffect(() => {
    initRequest().then()
  }, [])

  useDebouce(
    async () => {
      await getFilterIndividual(inputValue)
    },
    [inputValue],
    350
  )

  const map = useMemo(() => {
    const map = new Map()
    map.set('items', [
      {
        component: (item: any) => <div>{item.lastname + ' ' + item.firstname}</div>
      },
      {
        component: (item: any) => {
          return (
            <Button variant="contained" size={'small'} color={'error'} onClick={() => remove(item)}>
              Retirer
            </Button>
          )
        },
        style: { textAlign: 'right' }
      }
    ])
    map.set('data', clientRelation)
    return map
  }, [clientRelation, remove])

  return (
    <MuiPaper>
      <Box paddingY={4} paddingX={4}>
        <Stack direction="row" justifyContent="flex-start" spacing={5} marginBottom={10}>
          <Autocomplete
            fullWidth
            size={'small'}
            value={selectedClient}
            getOptionLabel={(option: any) => option.fullName}
            getOptionKey={(option) => option.id}
            options={clients}
            onChange={(_: any, newValue: any) => {
              setSelectedClient(newValue)
            }}
            onInputChange={(event: any) => {
              if (!event) return
              setInputValue(event.target.value as string)
            }}
            renderInput={(params) => <TextField {...params} label={t('delegate_new')} />}
          />
          <Button
            variant="contained"
            disabled={inputValue === '' || inputValue === undefined}
            onClick={create}
          >
            Ajouter
          </Button>
        </Stack>
        <Stack justifyContent="flex-start" spacing={1}>
          <TitleComponent text={t('delegate_list')} variant={'h3'} />
          <ListSheetComponent
            isLoading={
              isLoading || map === undefined || map.size === 0 || map.get('data') === undefined
            }
            data={map}
          />
        </Stack>
      </Box>
    </MuiPaper>
  )
}
