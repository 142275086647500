import { Box, Container, Grid, Paper as MuiPaper, Typography } from '@mui/material'
import { FiltersBox } from 'app/components/filters/filter-box'
import { RangePeriodPicker } from 'app/components/filters/range-period-picker'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import dayjs from 'dayjs'
import { useApp } from 'app/providers/app.provider'
import { SoldNomadicContractsChart } from 'modules/stats/components/sold-nomadic-contracts-chart.component'
import { NomadOccupationStats } from 'modules/stats/components/nomad-occupation-stats.component'
import { TableNomads } from 'modules/stats/components/table-stats-nomad.component'
import { CaVsBpChartComponent } from 'modules/stats/components/ca-vs-bp-chart.component'

export const StatsNomadicContractsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { user } = useApp()
  const { filtersList, handleFilter, initFilters } = useList()
  const { setSearchParams, searchParams } = useFetcher()

  const labels = useMemo(() => {
    const startDate = dayjs(searchParams.get('date_begin') ?? dayjs().startOf('month')).startOf(
      'month'
    )
    const endDate = dayjs(searchParams.get('date_end') ?? dayjs().startOf('month')).endOf('month')
    const monthsDifference = endDate.diff(startDate, 'month')
    return Array.from({ length: monthsDifference + 1 }, (_, i) =>
      startDate.add(i, 'month').format('YYYY-MM-DD')
    )
  }, [searchParams])

  useEffect(() => {
    if (!user) return
    const now = dayjs()
    if (!searchParams.get('date_begin') || !searchParams.get('date_end')) {
      searchParams.set(
        'date_begin',
        now.subtract(23, 'months').startOf('month').format('YYYY-MM-DD')
      )
      searchParams.set('date_end', now.endOf('month').format('YYYY-MM-DD'))
    }
    if (!searchParams.get('center')) {
      searchParams.set('center', String(user.mainCenter))
    }
    initFilters(new Map([['centers', 'center']]), {
      centers: {
        multiple: false,
        default: user?.mainCenter
      }
    }).then()
  }, [initFilters, searchParams, user])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('stats_nomadic_contracts')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(async () => {}, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePeriodPicker
            slugStart="date_begin"
            slugEnd="date_end"
            labelStart={t('begin_date')}
            labelEnd={t('end_date')}
            onChange={() => handleFilter(async () => {}, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersBox>
      </Grid>
      <Grid container spacing={6} alignItems={'stretch'}>
        <Grid item xs={12} sm={12} md={6}>
          <MuiPaper>
            <Box display="flex" justifyContent="start" alignItems="center" gap={4} padding={6}>
              <Typography variant="h3">{t('CA vs BP')}</Typography>
            </Box>
            <CaVsBpChartComponent
              height={280}
              params={searchParams}
              toolbarShow={false}
              labels={labels}
              types={['COWORKING']}
              byLabels={true}
              notIncludeBp={true}
            />
          </MuiPaper>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <MuiPaper>
            <Box display="flex" justifyContent="start" alignItems="center" gap={4} padding={6}>
              <Typography variant="h3">{t('users_associates')}</Typography>
            </Box>
            <NomadOccupationStats height={280} params={searchParams} />
          </MuiPaper>
        </Grid>
      </Grid>
      <Grid container spacing={6} alignItems={'stretch'} sx={{ mt: 0 }}>
        <Grid item xs={12} sm={12} md={6}>
          <MuiPaper>
            <Box display="flex" justifyContent="start" alignItems="center" gap={4} padding={6}>
              <Typography variant="h3">{t('stats_nomadic_contracts_sold')}</Typography>
            </Box>
            <SoldNomadicContractsChart />
          </MuiPaper>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <MuiPaper sx={{ height: '100%', marginBottom: 4 }}>
            <Box display="flex" justifyContent="start" alignItems="center" gap={4} padding={6}>
              <Typography variant="h3">{t('stats_nomads_table')}</Typography>
            </Box>
            <TableNomads center={Number(searchParams.get('center'))} />
          </MuiPaper>
        </Grid>
      </Grid>
    </Container>
  )
}
