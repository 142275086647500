import { Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  CreatePackageFormSchema,
  PackagesClientsRelated,
  PackagesListSchema,
  PackagesVerifyCodeSchema
} from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const packagesRouter = ({ token }: Ctx) => ({
  getRecipientRelated: async (client: number) =>
    fetcher<PackagesClientsRelated>({
      url: `${BASE_URL}${urlHelper(API.PACKAGES_RELATED, { client })}`,
      method: 'GET',
      token: token
    }),
  create: async (data: CreatePackageFormSchema) =>
    fetcher<void>({
      url: `${BASE_URL}${API.PACKAGES}`,
      method: 'POST',
      token: token,
      body: data
    }),
  list: async (params: string) =>
    fetcher<PackagesListSchema>({
      url: `${BASE_URL}${API.PACKAGES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  verifyCode: async (packageId: number, securityCode: string, handoverClient: number) =>
    fetcher<PackagesVerifyCodeSchema>({
      url: `${BASE_URL}${urlHelper(API.PACKAGES_CODE, {
        packageId
      })}?securityCode=${securityCode}&handoverClient=${handoverClient}`,
      method: 'GET',
      token: token
    })
})
