import { useCallback, useMemo, useState } from 'react'
import { Button, FormControl } from '@mui/material'
import { DateRange, DateRangePicker, SingleInputDateRangeField } from '@mui/x-date-pickers-pro'
import dayjs, { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'
import { SetURLSearchParams } from 'react-router-dom'
import { Box } from '@mui/system'

export enum dates {
  TODAY,
  YESTERDAY,
  CURRENT_WEEK,
  NEXT_MONTH,
  CURRENT_MONTH,
  LAST_MONTH,
  LAST_3_MONTHS,
  LAST_6_MONTHS,
  LAST_12_MONTHS,
  ROLLING_12_MONTHS,
  CURRENT_YEAR,
  TWO_YEARS_AGO,
  NEXT_YEAR,
  LAST_YEAR,
  N_PLUS_2
}

interface IRangePickerProps {
  label: string
  slug: string
  onChange?: () => void
  setSearchParams: SetURLSearchParams
  searchParams: URLSearchParams
  valueLabel?: number
  slugs?: { begin: string; end: string }
  isNoDate?: boolean
  ranges?: Array<dates>
  allowCustom?: boolean
}

export function RangePicker({
  label,
  slug,
  onChange,
  searchParams,
  setSearchParams,
  valueLabel,
  slugs,
  isNoDate = true,
  ranges,
  allowCustom = true
}: IRangePickerProps) {
  const { t } = useTranslation()

  // TODO: Add translation on shortcuts labels
  let itemsRange = useMemo(() => {
    let _items: (
      | { label: string; getValue: () => [dayjs.Dayjs, dayjs.Dayjs]; key: number }
      | { label: string; getValue: () => [any, any]; key: number }
    )[] = [
      {
        key: dates.LAST_12_MONTHS,
        label: t('last_12_months'),
        getValue: () => {
          const now = dayjs()
          return [now.subtract(1, 'year').startOf('month'), now.subtract(1, 'month').endOf('month')]
        }
      },
      {
        key: dates.ROLLING_12_MONTHS,
        label: t('rolling_12_months'),
        getValue: () => {
          const now = dayjs()
          return [now.subtract(6, 'months').startOf('month'), now.add(5, 'months').endOf('month')]
        }
      },
      {
        key: dates.CURRENT_MONTH,
        label: t('current_month'),
        getValue: () => {
          const now = dayjs()
          return [now.startOf('month'), now.endOf('month')]
        }
      },
      {
        key: dates.TODAY,
        label: t('today'),
        getValue: () => {
          const now = dayjs()
          return [now.startOf('day'), now.endOf('day')]
        }
      },
      {
        key: dates.YESTERDAY,
        label: t('yesterday'),
        getValue: () => {
          const now = dayjs()
          return [now.subtract(1, 'day').startOf('day'), now.subtract(1, 'day').endOf('day')]
        }
      },
      {
        key: dates.CURRENT_WEEK,
        label: t('current_week'),
        getValue: () => {
          const now = dayjs()
          return [now.startOf('week'), now.endOf('week')]
        }
      },
      {
        key: dates.NEXT_MONTH,
        label: t('next_month'),
        getValue: () => {
          const now = dayjs()
          return [now.add(1, 'month').startOf('month'), now.add(1, 'month').endOf('month')]
        }
      },
      {
        key: dates.LAST_MONTH,
        label: t('last_month'),
        getValue: () => {
          const now = dayjs()
          return [
            now.subtract(1, 'month').startOf('month'),
            now.subtract(1, 'month').endOf('month')
          ]
        }
      },
      {
        key: dates.LAST_3_MONTHS,
        label: t('last_3_months'),
        getValue: () => {
          const now = dayjs()
          return [
            now.subtract(3, 'months').startOf('month'),
            now.subtract(1, 'month').endOf('month')
          ]
        }
      },
      {
        key: dates.LAST_6_MONTHS,
        label: t('last_6_months'),
        getValue: () => {
          const now = dayjs()
          return [
            now.subtract(6, 'months').startOf('month'),
            now.subtract(1, 'month').endOf('month')
          ]
        }
      },
      {
        key: dates.CURRENT_YEAR,
        label: t('current_year'),
        getValue: () => {
          const now = dayjs()
          return [now.startOf('year'), now.endOf('year')]
        }
      },
      {
        key: dates.LAST_YEAR,
        label: t('last_year'),
        getValue: () => {
          const now = dayjs()
          return [now.subtract(1, 'year').startOf('year'), now.subtract(1, 'year').endOf('year')]
        }
      },
      {
        key: dates.TWO_YEARS_AGO,
        label: t('two_years_ago'),
        getValue: () => {
          const now = dayjs()
          return [now.subtract(2, 'years').startOf('year'), now.subtract(2, 'years').endOf('year')]
        }
      },
      {
        key: dates.NEXT_YEAR,
        label: t('next_year'),
        getValue: () => {
          const now = dayjs()
          return [now.add(1, 'year').startOf('year'), now.add(1, 'year').endOf('year')]
        }
      },
      {
        key: dates.N_PLUS_2,
        label: t('year_n_plus_2'),
        getValue: () => {
          const now = dayjs()
          return [now.add(2, 'years').startOf('year'), now.add(2, 'years').endOf('year')]
        }
      }
    ]

    if (ranges !== undefined) {
      _items = _items
        .filter((item) => ranges.includes(item.key))
        .sort((a, b) => ranges.indexOf(a.key) - ranges.indexOf(b.key))
    }
    return _items
  }, [ranges, t])

  const [showCustomDate, setShowCustomDate] = useState(true)
  const handleCustomDate = useCallback(() => {
    setShowCustomDate(!showCustomDate)
  }, [showCustomDate])

  const viewRenderers = useMemo(() => {
    if (showCustomDate) return { day: () => null }
    return undefined
  }, [showCustomDate])

  const defaultValue = useCallback((): DateRange<Dayjs> => {
    let _begin = slugs?.begin ?? `${slug}_begin`
    let _end = slugs?.end ?? `${slug}_end`
    const [startParam, endParam] = [searchParams.get(_begin), searchParams.get(_end)]

    if (startParam && endParam) {
      return [dayjs(startParam), dayjs(endParam)]
    } else if (valueLabel !== undefined) {
      const _itemRange = itemsRange.find((item) =>
        item.key !== undefined ? item.key === valueLabel : false
      )
      if (_itemRange) {
        const value = _itemRange.getValue()
        if (value[0] && value[1]) {
          searchParams.append(_begin, value[0].format('YYYY-MM-DD'))
          searchParams.append(_end, value[1].format('YYYY-MM-DD'))
          return [dayjs(value[0]), dayjs(value[1])]
        }
        return [null, null]
      }
    }

    return [null, null]
  }, [])

  const [value, setValue] = useState<DateRange<Dayjs>>(defaultValue())

  const handleChange = useCallback(
    (newValue: DateRange<Dayjs>) => {
      const [start, end] = newValue

      setValue(newValue)
      setSearchParams((searchParams) => {
        if (start && end) {
          searchParams.set(slugs?.begin ?? `${slug}_begin`, start.format('YYYY-MM-DD'))
          searchParams.set(slugs?.end ?? `${slug}_end`, end.format('YYYY-MM-DD'))
        } else {
          searchParams.delete(slugs?.begin ?? `${slug}_begin`)
          searchParams.delete(slugs?.end ?? `${slug}_end`)
        }
        return searchParams
      })

      if (onChange) {
        onChange()
      }
    },
    [onChange, setSearchParams, searchParams, slug, slugs]
  )

  return (
    <FormControl sx={{ width: 220 }}>
      <DateRangePicker
        label={t(label)}
        slots={{
          field: SingleInputDateRangeField,
          actionBar: () =>
            allowCustom && (
              <Box sx={{ gridRow: 3, p: 6 }}>
                <Button variant="outlined" onClick={handleCustomDate}>
                  {t('custom_date')}
                </Button>
              </Box>
            )
        }}
        value={value}
        onChange={handleChange}
        calendars={2}
        viewRenderers={viewRenderers}
        slotProps={{
          shortcuts: {
            items: itemsRange
          },
          textField: {
            size: 'small' // Add size='small' to the slotProps
          },
          field: {
            clearable: isNoDate
          }
        }}
        localeText={{ start: t('begin'), end: t('end') }}
      />
    </FormControl>
  )
}
