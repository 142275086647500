import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Typography, IconButton } from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import { Container, Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'

import { Appointment } from 'api/models'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { FiltersContainer } from 'app/components/filters/filters-container'
import { useApp } from 'app/providers/app.provider'
import { useNavigate } from 'react-router-dom'
import { formatCustomerAppointmentStatus, formatDateWithTime } from 'app/utils/format'
import { AppointmentActionMenu } from 'modules/appointments/components/appointment-action-menu.component'

import GuestList from 'modules/appointments/components/guest-pop-hover.component'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { dates, RangePicker } from 'app/components/filters/range-picker'

export const AppointmentsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getRight } = useApp()
  const navigate = useNavigate()
  const {
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initSort,
    handleSort,
    handleFilter
  } = useList()
  const { getAppointments, searchParams, setSearchParams } = useFetcher()
  const [appointments, setAppointments] = useState<Appointment[]>([])
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['staff_centers', 'center'],
      ['staffs', 'staff'],
      ['appointment_status', 'status']
    ])
  )

  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      await getAppointments
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setAppointments(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))
    },
    [getAppointments, setIsLast, setOffset, setTotal, searchParams, setSearchParams]
  )

  useEffect(() => {
    initSort([{ property: 'begin', order: 'asc' }]).then(() => refreshList(true).then())
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('appointments_list')}
          {getRight('happening', 'isAdd') ? (
            <IconButton
              title={t('package_add_title')}
              aria-label={t('package_add_title')}
              color="primary"
              onClick={() => navigate('/appointments/add')}
            >
              <AddCircle fontSize="small" />
            </IconButton>
          ) : (
            <></>
          )}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          commonFilters={commonFilters}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          onChange={() => handleFilter(refreshList, true)}
        >
          <SearchClientInput
            slug="client"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            defaultIsEnterprise={false}
            label={t('organiser')}
          />
          <SearchClientInput
            slug="guest"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            defaultIsEnterprise={false}
            isIndividualGuest={true}
            label={t('guest')}
          />
          <RangePicker
            slug="begin"
            label={'begin'}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            valueLabel={dates.CURRENT_MONTH}
          />
        </FiltersContainer>
      </Grid>
      <Grid data-cy="appointments">
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {appointments.length} / {total}
          </Typography>
        </Grid>
        <List
          items={appointments}
          columns={[
            {
              label: '#',
              slug: 'status',
              id: 'id',
              valueFormatter: formatCustomerAppointmentStatus,
              text: 'statusLabel'
              // link: { base: '/appointment', slug: 'id' }
            },
            {
              label: t('organiser'),
              slug: 'organiser',
              link: { base: '/individuals', slug: 'organiserId' }
            },
            {
              label: t('center'),
              slug: 'center'
            },
            { label: t('staff'), slug: 'staff' },
            { label: t('begin'), slug: 'begin', valueFormatter: formatDateWithTime },
            { label: t('end'), slug: 'end', valueFormatter: formatDateWithTime },
            {
              label: t('guests'),
              slug: 'guestsSum',
              condition: (item: Appointment) => <GuestList items={item.guestsSum} />
            },
            {
              label: t('description'),
              slug: 'description'
            },
            {
              label: '',
              slug: 'id',
              unsorted: true,
              condition: (item: Appointment) =>
                item.status !== 2 && <AppointmentActionMenu item={item} refreshList={refreshList} />
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {appointments.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
