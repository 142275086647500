import { z } from 'zod'
import dayjs, { Dayjs } from 'dayjs'

const floorPlanListItemSchema = z.object({
  id: z.number(),
  centerName: z.string(),
  floor: z.number(),
  index: z.string(),
  begin: z.string(),
  end: z.string()
})
export type FloorPlanListItem = z.infer<typeof floorPlanListItemSchema>

const floorPlansListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(floorPlanListItemSchema)
})
export type FloorPlansList = z.infer<typeof floorPlansListSchema>

export const floorPlansCreateSchema = z.object({
  center: z.number({ coerce: true }),
  floor: z.number({ coerce: true }),
  index: z.string(),
  begin: z
    .instanceof(dayjs as unknown as typeof Dayjs)
    .transform((date) => date.format('YYYY-MM-DD'))
})
export type FloorPlansCreate = z.infer<typeof floorPlansCreateSchema>

const floorPlansSchema = z.object({
  id: z.number(),
  centerName: z.string(),
  description: z.string(),
  floor: z.number(),
  version: z.string(),
  begin: z.string(),
  end: z.string(),
  internalImage: z.string(),
  externalImage: z.string()
})
export type FloorPlan = z.infer<typeof floorPlansSchema>

const serviceShapeListItemSchema = z.object({
  id: z.number(),
  floorPlanId: z.number(),
  points: z.string(),
  isOnline: z.boolean(),
  serviceId: z.number(),
  serviceLabel: z.string()
})

export type ServiceShapeListItem = z.infer<typeof serviceShapeListItemSchema>

const serviceShapeListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(serviceShapeListItemSchema)
})
export type ServiceShapeList = z.infer<typeof serviceShapeListSchema>

export const floorPlanEditFormSchema = z.object({
  centerName: z.string().optional(),
  floor: z.number().optional(),
  version: z.string(),
  description: z.string().optional().nullable(),
  begin: z
    .instanceof(dayjs as any)
    .refine(
      (date) =>
        dayjs(date, 'DD/MM/YYYY', true).isValid() || dayjs(date, 'MM/DD/YYYY', true).isValid()
    ),
  end: z
    .instanceof(dayjs as any)
    .refine(
      (date) =>
        dayjs(date, 'DD/MM/YYYY', true).isValid() || dayjs(date, 'MM/DD/YYYY', true).isValid()
    )
    .optional()
    .nullable()
})

export type FloorPlanEditItem = z.infer<typeof floorPlanEditFormSchema>

export const floorPlanDuplicateInputSchema = z.object({
  version: z.string(),
  begin: z
    .instanceof(dayjs as unknown as typeof Dayjs)
    .transform((date) => date.format('YYYY-MM-DD'))
})
export type FloorPlanDuplicateInput = z.infer<typeof floorPlanDuplicateInputSchema>
