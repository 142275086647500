import React from 'react'
import { Grid, Skeleton, Tooltip, useTheme, Card, CardContent, Box } from '@mui/material'
import { FormItem } from 'api/models'
import { Control, UseFormReturn } from 'react-hook-form'
import { FORM_ITEMS, FormInputProps } from './controlled-form.component'
import { TitleComponent } from 'app/components/titles/title.component'

interface IFormCardProps {
  items: Array<FormInputProps>
  control: Control<any>
  title?: string
  isLoading: boolean
  itemXS?: number
  methods?: UseFormReturn<any>
  padding?: number
  children?: React.ReactNode
}

export function FormCard({
  title,
  control,
  items,
  isLoading,
  itemXS,
  methods,
  children
}: IFormCardProps) {
  const theme = useTheme()
  const getFormInput = (item: FormInputProps) => {
    const Item = item.type == 'blank' ? null : FORM_ITEMS[item.type]
    if (item.type == 'blank') {
      return <></>
    }

    let props = {
      name: item.name,
      label: item.label,
      icon: item.icon,
      required: item.required
    } as any

    if (item.formItem) {
      props.formItem = item.formItem || ({} as FormItem)
    }

    return (
      <>
        {item.info}
        {Item && <Item control={control} methods={methods} {...props} {...item.inputProps} />}
      </>
    )
  }

  return (
    <Card
      sx={{ background: theme.palette.background.paper, minHeight: '100%', overflow: 'visible' }}
      data-cy="form-card"
    >
      <CardContent>
        {title && (
          <Box paddingBottom={4}>
            <TitleComponent text={title} variant={'h3'} paddingLeft={12} />
          </Box>
        )}
        <Grid container columns={12} columnSpacing={4}>
          {items.map((item, index) => {
            const xs = item.type !== 'blank' ? item.xs ?? itemXS ?? 6 : itemXS ?? 6
            const sx = item.type !== 'blank' ? item.sx ?? undefined : undefined

            return (
              <Grid
                key={index}
                item
                xs={xs}
                paddingY={isLoading ? 0 : 4}
                sx={sx}
                data-cy="form-item"
              >
                {isLoading ? (
                  <Skeleton height={67} sx={{ margin: 0 }} />
                ) : item.type === 'customComponent' && item.customComponent ? (
                  item.customComponent
                ) : item.type !== 'blank' && item.tooltip ? (
                  <Tooltip title={item.tooltip}>{getFormInput(item)}</Tooltip>
                ) : (
                  getFormInput(item)
                )}
              </Grid>
            )
          })}
        </Grid>
        {children}
      </CardContent>
    </Card>
  )
}
