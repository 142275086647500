import React, { RefObject } from 'react'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { useTranslation } from 'react-i18next'
import { Button, Typography } from '@mui/material'
import { FloorPlan } from 'api/models/floor_plans_management'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import styled from '@emotion/styled'
import { AddPhotoAlternate } from '@mui/icons-material'
import { useMutationFetcher } from 'app/hooks/use-mutation-fetcher'

interface IUpdateImageDialogProps {
  dialogRef: RefObject<DialogRef>
  floorPlan: FloorPlan
  imageKey: 'internalImage' | 'externalImage'
  refetch: () => void
}

export const UpdateImageDialog = function ({
  dialogRef,
  floorPlan,
  imageKey,
  refetch
}: IUpdateImageDialogProps) {
  const { t } = useTranslation()
  const { FloorPlansManagement } = useFetcherV2()

  const upload = useMutationFetcher({
    mutationKey: ['floor-plans-management', 'upload-image'],
    mutationFn: (formData: FormData) =>
      FloorPlansManagement.uploadImage(floorPlan.id, imageKey, formData),
    onSuccess: () => {
      refetch()
    },
    confirm: {
      title: t('update_image'),
      content: t('update_image_confirm_content')
    }
  })

  const deleteImage = useMutationFetcher({
    mutationKey: ['floor-plans-management', 'upload-image'],
    mutationFn: () => FloorPlansManagement.deleteImage(floorPlan.id, imageKey),
    onSuccess: () => {
      dialogRef.current?.close()
      refetch()
    },
    confirm: {
      title: t('delete_image_confirm_title'),
      content: t('delete_image_confirm_content'),
      variant: 'error'
    }
  })

  const onFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData()
    const file = event.target.files?.[0]
    if (file) {
      formData.append('file', file)
      upload(formData)
    }
  }

  return (
    <Dialog
      ref={dialogRef}
      title={t('update_image')}
      maxWidth="sm"
      actions={
        <>
          <Button
            color="error"
            variant="contained"
            disabled={!floorPlan[imageKey]}
            onClick={deleteImage}
          >
            {t('delete_action')}
          </Button>

          <Button component="label" variant="contained" startIcon={<AddPhotoAlternate />}>
            {t('load_image')}
            <VisuallyHiddenInput
              type="file"
              onChange={onFileSelect}
              id="file"
              multiple={false}
              accept="image/*"
            />
          </Button>
        </>
      }
    >
      {floorPlan[imageKey] ? (
        <img src={floorPlan[imageKey]} alt={imageKey} style={{ maxWidth: '100%' }} />
      ) : (
        <Typography variant="subtitle2" color="action">
          {t('no_image')}
        </Typography>
      )}
    </Dialog>
  )
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})
