import { z } from 'zod'
import dayjs from 'dayjs'

const clientRecipientRelated = z.object({
  id: z.number(),
  firstname: z.string(),
  lastname: z.string()
})

export type ClientsRecipientRelated = z.arrayOutputType<typeof clientRecipientRelated>

export const createPackageFormSchema = z.object({
  center: z.number({ coerce: true }),
  receptionDate: z.instanceof(dayjs as any),
  recipient: z.number({ coerce: true }),
  sender: z.string().nullish().optional(),
  type: z.number({ coerce: true })
})

export type CreatePackageFormSchema = z.infer<typeof createPackageFormSchema>

const packageListSchema = z.object({
  id: z.number(),
  recipientId: z.number(),
  recipientFullName: z.string(),
  reference: z.string(),
  siteName: z.string(),
  type: z.number(),
  typeLabel: z.string(),
  status: z.number(),
  statusLabel: z.string(),
  receptionDate: z.string(),
  handoverDate: z.string(),
  handoverClientFullName: z.string(),
  sender: z.string(),
  lastSentInvitationDate: z.string()
})

const packagesListSchema = z.object({
  total: z.number(),
  items: z.array(packageListSchema)
})

const verifyCodeSchema = z.object({
  found: z.boolean()
})

const packagesClientRelated = z.object({
  id: z.number(),
  fullName: z.string()
})

export type PackageListSchema = z.infer<typeof packageListSchema>
export type PackagesListSchema = z.infer<typeof packagesListSchema>
export type PackagesVerifyCodeSchema = z.infer<typeof verifyCodeSchema>
export type PackagesClientsRelated = z.arrayOutputType<typeof packagesClientRelated>
