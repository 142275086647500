import * as React from 'react'
import { Box, Chip, Popover, Typography, Card, CardContent } from '@mui/material'
import { formatGuestInvitationStatus } from 'app/utils/format'

interface GuestListProps {
  items: { id: number; name: string; status: number; statusLabel: string }[] | string
  maxVisibleGuests?: number
}

const GuestList: React.FC<GuestListProps> = ({ items, maxVisibleGuests = 2 }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const parsedItems = typeof items === 'string' ? JSON.parse(`[${items}]`) : items

  if (parsedItems === null || parsedItems === undefined) return null

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        gap: 1
      }}
      onClick={maxVisibleGuests < parsedItems.length ? handleClick : undefined}
    >
      <Card
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          maxWidth: 250,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {parsedItems
          .slice(0, maxVisibleGuests)
          .map((item: { id: number; name: string; status: number; statusLabel: string }) => (
            <Chip
              key={item.id}
              size="small"
              icon={formatGuestInvitationStatus(item.status, item.statusLabel, item.status)}
              label={`${item.name}`}
            />
          ))}
      </Card>

      {parsedItems.length > maxVisibleGuests && (
        <Typography variant="body2">... +{parsedItems.length - maxVisibleGuests}</Typography>
      )}

      <Popover
        id="click-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Card sx={{ maxWidth: 300, background: 'transparent' }}>
          <CardContent sx={{ padding: '5px !important' }}>
            {parsedItems.map(
              (item: { id: number; name: string; status: number; statusLabel: string }) => (
                <Chip
                  key={item.id}
                  icon={formatGuestInvitationStatus(item.status, item.statusLabel, item.status)}
                  label={`${item.name}`}
                  size="small"
                  sx={{ m: 0.5 }}
                />
              )
            )}
          </CardContent>
        </Card>
      </Popover>
    </Box>
  )
}

export default GuestList
