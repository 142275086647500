import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { FormItem, FormItems } from 'api/models'
import { useApp } from 'app/providers/app.provider'
import { FormCard } from 'app/components/form/form-card.component'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { useFeedback } from 'app/providers/feedback.provider'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router-dom'
import { AppointmentForm, appointmentFormSchema } from 'api/models/forms/appointments'
import { AddGuestModal } from 'modules/appointments/components/guest-add.component'

export const AppointmentAddView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { handleMutation } = useFeedback()
  const { getFormItems, createAppointment } = useFetcher()
  const { user } = useApp()
  const methods = useForm<AppointmentForm>({
    defaultValues: {
      center: user?.mainCenter,
      beginDate: dayjs().startOf('hour'),
      endDate: dayjs().startOf('hour').add(1, 'hour'),
      description: '',
      staff: user?.id
    },
    mode: 'onChange',
    resolver: zodResolver(appointmentFormSchema)
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formItems, setFormItems] = useState({} as FormItems)
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([['centers', 'center']])
  )

  const initOptions = useCallback(async (commonOptions: Map<string, string>) => {
    await getFormItems.mutateAsync(Array.from(commonOptions.keys() as any)).then((optionsData) => {
      setFormItems(optionsData as FormItems)
      setIsLoading(false)
    })
  }, [])

  const handleSubmit = useCallback(
    async (data: AppointmentForm) => {
      await handleMutation({
        confirm: {
          content: t('confirm_create_appointment')
        },
        mutation: createAppointment,
        data: data,
        toastSuccess: t('appointment_created'),
        toastError: t('appointment_error'),
        onSuccess: () => {
          navigate(`/appointments`)
        }
      })
    },
    [createAppointment]
  )

  useEffect(() => {
    initOptions(commonOptions).then()
    const sub = methods.watch(async (data, { name }) => {
      if (name === 'beginDate' && data.endDate.isBefore(data.beginDate)) {
        methods.setValue('endDate', data.beginDate.add(1, 'hour'))
        return
      }
      if (name === 'endDate' && data.endDate.isBefore(data.beginDate)) {
        methods.setValue('beginDate', data.endDate.subtract(1, 'hour'))
        return
      }
      if ((name === 'beginDate' || name === 'endDate') && data.endDate.isSame(data.beginDate)) {
        methods.setValue('endDate', data.beginDate.add(5, 'minute'))
        return
      }
    })
    return () => sub.unsubscribe()
  }, [methods])

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('add_appointment')}
        </Typography>
      </Box>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <FormCard
            items={[
              {
                type: 'clients',
                label: t('individual'),
                name: 'organiser',
                required: true
              },
              {
                type: 'select',
                label: t('center'),
                name: 'center',
                formItem: formItems.centers as FormItem,
                required: true
              },
              {
                type: 'guests',
                label: t('guests'),
                name: 'guests',
                required: true
              },
              {
                type: 'customComponent',
                customComponent: <AddGuestModal />,
                label: 'Add Guest',
                name: 'addGuest'
              },
              {
                type: 'datetimepicker',
                label: t('begin'),
                name: 'beginDate',
                required: true
              },
              {
                type: 'datetimepicker',
                label: t('end'),
                name: 'endDate',
                required: true
              },
              {
                type: 'textfield',
                label: t('description'),
                name: 'description',
                xs: 12,
                inputProps: { multiline: true, rows: 2 }
              }
            ]}
            control={methods.control}
            isLoading={isLoading}
          />
        </Grid>
        <Grid sx={{ marginTop: 4 }} container columns={12} columnSpacing={4}>
          <Grid item xs={6} textAlign={'right'}>
            <Button
              variant={'outlined'}
              color={'primary'}
              onClick={() => navigate(`/appointments`)}
            >
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item xs={6} textAlign={'left'}>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={() => {
                methods.setValue('organiser', Number(methods.getValues().organiser))
                methods.handleSubmit(handleSubmit)()
              }}
            >
              {t('save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
