export const BASE_URL = process.env.REACT_APP_API_URL
export const BASE_CLIENT_URL = process.env.REACT_APP_CLIENT_URL
export const FORGOTTEN_TOKEN = process.env.REACT_APP_FORGOTTEN_TOKEN

export const API = {
  // Documents
  DOCUMENT: '/documents/:id',
  DOCUMENT_INVALIDATE: '/documents/:id/invalidate',
  DOCUMENT_VALIDATE: '/documents/:id/validate',
  // Aircall
  AIRCALL_CALL: '/phone-systems/click-to-call',
  // Accounting
  ACCOUNTING_CLIENTS: '/accountingclients',
  ACCOUNTING_CLIENTS_EXPORT: '/accountingclients/export',
  ACCOUNTING_CLIENTS_EXPORT_SAGE: '/accountingclients/exportsage',
  ACCOUNTING_DOCUMENTS: '/accountingdocuments',
  ACCOUNTING_PAYMENTS: '/accountingpayments',
  ACCOUNTING_PAYMENTS_EXPORT: '/accountingpayments/export',
  ACCOUNTING_VARIOUS_OPERATIONS: '/accountingvariousoperations',
  ACCOUNTING_VARIOUS_OPERATIONS_EXPORT: '/accountingvariousoperations/export',
  ACCOUNTING_MATCHING_TRANSFERS_EXCEPTIONS: '/matchingtransfersexception',
  ACTIVITIES: '/activities',
  TYPED_ACTIVITIES: '/:type/:id/activities',
  ACCOUNTING_DOCUMENT: '/accounting_documents',
  // Appointments
  APPOINTMENTS: '/appointments',
  APPOINTMENTS_CANCEL: '/appointments/:id/cancel',
  APPOINTMENTS_SEND_REMINDER: '/appointments/:id/reminder',
  APPOINTMENTS_LAST_SENT_INVITATION: '/appointments/:id/last-sent-invitation',
  APPOINTMENT_CONFIRM_PRESENCE: '/appointments/:id/confirm-presence',
  // Assets
  ASSETS: '/assets/:type/:id',
  ASSET: '/assets/:type/:id/:imageId',
  // Attributions
  ATTRIBUTIONS_LOGS: '/attributions/logs',
  // Auth
  SIGN_IN: '/connection',
  FORGOTTEN_PASSWORD: '/forgotten-password',
  //Center Price
  CENTER_PRICES: '/centerprices',
  CENTER_PRICES_BLOCKED_PRICES: '/centerprices/:id/blocked-prices',
  //Companies
  COMPANIES: '/companies',
  COMPANY: '/companies/:id',
  COMPANY_CENTERS: '/companies/:id/centers',
  COMPANY_CONFIG: '/companies/:id/configuration',
  POST_COMPANY: '/companies/company',
  COMPANY_BANK_ACCOUNT: '/companies/bank-account/:id',
  COMPANY_LIST_BANK_ACCOUNTS: '/companies/:id/bank-account',
  // Enterprises
  ENTERPRISES: '/enterprises',
  DUPLICATE_ENTERPRISES: '/enterprises/duplicate',
  ENTERPRISE_CONTRACTS: '/enterprises/:id/contracts',
  ENTERPRISE_DOCUMENTS: '/enterprises/:id/documents',
  ENTERPRISE_EVENTS: '/enterprises/:id/events',
  ENTERPRISE_MEMBERS: '/enterprises/:id/members',
  ENTERPRISE_OPPORTUNITIES: '/enterprises/:id/opportunities',
  ENTREPRISE_PRESCRIBER: '/enterprises/:id/prescribers/:prescriberId',
  ENTERPRISE_SSIDS: '/enterprises/:id/ssids',
  ENTERPRISE_SSIDS_USERS: '/enterprises/:id/ssids/:ssid/users',
  CLIENTS: '/clients',
  ENTERPRISE_MEMBER_LINK: '/enterprises/:id/members/link',
  ENTERPRISE_PRESCRIBERS: '/enterprises/:id/prescribers',
  ENTERPRISE_SSID_USER: '/enterprises/:id/ssids/:ssid/users/:ssidUser',
  UPLOAD_ENTERPRISE_DOCUMENT: '/enterprises/:id/documents/upload',
  GET_ENTERPRISES_EXPORT: '/enterprises/export',
  ENTERPRISE_ACCOUNTING: '/enterprises/:id/accounting',
  // Email Templates
  EMAIL_TEMPLATES: '/email-templates',
  EMAIL_TEMPLATE: '/email-templates/:id',
  // Contracts
  CONTRACT_LINK: '/contracts/:id/link',
  CONTRACT_USERS: '/contracts/:id/users',
  CONTRACTS_CHANGE_SERVICE: '/contracts/:id/services/:contractServiceId/change-service',
  CONTRACTS: '/contracts',
  CONTRACT: '/contracts/:id',
  CONTRACT_READ: '/contracts/:id',
  CONTRACT_MAIN: '/contracts/:id/main',
  CONTRACT_OPTIONS: '/contracts/:id/options',
  CONTRACT_CONSUMPTIONS: '/contracts/:id/consumptions',
  CONTRACT_ENDORSEMENTS: '/contracts/:id/endorsements',
  CONTRACT_AUTORENEWAL_ENDORSEMENTS: '/contracts/:id/endorsements-auto',
  CONTRACT_INVOICES: '/contracts/:id/invoices',
  CONTRACT_INDEXING_ANNIVERSARY: '/contracts/:id/indexing',
  CONTRACT_LITIGATIONS: '/contracts/:id/litigations',
  CONTRACT_PAYMENTS: '/contracts/:id/payments',
  CONTRACT_SELECT_OPTIONS: '/contracts/:id/select-options',
  CONTRACT_OCCUPANT: '/contracts/:id/update-occupant',
  CONTRACT_SUM: '/contracts/sum',
  CONTRACT_EXPORT: '/contracts/export',
  CONTRACTS_OPTIONS: '/contracts-options',
  CONTRACTS_OPTIONS_SUM: '/contracts-options/sum',
  CONTRACT_PDF: '/contracts/:id/pdf',
  CONTRACTS_CONSUMPTIONS: '/contracts/consumptions',
  CONTRACTS_CONSUMPTIONS_SUM: '/contracts/consumptions/sum',
  CLIENT_CONTRACTS: '/clients/:id/contracts',
  CONTRACT_ADMIN: '/contracts/:id/admin-update',
  CONTRACT_AGREE: '/contracts/:id/agree',
  CONTRACT_CANCEL: '/contracts/:id/cancel',
  CONTRACT_LITIGATION: '/contracts/:id/litigation',
  CONTRACT_VALIDATE: '/contracts/:id/validate',
  CONTRACT_INVOICE_PARTIAL: '/contracts/:id/invoices/partial',
  CONTRACT_SERVICES: '/contracts/:id/services',
  CONTRACT_SERVICE: '/contracts/:id/services/:serviceId',
  STOP_CONTRACT_SERVICE: '/contracts/:id/services/:serviceId/stop',
  //Contact
  CONTACTS: '/contacts',
  CONTACTS_EXPORT: '/contacts/export',

  //Dashboard
  DASHBOARD_CONTRACTS: '/dashboard/contracts',
  DASHBOARD_OCCUPATIONS: '/dashboard/occupations',
  DASHBOARD_VISITS: '/dashboard/visits',
  DASHBOARD_NEXT_ENTRIES: '/dashboard/next_entries',
  DASHBOARD_MEETINGS: '/dashboard/meetings',
  DASHBOARD_OPEN_DESKTOPS: '/dashboard/open_desktops',
  DASHBOARD_OTHER_INFORMATION: '/dashboard/other_information',
  DASHBOARD_APPOINTMENTS: '/dashboard/appointments',
  // Filters
  FILTERS: '/filters',
  CLIENTS_FILTER: '/filter_clients',
  CLIENT_CONSUMPTIONS: '/clients/:id/consumptions',
  CENTER_FILTER: '/filter_centers',
  CENTER_FILTER_SELECT: '/filter_centers/select',
  CENTER_VISOR_GROUPS: '/centers/:id/visor-groups',
  BUSINESS_PLAN: '/centers/bp',
  //Visor
  CENTERS_VISORS: '/centers/visor',
  STAFF_FILTER: '/filter_staffs',
  WIFI_FILTER_STATIONS: '/filter_wifi/stations',
  WIFI_FILTER_SSIDS: '/filter_wifi/ssid',
  ENTERPRISE_MEMBERS_FILTER: '/enterprises/:id/filter_members',
  VISOR_ROOMS: '/centers/:id/visor-rooms',
  //Form Items
  FORM_ITEMS: '/formitems',
  // Invoices
  INVOICES: '/invoices',
  INVOICES_EXPORT: '/invoices/export',
  INVOICES_INFORMATIONS: '/invoices/:id',
  INVOICES_PDF: '/invoices/:id/pdf',
  INVOICE_SUM: '/invoices/sum',
  INVOICES_ACTIVITIES: '/invoices/:id/activities',
  INVOICES_REGENERATE: '/invoices/:id/regeneratepdf',
  PATCH_INVOICES_ACCOUNT: '/invoices/:id/account',
  PATCH_INVOICES_UNACCOUNT: '/invoices/:id/unaccount',
  PATCH_INVOICES_BLOCK: '/invoices/:id/block',
  PATCH_INVOICES_UNBLOCK: '/invoices/:id/unblock',
  PATCH_INVOICES_LITIGATION: '/invoices/:id/litigation',
  INVOICES_UNCOLLECTIBLE: '/invoices/:id/uncollectible',
  INVOICES_MANUAL_REMINDER: '/invoices/:id/manual-reminder',
  INVOICES_ROWS: '/invoices/:id/rows',
  INVOICES_DISCOUNT: '/invoices/:id/discount',
  PATCH_INVOICES_LANGUAGE: '/invoices/:id/language',
  INVOICES_MANUAL_BANK_DEBIT: '/invoices/:id/manualbankdebit',
  INVOICES_ADD_PAYMENT: '/invoices/:id/addpayment',
  INVOICES_LINK_PAYMENT: '/invoices/:id/payments/:payment/link_payment',
  INVOICES_UNLINK_PAYMENT: '/invoices/:id/payments/:payment/unlink_payment',
  DELETE_INVOICES_UNLINK_CREDIT: '/invoices/:id/credits/:credit/unlink_credit',
  INVOICES_CANCELLED_PAYMENT: '/invoices/:id/payments/:payment/cancelledgc',
  INVOICES_PAYMENTS: '/invoices/:id/payments',
  INVOICES_CREDITS: '/invoices/:id/credits',
  PATCH_INVOICES_DUE_DATE: '/invoices/:id/due-date',
  // Options
  OPTIONS: '/options',
  // Centers
  CENTERS: '/centers',
  CENTER: '/centers/:id',
  CLUSTERS: '/clusters',
  CLUSTER: '/clusters/:id',
  // Credits
  CREDITS: '/credits',
  CREDIT_SUM: '/credits/sum',
  CREDIT_EXPORT: '/credits/export',
  CREDIT_INFORMATION: '/credits/:id',
  CREDIT_PDF: '/credits/:id/pdf',
  CREDIT_REGENERATE_PDF: '/credits/:id/regeneratepdf',
  CREDIT_INVOICE_SELECT: '/credits/:id/invoices-select',
  CREDIT_APPLY_INVOICE: '/credits/:id/apply-on-invoice',
  CREDIT_DECLARE_PAYMENT: '/credits/:id/declare-payment',
  CREDIT_INVOICE: '/credits/:id/invoices',
  CREDIT_PAYMENT: '/credits/:id/payments',
  PATCH_CREDIT_LANGUAGE: '/credits/:id/language',
  CREDIT_ACTIVITIES: '/credits/:id/activities',
  // Crypt
  ENCRYPT: '/cryptology/encrypt',
  DECRYPT: '/cryptology/decrypt',
  //Service occupancy
  SERVICES_AVAILABLE: '/services/available',
  SERVICE_AVAILABILITY: '/services/:id/availability',
  SERVICE_COMMITMENT_PRICE: '/services/:id/calculate-price',
  SERVICES_AVAILABLE_DESKTOPS: '/services/available-desktops',
  SERVICE_OCCUPANCY: '/main-services/occupancy',
  SERVICE_OCCUPANCY_SUM: '/main-services/occupancy/sum',
  SERVICE_OCCUPANCY_EXPORT: '/main-services/occupancy/export',
  SERVICE_PRICING: '/services/:id/pricing',
  SERVICE_CONSUMPTIONS_PRICING: '/services/:id/pricing-consumption',
  CATALOG_MAIN_SERVICES: '/services/main',
  // Rights
  RIGHTS: '/rights',
  //Discounts
  DISCOUNTS: '/discounts',
  DISCOUNT: '/discounts/:id',
  DISCOUNTS_EXIST: '/discounts/exist',
  // Discount grid
  DISCOUNT_GRIDS: '/discount-grids',
  DISCOUNT_GRID: '/discount-grids/:id',
  // Discount grid center
  DISCOUNT_GRIDS_CENTER: '/center-discount-grids',
  //Litigations
  CLOSE_LITIGATION: '/litigations/:id/close',
  LITIGATIONS: '/litigations',
  LITIGATION: '/litigations/:id',
  LITIGATION_CONTRACTS: '/litigations/:id/contracts',
  LITIGATION_INVOICES: '/litigations/:id/invoices',
  //Opportunities
  OPPORTUNITIES: '/opportunities',
  GET_OPPORTUNITIES_EXPORT: '/opportunities/export',
  OPPORTUNITY: '/opportunities/:id',
  OPPORTUNITY_CONTRACTS: '/opportunities/:id/contracts',
  OPPORTUNITY_EVENTS: '/opportunities/:id/events-list',
  OPPORTUNITY_SOLUTIONS: '/opportunities/:id/quotations',
  OPPORTUNITY_USERS: '/opportunities/:id/users',
  OPPORTUNITY_QUOTATION_CREATE: '/opportunities/:id/quotation-create',
  OPPORTUNITY_ATTRIBUTION: '/opportunity-attribution',
  OPPORTUNITY_ATTRIBUTION_LOGS: '/opportunity-attribution/logs',
  ACTUAL_PIPELINE: '/opportunities/pipeline',
  //Individuals
  INDIVIDUALS: '/individuals',
  ADD_INDIVIDUALS: '/individuals/add',
  INDIVIDUAL_ACCESS: '/individuals/:id/access',
  INDIVIDUAL_CONSUMPTIONS: '/individuals/:id/consumptions',
  INDIVIDUAL_DEVICES: '/individuals/:id/devices',
  UPDATE_INDIVIDUAL_DEVICES: '/individuals/devices/:id',
  INDIVIDUAL_ENTERPRISES: '/individuals/:id/enterprises',
  INDIVIDUAL_EVENTS: '/individuals/:id/events',
  UPDATE_INDIVIDUAL_CLIENTLINK: '/individuals/:id/clientlink/:clientId',
  INDIVIDUAL_INVOICES: '/individuals/:id/invoices',
  GET_INDIVIDUAL_INVOICES: '/individuals/:id/invoices',
  PATCH_INDIVIDUAL: '/individuals',
  PATCH_INDIVIDUAL_ACCESS: '/individuals/:id/access',
  POST_INDIVIDUAL: '/individuals',
  POST_INDIVIDUAL_ACCESS: '/individuals/:id/access',
  PUT_INDIVIDUAL: '/individuals',
  GET_INDIVIDUALS_EXPORT: '/individuals/export',
  // Checkouts
  CHECKOUTS: '/checkouts',
  CHECKOUT_SUM: '/checkouts/sum',
  // Mandates
  MANDATES: '/paymentmethods',
  MANDATE_PAYMENT_METHOD: '/paymentmethods/:id/paymentmethod',
  MANDATE_BANK_ACCOUNT: '/paymentmethods/:id/bankaccount',
  MANDATE_PAYMENT: '/paymentmethods/:id/payments',
  MANDATE_CANCELLED: '/paymentmethods/:id/cancelledgc',
  // Services
  MAIN_SERVICES: '/services-main',
  MAIN_SERVICE: '/services-main/:id',
  COMPUTED_PRICE: '/services-main/compute-price',
  MAIN_SERVICES_SUM: '/services-main/sum',
  MAIN_TEMPLATE_SPREADSHEET: '/services-main/template-spreadsheet',
  OPTION_SERVICE: '/services-option/:id',
  OPTION_SERVICES: '/services-option',
  PARKING_SERVICES: '/services-parkings',
  PARKING_SERVICE: '/services-parkings/:id',
  CHECK_ADD_MANY_SERVICE_EXCEL: '/services-main/check-add-many-excel',
  POST_MANY_MAIN_SERVICES: '/services-main/add-many',
  // Services Types
  SERVICES_TYPES: '/services-types',
  SERVICES_TYPES_ACCOUNTING: '/services-types/accounting',
  // Prescribers
  PRESCRIBERS: '/prescribers',
  //Plannings
  PLANNINGS_DESKTOPS: '/schedules/desktops',
  PLANNINGS_DESKTOPS_FLOORS: '/schedules/desktops/floors',
  // Payments
  PAYMENTS: '/payments',
  PAYMENTS_SUM: '/payments/sum',
  PAYMENT: '/payments/:id',
  // PRICER
  PRICER_INFORMATIONS: '/opportunities/:id/pricer-informations',
  PRICER_SERVICES: '/opportunities/:id/pricer-services',
  // Configuration
  CONFIGURATION_DISCOUNT_GRIDS: '/configuration/discount-grids',
  //Quotations
  QUOTATIONS: '/quotations',
  QUOTATION_SOLUTIONS: '/quotations/:id/solutions',
  QUOTATION_DOWNLOAD: '/quotations/:id/download',
  PATCH_QUOTATION_UPDATE: '/quotations/:id/update',
  GET_QUOTATIONS_EXPORT: '/quotations/export',
  PATCH_QUOTATION_CANCEL: '/quotations/:id/cancel',
  PATCH_QUOTATION_SOLUTION: '/quotations/:id/solutions/:solutionId',
  PATCH_QUOTATION_SOLUTION_PRICE: '/quotations/:id/solutions/:solutionId/price',
  POST_QUOTATION_SEND: '/quotations/:id/send',
  POST_QUOTATION_SOLUTION_TRANSFORM: '/quotations/:id/solutions/:solutionId/transform',
  QUOTATION_SOLUTION_SELECT_COMMITMENTS: '/quotations/:id/solutions/:solutionId/commitments-select',
  //Consumptions
  CONSUMPTIONS: '/consumptions',
  CONSUMPTION: '/consumptions/:id',
  CONSUMPTION_CONTRACT: '/consumption-contracts/:id',
  CONSUMPTION_CONTRACT_CONSUMPTIONS: '/consumption-contracts/:id/consumptions',
  // Indexing
  INDEXING: '/indexing',
  // Endorsements
  ENDORSEMENTS: '/endorsements',
  ENDORSEMENT_SELECT_COMMITMENTS: '/endorsements/:id/commitments-select',
  ENDORSEMENT_SEND_TO_DIRECTION: '/endorsements/:id/send_to_direction',
  ENDORSEMENT_VALIDATE: '/endorsements/:id/validate',
  PATCH_ENDORSEMENT_SERVICE: '/endorsements/:id/add_service',
  // Client center
  CLIENT_CENTERS: '/clients/:id/client_center',
  PATCH_CLIENT_CENTER: '/clients/:id/client_center/:clientCenterId',
  PATCH_CLIENT_CENTER_MAIL: '/clients/:id/client_center/:clientCenterId/accouting_mail',
  //Guests
  GUESTS: '/clients/guest',
  // Client service
  CLIENT_REQUEST: '/clientrequests/:id',
  CLIENT_REQUESTS: '/clientrequests',
  CLIENT_REQUESTS_SUM: '/clientrequests/sum',
  // Customer reservations
  CUSTOMER_RESERVATIONS: '/customer_reservations',
  CUSTOMER_RESERVATION: '/customer_reservations/:id',
  CUSTOMER_RESERVATION_CONTRACTS: '/customer_reservations/:id/contracts-select',
  CUSTOMER_RESERVATION_INDIVIDUALS: '/customer_reservations/:id/individuals-select',
  CUSTOMER_RESERVATION_SERVICES: '/customer_reservations/:id/services',
  CUSTOMER_RESERVATIONS_SUM: '/customer_reservations/sum',
  PATCH_CUSTOMER_RESERVATION_CANCEL: '/customer_reservations/:id/cancel',
  PATCH_CUSTOMER_RESERVATION_CONFIRM: '/customer_reservations/:id/confirm',
  PATCH_CUSTOMER_RESERVATION_UPDATE: '/customer_reservations/:id/update',
  POST_CUSTOMER_RESERVATION_SERVICE: '/customer_reservations/:id/services/:serviceId',
  POST_CUSTOMER_RESERVATION_LINK_TO_ACCESS: '/customer_reservations/:id/link-to-access',
  //Events
  EVENTS: '/events',
  PATCH_EVENT: '/events/:id',
  //Happening
  HAPPENINGS: '/happenings',
  HAPPENING: '/happenings/:id',
  HAPPENINGS_PARTNERS: '/happenings/partner',

  // Floor plans
  FLOOR_PLANS: '/floorplans',
  LIST_FLOORS: '/floorplans/:center/listfloors',
  FILTER_CONTRACTS: '/filter_activecontracts',
  CONTRACTS_FLOORS: '/floorplans/:floorplan/listactivecontracts',
  FLOOR_PLANS_MANAGEMENT: '/floor-plans-management',
  FLOOR_PLANS_MANAGEMENT_CREATE: '/floor-plans-management',
  FLOOR_PLANS_MANAGEMENT_DUPLICATE: '/floor-plans-management/:id/duplicate',
  FLOOR_PLANS_MANAGEMENT_READ: '/floor-plans-management/:id',
  FLOOR_PLANS_MANAGEMENT_SERVICE_SHAPES: '/floor-plans-management/:id/services-shapes',
  FLOOR_PLANS_MANAGEMENT_UPLOAD_IMAGE: '/floor-plans-management/:id/upload-image/:type',
  FLOOR_PLANS_MANAGEMENT_DELETE_IMAGE: '/floor-plans-management/:id/delete-image/:type',
  // Clients
  CLIENT_CONTRACT_CONSUMPTIONS: '/clients/:id/contracts-consumptions',
  // Monitoring
  MESSAGES: '/messages',
  AUTHENTIFICATION_RADIUS: '/authentifications_client',
  // Notifications
  NOTIFICATIONS: '/notifications',
  UPDATE_NOTIFICATION: '/notifications/:id',
  //ToS
  TERMS_OF_SALES: '/:type',
  TERM_OF_SALES: '/:type/:id',
  LIST_TERM_OF_SALES: '/term-of-sales',
  //Sources
  SOURCES: '/sources',
  SOURCE_ACQUISITION_COSTS: '/acquisition_costs',
  UPDATE_SOURCE_ACQUISITION_COST: '/acquisition_costs/:id',
  //Staffs
  STAFFS: '/staff',
  STAFF: '/staff/:id',
  UPDATE_STAFF_LANGUAGE: '/staff/:id/language',
  UPDATE_STAFF_IS_ATTRIBUTABLE: '/attributions/staff/:id',
  DEFAULT_STAFF_ATTRIBUTABLE: '/attributions/staff/default',
  STAFF_PHONE_SYSTEM: '/staff/:id/phone-system-staff',
  ATTRIBUTION_STAFFS: '/attributions/staff',
  // Stats
  STATS_ACQUISITIONS: '/stats/acquisitions',
  STATS_RATE_OCCUPANCY_GLOBAL: '/stats-rate-occupancy-global',
  STATS_CENTER_ACQUISITIONS: '/stats/center-production',
  STATS_STAFF_ACQUISITIONS: '/stats/staff-production',
  STATS_CHANNELS: '/stats/channels',
  STATS_CLIENT_REQUEST: '/stats/clientrequest',
  STATS_INVENTORY: '/stats/inventory',
  STATS_OCCUPATION: '/stats/occupation',
  STATS_OCCUPATION_EXPORT: '/stats/occupation/export',
  STATS_PERFORMANCE: '/stats/performances',
  STATS_SURFACE: '/stats/pricepersurface',
  STATS_CA: '/stats/revenue',
  STATS_CONTRACTS_DOM_DURATION: '/stats/domiciliation-agreement',
  STATS_ACCOUNTING: '/stats/accounting',
  STATS_CA_VS_BP: '/stats/revenue/ca-vs-bp',
  STATS_PARKING_OCCUPIED: '/stats/price-parking',
  STATS_PARKINGS_OCCUPATION: '/stats/parking-occupation',
  STATS_PARKINGS_OCCUPATION_CLIENTS: '/stats/parking-occupation/clients',
  STATS_CONTRACTS_NOMADIC_SOLD: '/stats/contracts/nomadic/sold',
  STATS_USERS_NOMADIC_OCCUPATION: '/stats/users-nomadic-occupation',
  STATS_MONTHLY_CA_VS_BP: '/stats/revenue/array-monthly-result-ca-bp-to',
  GET_STATS_CA_BY_TYPES_BY_CENTERS: '/stats/revenue/ca-by-types-by-centers',
  STATS_NOMADS_OCCUPATION_CLIENTS: '/stats/nomad-occupation/clients',
  STATS_DOMICILIATION_AVERAGE: '/stats/domiciliation-average',
  //Wifi
  WIFI_CLIENTS: '/wifi/clients',
  WIFI_LOGS: '/wifi/logs',
  // Tertiaries
  TERTIARIES: '/tertiaries',
  //Typologies
  TYPOLOGIES: '/typologies',
  // Commitments
  COMMITMENTS_LIST: '/commitments',
  // Center discounts
  CENTER_DISCOUNTS: '/center-discounts',
  CENTER_DISCOUNTS_NOT_ACTIVE: '/center-discounts/not-active',
  // Presences
  PRESENCES_LOGS: '/presences/logs',
  PRESENCES_USERS: '/presences/users',
  PRESENCES_SCHEDULE: '/presences/schedule',
  PRESENCES_CONSUMPTION: '/presences/consumption',
  PRESENCES_CONSUMPTION_SUM: '/presences/consumption/sum',
  // Packages
  PACKAGES_RELATED: '/packages/recipient-related/:client',
  PACKAGES: '/packages',
  PACKAGES_CODE: '/packages/verify-code/:packageId',
  //Rules
  PROCEDURE_RULES: '/rules-of-procedure',
  // Client relation
  CLIENT_RELATION: '/client-relation/:client',
  CLIENT_RELATION_FILTER: '/client-relation/filter/:client'
}
