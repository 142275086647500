import { User, Module, Language } from 'api/models'
import usePersisState from 'app/hooks/use-persist-state'
import React, { useContext, createContext, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import { RolesEnum } from 'app/constants/roles'

interface AppContextProps {
  user: User | null
  Accent: any
  setUser: (user: User | null) => void
  purge: () => void
  getModule: (label: string) => Module | undefined
  getRight: (label: string, action: string) => boolean
  getRights: (permissions: string[]) => boolean
  isRole: (role: RolesEnum) => boolean
  getLanguages: () => Language[]
  getHasClickToCall: () => boolean
}

const AppContext = createContext<AppContextProps>({
  user: null,
  Accent: () => {},
  setUser: () => {},
  purge: () => {},
  getModule: () => undefined,
  getRight: () => false,
  getRights: () => false,
  isRole: () => false,
  getLanguages: () => [],
  getHasClickToCall: () => false
})

interface IAppProviderProps {
  children: React.ReactNode
}

const AppProvider = ({ children }: IAppProviderProps): React.JSX.Element => {
  const [user, setUser, purge] = usePersisState<User | null>('user', null)

  const getModule = useCallback(
    (key: string): Module | undefined => {
      if (user && user.modules && user.modules.length > 0) {
        return user.modules.find((module) => module.key === key)
      }
      return undefined
    },
    [user]
  )

  const getRight = useCallback(
    (key: string, action: string): boolean => {
      if (user && user.rights && Object.keys(user.rights).length > 0) {
        let _key = Object.keys(user!.rights).find((k) => k === key)
        if (undefined === _key) {
          return false
        }
        // @ts-ignore
        const _right = user!.rights[_key]
        const _action = Object.keys(_right).find((a: string) => a === action)

        if (undefined === _action) {
          return false
        }

        return Boolean(_right[_action])
      }
      return false
    },
    [user]
  )

  const getRights = useCallback(
    (permissions: string[]): boolean => {
      for (let i = 0; i < permissions.length; i++) {
        const [key, action] = permissions[i].split('.')
        if (!getRight(key, action)) {
          return false
        }
      }
      return true
    },
    [getRight, user]
  )

  const isRole = useCallback((role: RolesEnum): boolean => user?.role === role, [user])

  const getLanguages = useCallback(() => user?.languages ?? [], [user])

  const getHasClickToCall = useCallback(() => user?.hasClickToCall ?? false, [user])

  const Accent = styled.span`
    color: ${(props) => props.theme.palette.primary.light};
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
  `

  const values = useMemo(() => {
    return {
      user,
      Accent,
      setUser,
      purge,
      getModule,
      getRight,
      getLanguages,
      isRole,
      getRights,
      getHasClickToCall
    }
  }, [user, Accent, setUser, purge, getModule, getRight, getLanguages, isRole, getRights])

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>
}

const useApp = () => useContext(AppContext)

export { AppProvider, useApp }
