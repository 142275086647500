import { z } from 'zod'

export const clientRelationFilterSchema = z.object({
  name: z.string().optional()
})

export type ClientRelationFilter = z.infer<typeof clientRelationFilterSchema>

export const createClientRelationSchema = z.object({
  client: z.number()
})

export type CreateClientRelation = z.infer<typeof createClientRelationSchema>

const clientRelationListSchema = z.object({
  id: z.string(),
  firstname: z.string(),
  lastname: z.string()
})

export type ClientRelationList = z.arrayOutputType<typeof clientRelationListSchema>

const clientRelationFilterResponseSchema = z.object({
  id: z.string(),
  fullName: z.string()
})

export type ClientRelationFilterResponse = z.arrayOutputType<
  typeof clientRelationFilterResponseSchema
>
